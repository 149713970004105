import React, { useState, useEffect } from "react";

function TimeLeft({ start, lifecycle, completedSeries, mobile }) {
  const [serieDate] = useState(new Date(start));
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState("");
  const [dateMonth, setDateMonth] = useState();

  //const dayString = days > 1 ? 'days' : 'day';

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    if (serieDate !== undefined) {
      /*       let utc = serieDate.toUTCString();
      setUTCDate(new Date()); */
      //console.log(serieDate.toLocaleTimeString());

      //console.log(serieDate.getDate(), serieDate.getMonth());

      let finalizedString =
        serieDate.getDate() + " " + months[serieDate.getMonth()];
      setDateMonth(finalizedString);
      let time = serieDate.toLocaleTimeString("en-GB");
      // console.log(time);

      setHours(time.substring(0, 2));
      setMinutes(time.substring(3, 5));

      /*       setHours(serieDate.getHours());
      if (serieDate.getMinutes().toString().length === 1) {
        setMinutes(serieDate.getMinutes() + '0');
      } else {
        setMinutes(serieDate.getMinutes().toString());
      } */
    }
  }, [serieDate]);

  const timeDisplay = () => {
    return dateMonth + " " + hours + ":" + minutes;
  };
  let overCycles = ["over", "over-draw", "over-forfeited"];
  return (
    <div>
      {hours !== undefined && minutes !== undefined ? (
        <div className="text-sm text-white mt-1 text-opacity-90 flex cursor-pointer">
          <span
            title={`The match ${
              overCycles.includes(lifecycle) ? "started at" : "will start"
            } at: ${hours}:${minutes}`}
          >
            {dateMonth !== undefined &&
              hours !== undefined &&
              minutes !== undefined &&
              dateMonth + " " + hours + ":" + minutes}
          </span>
        </div>
      ) : null}
    </div>
  );
}

export default TimeLeft;
