import axios from "axios";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Layout from "./layout/Layout";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider, Helmet } from "react-helmet-async";

const queryClient = new QueryClient();

async function cookieCheck() {
  await axios(`${process.env.REACT_APP_BE_URL}/api/cookies/cc`, {
    withCredentials: true,
  });
  //console.log(response);
}

export default function OddsArena() {
  //console.log(process.env.REACT_APP_BE_URL);
  useEffect(() => {
    (async () => {
      await cookieCheck();
    })();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <CookiesProvider>
          <Layout />
        </CookiesProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
}
