import React, { useEffect, useState } from "react";
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/outline";

export default function LiveSerie({ serie, streams, handleStreams }) {
  const [streamed, setStreamed] = useState(false);

  useEffect(() => {
    if (streams.length > 0) {
      if (streams.filter((stream) => stream.id === serie.id).length > 0) {
        setStreamed(true);
      }
    }

    //calculate here
    //NOT SURE IF THIS WILL BE NEEDED AT ALL
    // examples, 3 = 2 rows 2 cols
  }, [streams]);

  //console.log("STREMED:", streamed);
  //console.log(serie);

  const logo = (data) => {
    let logoReturn;

    data !== undefined &&
    data.teamData !== undefined &&
    data.teamData.images !== null &&
    data.teamData.images.length > 0 &&
    data.teamData.images[0].url !== ""
      ? (logoReturn = (
          <div className="rounded-full flex w-6 h-4 items-center justify-center">
            <img
              className="h-4  z-10"
              src={data.teamData.images[0].thumbnail}
              alt={data.teamData.name}
              title={data.teamData.name}
            />
          </div>
        ))
      : (logoReturn = <div className="h-4 w-6 rounded-l-lg " />);

    return logoReturn;
  };

  return (
    <div
      className={`flex h-12  px-2 border-white rounded-md border mb-1 mx-1 border-opacity-40 text-white hover:cursor-pointer  hover:bg-hoverBackground hover:text-red-500 ${
        streamed ? "bg-green-900" : "bg-serieLeft2"
      }`}
      onClick={() => handleStreams(serie, streamed)}
    >
      <div className="flex  items-center">
        <div className="w-5 h-5 ">
          <img
            className="rounded-sm"
            src={serie.game[0].defaultThumbnail}
            alt={serie.game[0].title}
            title={serie.game[0].title}
          />
        </div>
      </div>
      <div className="flex-col pl-2 ">
        <div className="flex pt-1">
          <div>{logo(serie.participants.filter((p) => p.seed === 1)[0])}</div>
          <div className="flex justify-between w-32">
            <div>
              <p className="text-white text-xs text-opacity-80 pl-2">
                {serie.participants.filter((p) => p.seed === 1)[0].teamData.name
                  .length < 17
                  ? serie.participants.filter((p) => p.seed === 1)[0].teamData
                      .name
                  : serie.participants.filter((p) => p.seed === 1)[0].teamData
                      .abbreviation}
              </p>
            </div>
            <div>
              <p className="text-white text-xs text-opacity-80 pl-2">
                {serie.participants.filter((p) => p.seed === 1)[0].score !==
                  null &&
                  serie.participants.filter((p) => p.seed === 1)[0].score}
              </p>
            </div>
          </div>
        </div>
        <div className="flex pt-1">
          <div>{logo(serie.participants.filter((p) => p.seed === 2)[0])}</div>
          <div className="flex justify-between w-32">
            <div>
              <p className="text-white text-xs text-opacity-80 pl-2">
                {serie.participants.filter((p) => p.seed === 2)[0].teamData.name
                  .length < 18
                  ? serie.participants.filter((p) => p.seed === 2)[0].teamData
                      .name
                  : serie.participants.filter((p) => p.seed === 2)[0].teamData
                      .abbreviation}
              </p>
            </div>
            <div>
              <p className="text-white text-xs text-opacity-80 pl-2">
                {serie.participants.filter((p) => p.seed === 1)[0].score !==
                  null &&
                  serie.participants.filter((p) => p.seed === 2)[0].score}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex pl-3 items-center">
        {streamed ? (
          <div className="w-5 h-5 ">
            <ChevronDoubleLeftIcon
              onClick={() => {
                setStreamed(false);
              }}
            />
          </div>
        ) : (
          <div className="w-5 h-5 ">
            <ChevronDoubleRightIcon />
          </div>
        )}
      </div>
    </div>
  );
}
