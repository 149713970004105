import React from "react";
import TeamSeriesUpcoming from "../../components/Teams/TeamUpcomingSeries";

export default function NextMatch({
  match,
  home = false,
  mobile = false,
  teamName,
  bookies,
  team,
  games,
}) {
  // console.log(match);
  return (
    <div className="mt-2">
      <TeamSeriesUpcoming
        data={match}
        mobile={mobile}
        bookies={bookies}
        team={team}
        games={games}
      />
    </div>
  );
}
