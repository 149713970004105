import React from "react";
import { useForm } from "react-hook-form";

export default function AddForm({ handleAddStream }) {
  const { register, handleSubmit, reset, formState } = useForm();
  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ stream: "" });
    }
  }, [formState, reset]);
  return (
    <div>
      <form onSubmit={handleSubmit(handleAddStream)}>
        <div className="rounded-md border-white border-opacity-40 border  bg-hoverBackground    ">
          <div className="flex-col">
            <p className="p-1 text-sm text-white font-bold">Add stream</p>
            <p className="p-1 text-xs text-white text-opacity-80">
              Add your own twitch stream, by submittnig the twitch-url in the
              textfield below.
            </p>
          </div>
          <div className="flex-col">
            <div>
              <input
                className="mx-1 rounded-sm text-sm w-56 indent-px "
                {...register("stream")}
                placeholder="https://www.twitch.tv/example"
              />
            </div>
            <div className="w-full flex justify-end p-1">
              <button
                className="ml-5 bg-blue-500 hover:bg-serieLeft2 hover:text-red-500 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline text-xs"
                type="submit"
              >
                Add Stream
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
