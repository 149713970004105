import { useEffect, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import TournamentDescription from "./TournamentDescription";
import TournamentParticipants from "./TournamentParticipants";

export default function TournamentInformation({
  tournamentData = false,
  mobile = false,
}) {
  //console.log(tournamentData);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tooglePrizes, setTooglePrizes] = useState(false);
  const [prizes, setPrizes] = useState(null);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleToogle = () => {
    setTooglePrizes(!tooglePrizes);
  };

  const openLink = (link) => {
    window.open(link);
  };

  useEffect(() => {
    if (tournamentData.start !== undefined) {
      let date = new Date(tournamentData.start);
      let startString =
        date.getDate() +
        " " +
        months[date.getMonth()] +
        " " +
        date.getFullYear();

      setStartDate(startString);
    }

    if (tournamentData.end !== undefined) {
      if (tournamentData.end === null) {
        setEndDate("");
      } else {
        let date = new Date(tournamentData.end);
        let endString =
          date.getDate() +
          " " +
          months[date.getMonth()] +
          " " +
          date.getFullYear();

        setEndDate(endString);
      }
    }

    if (tournamentData.string_prize_pool.first !== null) {
      let orgString = { ...tournamentData.string_prize_pool };
      delete orgString.total;
      let values = Object.values(orgString);
      setPrizes(values);
    }
  }, [tournamentData]);

  const link = (team) => {
    if (
      team.name !== undefined &&
      team.id !== undefined &&
      tournamentData.game[0].abbreviation !== undefined
    ) {
      let game = tournamentData.game[0].abbreviation;
      game = game.toLowerCase();
      game = game.replace(/\s/g, "-");
      game = game.replace(/\//g, "-");

      let link = team.name;
      link = link.toLowerCase();
      link = link.replace(/\s/g, "-");
      link = link.replace(/\//g, "-");
      link += "-" + team.id;
      let returnLink = game + "/" + link;

      return returnLink;
    }

    return;
  };

  //console.log(tournamentData);
  return (
    <>
      {tournamentData !== false && (
        <div className=" rounded-md w-full shadow-md border border-white border-opacity-60">
          <div className="flex flex-col bg-serieRight2 rounded-md shadow-md ">
            <div>
              <h1 className="uppercase text-white font-bold text-2xl pl-3 mt-1">
                {tournamentData.title}
              </h1>
            </div>
            <div className="flex flex-col md:flex-row justify-between w-full  mt-2 ">
              <div>
                {tournamentData.images !== null &&
                tournamentData.images[0].url !== undefined ? (
                  <img
                    src={tournamentData.images[0].url}
                    className="w-60 rounded-r-md rounded-bl-md shadow-md"
                    alt={`${tournamentData.title} logo`}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <TournamentDescription
            startDate={startDate}
            endDate={endDate}
            tournamentData={tournamentData}
          />
          {/*           <TournamentParticipants tournamentData={tournamentData} /> */}
        </div>
      )}
    </>
  );
}
