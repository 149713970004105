import React from "react";
import MobileLeftTeam from "./MobileLeftTeam";
import MobileRightTeam from "./MobileRightTeam";
import Versus from "./Versus";
import Results from "./Results";

export default function MiddleSerieSectionMobile({
  data,
  completedSeries,
  mobile,
  open = false,
  games,
}) {
  //console.log(data);
  return (
    <div className="flex relative w-full h-12  items-center bg-serieLeft">
      <MobileLeftTeam data={data.participants[0]} games={games} />

      {completedSeries === false && <Versus />}
      {completedSeries !== false && (
        <Results
          results={data.participants}
          lifecycle={data.lifecycle}
          mobile={mobile}
          open={true}
        />
      )}

      <MobileRightTeam data={data.participants[1]} games={games} />
    </div>
  );
}
