import React from "react";
import { Link } from "react-router-dom";

export default function HeadToHead({ matchData, mobile }) {
  //console.log(matchData.h2h);

  //const dayString = days > 1 ? 'days' : 'day';

  const link = (serie) => {
    let urlString = "";

    if (serie.game[0].abbreviation !== undefined) {
      let game = serie.game[0].abbreviation;
      game = game.replace(/[:\s\-]/g, "").toLowerCase();

      urlString += "/match/" + game + "/";
    }

    if (serie.teams[0] !== undefined) {
      urlString += serie.teams[0].name.replace(/ /g, "-").toLowerCase();
    }
    if (serie.teams[1] !== undefined) {
      urlString +=
        "-vs-" + serie.teams[1].name.replace(/ /g, "-").toLowerCase();
    }

    if (serie.title !== undefined) {
      let tempString = serie.title;
      tempString = tempString.replace(/ - /g, "-");
      urlString += "-" + tempString.replace(/ /g, "-").toLowerCase();
    }

    if (
      serie.tournament !== undefined &&
      serie.tournament.length > 0 &&
      serie.tournament[0].title !== undefined
    ) {
      urlString +=
        "-" +
        serie.tournament[0].title
          .replace(/ /g, "-")
          .replace(/#/g, "")
          .toLowerCase();
    }

    if (serie.id !== undefined) {
      urlString += "-" + serie.id;
    }

    return urlString;
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const displayDate = (dateParameter) => {
    let date = new Date(dateParameter);
    let finalizedString =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
    //setDateMonth(finalizedString);
    //let time = date.toLocaleTimeString("en-GB");
    return finalizedString;
  };

  const logo = (participant) => {
    let logoReturn;

    participant !== undefined &&
    participant.images !== null &&
    participant.images.length > 0
      ? (logoReturn = (
          <div className="rounded-full flex h-8 w-8  items-center justify-center">
            <img
              className={`h-8 w-8 z-10 `}
              src={participant.images[0].thumbnail}
              alt={participant.name}
            />
          </div>
        ))
      : (logoReturn = <div className="h-8 w-8 bg-serieLeft2" />);

    return logoReturn;
  };

  const tableRow = (serie, index) => {
    return (
      <Link to={link(serie)}>
        <div
          key={serie.id}
          className={`flex align-middle items-center h-10 ${
            index % 2 === 0 ? " bg-serieLeft2" : "bg-serieRight2"
          } ${index === 0 && "rounded-tr-md"} hover:bg-hoverBackground`}
        >
          <div className="w-18 ml-2">
            <span>{serie.start !== undefined && displayDate(serie.start)}</span>
          </div>
          <div className="flex justify-between w-full">
            <div>{leftTeam(serie)}</div>
            <div className="h-8 align-middle items-center flex  text-base md:text-lg  text-white ">
              <span
                className={`${
                  serie.participants[0].winner === true
                    ? "font-bold text-opacity-80"
                    : "text-opacity-20"
                }`}
              >
                {serie.lifecycle === "over-forfeited" &&
                serie.participants[0].winner
                  ? "Win"
                  : serie.participants[0].score}
              </span>
              <span> - </span>
              <span
                className={`${
                  serie.participants[1].winner === true
                    ? "font-bold text-opacity-80"
                    : "text-opacity-20"
                }`}
              >
                {serie.lifecycle === "over-forfeited" &&
                serie.participants[1].winner
                  ? "W "
                  : serie.participants[1].score}
              </span>
            </div>
            <div>{rightTeam(serie)}</div>
          </div>
        </div>
      </Link>
    );
  };

  const leftTeam = (serie) => {
    let leftParticipant = serie.participants[0];
    let winner = leftParticipant.winner;
    let roster = serie.rosters.filter(
      (roster) => roster.id === leftParticipant.roster.id
    )[0];
    //console.log(leftParticipant.roster.id);
    //console.log(roster);
    let lTeam = serie.teams.filter((team) => team.id === roster.team.id)[0];

    //console.log(winner);

    return (
      <div className="flex pl-3  w-28">
        <div className={`${!winner && "grayscale"}`}>{logo(lTeam)}</div>
        <div className="h-8 align-middle items-center flex ml-2">
          <span
            className={`text-sm ${
              winner
                ? "font-bold text-opacity-90"
                : "text-gray-400 text-opacity-70 font-normal"
            }`}
          >
            {lTeam.name.length > 10 ? lTeam.abbreviation : lTeam.name}
          </span>
        </div>
      </div>
    );
  };

  const rightTeam = (serie) => {
    let rightParticipant = serie.participants[1];
    let winner = rightParticipant.winner;
    let roster = serie.rosters.filter(
      (roster) => roster.id === rightParticipant.roster.id
    )[0];
    //console.log(rightParticipant.roster.id);
    //.log(roster);
    let rTeam = serie.teams.filter((team) => team.id === roster.team.id)[0];

    //console.log(winner);

    return (
      <div className="flex justify-end pr-2 w-28">
        <div className="h-8 align-middle items-center flex mr-2">
          <span
            className={`text-sm ${
              winner
                ? "font-bold text-opacity-90"
                : "text-gray-400 text-opacity-70 font-normal"
            }`}
          >
            {rTeam.name.length > 10 ? rTeam.abbreviation : rTeam.name}
          </span>
        </div>
        <div className={`${!winner && "grayscale"}`}>{logo(rTeam)}</div>
      </div>
    );
  };

  return (
    <div className="mt-5">
      <div className="flex">
        <div className="bg-elementBackground pr-2 border-t border-r border-l border-white border-opacity-20 rounded-tr-md">
          <h2
            className={`${
              mobile ? "text-sm" : "text-lg"
            } first-letter:ml-1 font-bold uppercase text-white text-opacity-80  `}
          >
            {`Previous h2h matches`}
          </h2>
        </div>
      </div>
      <div className=" bg-serieRight2 rounded-tr-md text-xs text-white text-opacity-80 ">
        {matchData.h2h.length > 0 ? (
          <div>
            {matchData.h2h.map((match, index) => (
              <div key={match.id}>{tableRow(match, index)} </div>
            ))}
          </div>
        ) : (
          <div className="p-1">
            <span className="">No recent matches between the two teams</span>
          </div>
        )}
      </div>
    </div>
  );
}
