import React from "react";
import MatchButton from "./MatchButton";

export default function RecentMatch({ match, index, teamID }) {
  //console.log(match);

  const displayTime = (start) => {
    let newDate = new Date(start);
    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let date = newDate.getDate();
    if (date < 10) {
      date = "0" + date;
    }
    return year + "-" + month + "-" + date;
  };

  const link = () => {
    let urlString = "";

    if (match.game[0].abbreviation !== undefined) {
      let game = match.game[0].abbreviation;
      game = game.replace(/[:\s\-]/g, "").toLowerCase();

      urlString += "/match/" + game + "/";
    }

    if (match.participants[0] !== undefined) {
      let participant = match.participants[0].roster.id;
      let teamId = match.rosters.filter(
        (roster) => roster.id === participant
      )[0].team.id;

      let teamData = match.teams.filter((team) => team.id === teamId)[0];

      urlString += teamData.name.replace(/ /g, "-").toLowerCase();
    }
    if (match.participants[1] !== undefined) {
      let participant = match.participants[1].roster.id;
      let teamId = match.rosters.filter(
        (roster) => roster.id === participant
      )[0].team.id;
      let teamData = match.teams.filter((team) => team.id === teamId)[0];
      urlString += teamData.name.replace(/ /g, "-").toLowerCase();
    }

    if (match.title !== undefined) {
      let tempString = match.title;
      tempString = tempString.replace(/ - /g, "-");
      urlString += "-" + tempString.replace(/ /g, "-").toLowerCase();
    }

    if (
      match.tournament !== undefined &&
      match.tournament.length > 0 &&
      match.tournament[0].title !== undefined
    ) {
      urlString +=
        "-" +
        match.tournament[0].title
          .replace(/ /g, "-")
          .replace(/#/g, "")
          .toLowerCase();
    }

    if (match.id !== undefined) {
      urlString += "-" + match.id;
    }

    return urlString;
  };

  // console.log(match, teamID);

  const displayRow = () => {
    if (match !== undefined && teamID !== undefined) {
    } else {
      return <></>;
    }
    //winner

    /*     let opponentsRoster = match.participants.filter(
      (part) => part.roster.id !== roster
    )[0];

    //TEAM
    let opponentRosterTeam = match.rosters.filter(
      (roster) => roster.id === opponentsRoster.roster.id
    )[0]; */

    let opponentTeam = match.teams.filter((team) => team.id !== teamID)[0];

    //console.log(opponentTeam);
    let opponentRosterTeam = match.rosters.filter(
      (rost) => rost.team.id === opponentTeam.id
    )[0];

    let opponentsRoster = match.participants.filter(
      (part) => part.roster.id === opponentRosterTeam.id
    )[0];

    let partRoster = match.participants.filter(
      (part) => part.roster.id !== opponentRosterTeam.id
    )[0];
    let winner = partRoster.winner;

    //return opponentTeam.name;

    return (
      <div className="flex justify-between ml-3 h-8">
        <div className="items-center align-middle flex">
          <span className="text-xs text-white text-opacity-40">
            {displayTime(match.start)}
          </span>
          <div className="flex ml-2">
            <div className="flex align-middle items-center">
              {opponentTeam.images !== null &&
              opponentTeam.images.length > 0 &&
              opponentTeam.images.url !== "" ? (
                <img className="w-5" src={opponentTeam.images[0].url} />
              ) : (
                <div className="h-5 w-5"></div>
              )}
            </div>
            <div className="flex items-center align-middle">
              <span className="text-sm ml-2">{opponentTeam.name}</span>
            </div>
          </div>
        </div>

        <div className="flex items-center align-middle   w-22 ">
          <div
            className={`${
              winner
                ? "bg-green-500"
                : opponentsRoster.winner
                ? "bg-red-500"
                : "bg-yellow-500"
            } p-1 h-6 text-center align-middle flex rounded-md w-12 justify-center`}
          >
            {match.lifecycle !== "over-forfeited" ? (
              <span className="text-white text-xs font-bold text-center">
                {partRoster.score} - {opponentsRoster.score}
              </span>
            ) : (
              <span className="text-white text-xs font-bold text-center">
                {partRoster.winner ? "Win" : "Lose"}
              </span>
            )}
          </div>
          <MatchButton link={link()} />
        </div>
      </div>
    );
  };

  //console.log(match, index, roster);

  return (
    <>
      {match !== undefined && teamID !== undefined && (
        <div
          className={`w-full h-8 ${
            index % 2 ? " bg-headerBackground" : "  bg-serieLeft2"
          }`}
        >
          <span>{displayRow()}</span>
        </div>
      )}
    </>
  );
}
