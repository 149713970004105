import React, { useEffect, useState } from "react";
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/outline";
import OALogo from "../../assets/logo_white50h.png";

export default function UserAddedStream({ serie, streams, handleStreams }) {
  const [streamed, setStreamed] = useState(false);

  useEffect(() => {
    if (streams.length > 0) {
      if (streams.filter((stream) => stream.id === serie.id).length > 0) {
        setStreamed(true);
      }
    }

    //calculate here
    //NOT SURE IF THIS WILL BE NEEDED AT ALL
    // examples, 3 = 2 rows 2 cols
  }, [streams]);

  //console.log("STREMED:", streamed);
  console.log("USER-ADDED: ", serie);

  return (
    <div
      className={`flex h-16 py-2 px-2 border-white rounded-md border mt-1 mx-1 border-opacity-70 text-white hover:cursor-pointer  hover:bg-hoverBackground hover:text-red-500 ${
        streamed ? "bg-serieLeft2" : "bg-serieRight2"
      }`}
      onClick={() => handleStreams(serie, streamed)}
    >
      <div className="flex  items-center">
        <div className="w-5 h-5 ">
          <img
            className="rounded-sm"
            src={OALogo}
            alt="User Added Stream"
            title="User Added Stream"
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex-col items-center text-center mt-1">
          <div className="ml-6 mr-2 w-32  ">
            <p className="text-white text-sm">User Added Stream</p>
          </div>
          <div className="ml-6 mr-2 w-32  ">
            <p className="text-white text-opacity-50 text-xs">
              {serie.primaryCaster[0].additional_info.display_name}
            </p>
          </div>
        </div>
        <div className="flex pl-3 items-center">
          {streamed ? (
            <div className="w-5 h-5 ">
              <ChevronDoubleLeftIcon
                onClick={() => {
                  setStreamed(false);
                }}
              />
            </div>
          ) : (
            <div className="w-5 h-5 ">
              <ChevronDoubleRightIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
