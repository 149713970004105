import React from "react";
import RecentMatch from "./RecentMatch";

export default function MatchHistory({
  homeTeamSeries,
  awayTeamSeries,
  homeTeam,
  awayTeam,
  mobile,
}) {
  const matchView = (data, home = true) => {
    let teamID;
    if (home === true) {
      teamID = homeTeam.teamData.id;
      //console.log(data);
    } else {
      teamID = awayTeam.teamData.id;
    }
    //console.log(homeTeam);
    return (
      <div className=" bg-serieRight2 rounded-tr-md ">
        <div className="flex-col  pt-1">
          <div className="pl-3 h-6">
            <span className="text-white text-lg">{header(data, home)}</span>
          </div>
          {data.map((match, index) => (
            <RecentMatch
              match={match}
              index={index}
              teamID={teamID}
              key={match.id}
            />
          ))}
        </div>
      </div>
    );
  };

  const header = (data, home) => {
    if (home === true) {
      return (
        <div className="flex">
          <div>
            {homeTeam.teamData.images !== null &&
              homeTeam.teamData.images.length > 0 &&
              homeTeam.teamData.images.url !== "" && (
                <img className="h-5" src={homeTeam.teamData.images[0].url} />
              )}
          </div>
          <div className="flex items-center align-middle">
            <span className="text-sm ml-2 font-bold">
              {homeTeam.teamData.name}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex">
          <div>
            {awayTeam.teamData.images !== null &&
              awayTeam.teamData.images.length > 0 &&
              awayTeam.teamData.images.url !== "" && (
                <img className="h-5" src={awayTeam.teamData.images[0].url} />
              )}
          </div>
          <div className="flex items-center align-middle">
            <span className="text-sm ml-2">{awayTeam.teamData.name}</span>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="">
      <div className="mt-5 flex">
        <div className="bg-elementBackground pr-2 border-t border-r border-l border-white border-opacity-20 rounded-tr-md">
          <h2
            className={`${
              mobile ? "text-sm" : "text-lg"
            } first-letter:ml-1 font-bold uppercase text-white text-opacity-80  `}
          >
            {`Recent matches`}
          </h2>
        </div>
      </div>
      <div
        className={`${
          mobile ? "flex-col" : "flex"
        }  rounded-tr-md text-xs text-white text-opacity-80`}
      >
        <div className="w-full">
          {homeTeam !== undefined &&
            homeTeamSeries !== undefined &&
            homeTeamSeries.length > 0 &&
            matchView(homeTeamSeries)}
        </div>
        <div className={`w-10 bg-mainBackground ${mobile && "mt-2"}`}></div>
        <div className="w-full">
          {awayTeamSeries !== undefined &&
            awayTeamSeries.length > 0 &&
            matchView(awayTeamSeries, false)}
        </div>
      </div>
    </div>
  );
}
