import React, { useState, useEffect } from "react";
import Serie from "./SerieItem";

export default function LiveSerieList({ data, games, mobile, game }) {
  const [filterDisplay, setFilterDisplay] = useState("all games");

  useEffect(() => {
    //console.log("GAME:", game);

    if (game === undefined) {
      setFilterDisplay("all games");
    } else {
      //console.log(games);
      let gameName = games.filter((sg) => sg.gameMenu === game);
      //console.log(gameName);
      setFilterDisplay(gameName[0].abbreviation);
    }

    //console.log(filter);
  }, [game, games]);
  const liveSeries = (
    <div className="flex flex-col  shadow-lg">
      {data.map((serie, index) => (
        <Serie
          index={index}
          key={serie.id}
          data={serie}
          mobile={mobile}
          length={data.length}
          games={games}
        />
      ))}
    </div>
  );

  return (
    <>
      <div className={`h-8 flex text-white justify-between mt-2`}>
        <button
          disabled
          className={`  px-2   rounded-md shadow-md font-bold ${
            mobile && "text-base"
          } uppercase bg-serieRight2`}
        >
          Live Matches
        </button>
      </div>

      <div className="my-2 mb-4">{liveSeries}</div>
    </>
  );
}
