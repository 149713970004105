import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function OurService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col w-full px-1 py-1 mt-4 bg-elementBackground rounded-md shadow-lg prose max-w-none">
      <Helmet>
        <title>OddsArena | Our Service</title>
        <meta name="description" content="OddsArena | our service"></meta>
      </Helmet>
      <div className=" text-white prose-headings:text-white prose-h3:text-opacity-50 prose-h4:text-opacity-50 text-opacity-60 prose-h2:text-opacity-60 px-2 md:px-5 py-3">
        <h1>Our Service</h1>
        <p className="lead text-white text-opacity-80">
          We set out with a passion in technology and esports with a mission to
          create the best tool centred around esports and betting on esports.
        </p>
        <p>
          OddsArena is centred around listing upcoming and live esports matches
          and tournaments for the most popular Esports games. For each game, we
          aim to provide streaming coverage if the game is casted and we try our
          best to provide the best available odds for the game from bookmakers
          allowed to operate where you reside.
        </p>
        <p>
          The games, tournaments and odds are automatically updated with a low
          latency to make sure you are presented with the latest information.
        </p>

        <p className="italic">
          If you have ideas on how we can improve our site, please use the
          suggest improvement functionality by pressing the lightbulb in the
          bottom corner on desktop. And on mobile you can submit ideas in the
          main menu.
        </p>
        <h2>Esports streams </h2>
        <p>
          The streams will become available by pressing the Watch button once
          the game goes live and if the game is covered by multiple casters, you
          can switch between multiple casters and platforms directly on the
          page.
        </p>
        <p>We currently support Twitch and YouTube streams. </p>
        <h2>Esports betting</h2>
        <p>
          OddsArena have also partnered with bookmakers to provide you with the
          best odds for each game based on your region.
        </p>
        <p>
          We automatically filter out odds, to only show you odds from
          bookmakers that are allowed to operate where you reside.
        </p>
        <p>
          The service then presents the highest odds from the bookmakers we have
          partnered with an option to see the odds from multiple bookmakers if
          they exist by expanding the menu next to the odds. Pressing the odds
          will take you directly to the bookmaker's page, where you can place a
          bet on the match.
        </p>
        <p>
          Gambling should be fun, as soon as it stops being fun, you should
          stop. More information on responsible gambling can be found in our
          <Link to="/responsible-gambling">
            <span className="text-white"> Responsible Gambling </span>
          </Link>
          section.
        </p>
      </div>
    </div>
  );
}
