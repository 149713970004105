import React from "react";
import { Link } from "react-router-dom";

export default function MobileSideMenu({ menuData, handleSelectedTab, page }) {
  return (
    <div className="p-2 bg-elementBackground w-full rounded-md">
      <div className="flex">
        <Link to={`/${page === "matches" ? "" : page}`}>
          <div className="pr-1 flex">
            <button className="hover:text-red-600  text-white font-bold text-bold text-sm py-1 px-2 hover:bg-hoverBackground  bg-headerBackground rounded">
              ALL GAMES
            </button>
          </div>
        </Link>
        {menuData.map((game) => {
          return (
            <Link to={`/${page}/${game.gameMenu}`} key={game.id}>
              <div className="pr-1 flex" key={game.id}>
                <button
                  className="hover:text-red-600 text-white text-bold text-sm pl-1"
                  key={game.id}
                >
                  <div className="flex items-center align-middle">
                    <div>
                      <img
                        className="h-7 w-7 rounded-sm"
                        src={game.defaultThumbnail}
                        alt={game.title}
                      />
                    </div>
                  </div>
                </button>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
