import React, { useEffect } from "react";
import { useQuery } from "react-query";
import BookmakersList from "./BookMakers/BookmakersList";
import { Helmet } from "react-helmet-async";
import { useCookies } from "react-cookie";
import Lottie from "lottie-light-react";
import loadingAnimation from "../assets/loading_1.json";
import PageAd from "../components/PageAd";

export default function Bookmakers({
  mobile = false,
  refetchData,
  locationFilter,
  cryptoOnly,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [cookie, setCookies] = useCookies(["OddsArena"]);

  const fetchBookmakers = async () => {
    let data;
    //console.log(filter);
    data = await fetch(
      `${process.env.REACT_APP_BE_URL}/api/bookmakers?&locationFilter=${locationFilter}&crypto=${cryptoOnly}`,
      {
        credentials: "same-origin",
      }
    );

    data = await data.json();

    return data.data.bookmakers;
  };

  const { data, error, isLoading, isError } = useQuery(
    ["bookmakers", refetchData],
    fetchBookmakers,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  //  console.log(serieData);

  return (
    <>
      <Helmet>
        <title>OddsArena | Bookmakers</title>
        <meta
          name="description"
          content="OddsArena | esports bookmakers. Our list of betting bookmakers to provide you with the highest odds for your favourite esports bets."
        ></meta>
      </Helmet>
      <div className="mt-4">
        <div className="flex flex-col w-full ">
          <div className="bg-elementBackground rounded-md shadow-lg px-3 py-1  mb-2 border border-white border-opacity-60">
            <h2 className="uppercase text-white font-bold text-2xl">
              Bookmakers
            </h2>
            <article className="mt-3 text-white  text-sm ">
              It's complicated to find the best bookmaker. Multiple aspects
              needs to be considered, such as availability to your region,
              markets, odds, gamgling license, bonuses, deposit methods(crypto
              vs fiat currencies).
            </article>
            <article className="mt-3 text-white  text-sm ">
              This is the list of bookmakers that we collect and compare odds on
              a frequent basis. Some of the bookmakers might be unaccessible to
              your location. Some unaccessible bookmakers still allow players to
              play through a VPN.
            </article>
            <article className="mt-3 text-white  text-sm ">
              The list is filtered with the same mechanics as the matches on the
              match page using your own filters (location and crypto).
            </article>

            <article className="mt-3 text-white text-sm text-opacity-80 italic">
              This list will be expanded over time to make sure we can find the
              best odds around. If you think we are missing a specific
              bookmaker, feel free to reach out to us in our feedback form.
            </article>

            <article className="mt-3 text-white text-sm text-opacity-80 italic">
              If you decide to register to one of the below mentioned listed
              bookmakers, feel free to use our links / registration codes to
              help us continue deliver this service to you. Normally our
              registration code, link also provides you with an improved bonus
              compared to registering directly at the site.
            </article>
            <br></br>
            {/*             <span className="mt-2 text-white text-opacity-90 text-sm">
              Your current filter:
            </span> */}
          </div>
          {isLoading ? (
            <Lottie animationData={loadingAnimation} style={{ height: 200 }} />
          ) : (
            data !== undefined &&
            data.length > 0 && (
              <>
                {/*                 <div className="flex w-full mt-5 mb-5  ">
                  <PageAd game={"thunder"} />
                </div> */}
                <BookmakersList bookies={data} />{" "}
              </>
            )
          )}
        </div>
      </div>
    </>
  );
}
