import React from "react";

const unknown_player =
  "https://oa-img-public.s3.eu-north-1.amazonaws.com/oddsarena/unknown_player300x300.png";

export default function Player({ player = false, mobile = false }) {
  // console.log(player);

  return (
    <>
      {player !== false ? (
        <div
          className={`flex-col bg-serieRight2 ${
            !mobile && "rounded-tr-md"
          } justify-center pt-3 pb-2 `}
        >
          <div className="flex justify-center ">
            {player.images.length > 0 ? (
              <img
                src={player.images[0].url}
                alt={player.nick_name + " image"}
                className={`max-h-[80px] max-w-[80px] rounded-full     }`}
              />
            ) : (
              <img
                src={unknown_player}
                alt="Unknown player"
                className={`max-h-[80px] rounded-full  mx-1 mt-1 } bg-serieLeft2`}
              />
            )}
          </div>
          <div
            className={`${
              mobile ? "flex-col justify-center" : "flex justify-center mt-1"
            } `}
          >
            <div
              className={`pt-2 mr-1 ${
                mobile && "flex justify-center mr-0 pt-1 mt-1"
              }`}
            ></div>
            <div className={`${mobile && "flex justify-center"}`}>
              {player.nick_name !== undefined && (
                <span
                  className={`text-white text-opacity-80  ${
                    mobile ? "text-base" : "text-lg font-bold"
                  }`}
                >
                  {player.nick_name}
                </span>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            {mobile === false && (
              <span
                className={`text-white text-opacity-50 ${
                  mobile ? "text-xs" : "text-sm"
                }`}
              >
                {player.first_name} {player.last_name}
              </span>
            )}
          </div>
          <div className="flex justify-center mt-1 align-middle items-center">
            {player.region !== null &&
              player.region.country !== null &&
              player.region.country.images !== undefined &&
              player.region.country.images[0].url !== undefined && (
                <img
                  src={player.region.country.images[0].url}
                  alt="player region"
                  className="h-4 "
                />
              )}
            <span className="ml-1 text-white text-opacity-40 text-xs">
              {player.region !== null &&
                player.region.country !== null &&
                player.region.country.name !== undefined &&
                player.region.country.name}
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
}
