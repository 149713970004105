import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export default function ResponsibleGambling() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>OddsArena | Responsible Gambling</title>
        <meta
          name="description"
          content="OddsArena | Responsible Gambling"
        ></meta>
      </Helmet>
      <div className="mx-auto text-white  mt-4  prose-headings:text-white  prose  prose-h3:text-opacity-50 prose-h4:text-opacity-50 text-opacity-60  prose-h2:text-opacity-60 bg-elementBackground rounded-md shadow-lg px-2 md:px-5 py-3 max-w-none ">
        <h1>Responsible Gambling</h1>
        <p />
        <h2 className="uppercase">It's extremely important</h2>

        <p>
          A lot of people are able to enjoy healthy gambling and are able to
          control addiction. But for people who struggles with addiction to
          gambling, we highly encourage you to walk away and stay away from all
          kind of gambling.
        </p>
        <p>
          For the majority of people, gambling should be seen as an increased
          entertainment value to support your favourite team, or to raise the
          thrill around the game you are watching/following.
        </p>
        <p>
          Very few people make consistent profit and become professional
          betters.
        </p>
        <p>
          If you want to make profits consistenly, you need to put in time and
          discipline to practice your skills / theories consistently.
        </p>
        <p className="font-bold">
          If you don't then the house will always win in the long run.
        </p>

        <h3>Responsible Gambling Tips</h3>
        <li>Make a plan, and set your budget</li>
        <li>Only gamble with a clear head</li>
        <li>Don't gamble with money you can't afford to lose</li>
        <li>Don't chase your losses</li>
        <li>Stop when it's not fun anymore</li>
        <li>Search for help, if you need it</li>

        <h3>Signs to look for if you need help</h3>
        <li>Have you gambled longed than you had planned?</li>
        <li>Have you lost more than you expected?</li>
        <li>Are you having problems sleeping?</li>
        <li>Have you borrowed money to finance gambling?</li>
        <li>
          Have you gambled to get money to meet your financial obligations?
        </li>

        <h4 className="font-bold uppercase">
          Seek help if you recognize one/some of the signs
        </h4>
      </div>
    </>
  );
}
