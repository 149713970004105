import React, { useState, useEffect } from "react";
import GroupList from "./Groups/GroupList";
import Bracket from "./Brackets/Bracket";
import Brackets from "./Brackets/Brackets";

export default function TournamentSubStage({
  data,
  mobile,
  game,
  teams,
  series,
  substageAmount,
  bookies,
}) {
  //console.log("TST: ", series);

  //console.log(data);
  return (
    <>
      <div>
        {substageAmount !== undefined && substageAmount > 1 && data.title}
      </div>
      {data.type === 0 && (
        <GroupList
          game={game}
          teams={teams}
          title={data.title}
          standings={data.standings}
          series={series}
          mobile={mobile}
          bookies={bookies}
          key={data.id}
        />
      )}
      {data.type !== 0 && data.phase !== "other" && (
        <Brackets game={game} title={data.title} series={series} />
      )}
      {data.type === 1 && data.phase === "other" && (
        <Brackets game={game} title={data.title} series={series} />
      )}
    </>
  );
}
