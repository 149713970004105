import React, { useState, useEffect } from "react";
import MatchItem from "./MatchItem";
import MatchDetail from "./MatchDetail";

export default function MatchList({ matchData, mobile, handleRefetch }) {
  const [selectedIndex, setSelectedIndex] = useState(false);

  let selection;
  let overCycles = ["over", "over-draw", "over-forfeited"];

  useEffect(() => {
    //console.log(matchData);
    if (overCycles.includes(matchData.lifecycle)) {
      handleSelectMatch(0);
    } else if (matchData.lifecycle === "live") {
      let match = matchData.matches.filter(
        (match) => match.lifecycle === "live"
      )[0];
      if (match === undefined) {
        //probably advantage here that needs to be dealt with
      } else {
        let index = match.order - 1;
        handleSelectMatch(index);
      }
    }
  }, [matchData]);
  // console.log(matchData);

  const handleSelectMatch = (id) => {
    setSelectedIndex(id);
  };

  const mobileView = (
    <div className="flex ">
      {matchData.matches.map((match, idx) => (
        <MatchItem
          match={match}
          participants={matchData.participants}
          mobile={mobile}
          key={match.id}
          index={idx}
          length={matchData.matches.length}
          selectMatch={handleSelectMatch}
          selectedIndex={selectedIndex}
        />
      ))}
    </div>
  );

  //console.log(matchData);
  return (
    <div className="flex-col mt-5">
      <div className="flex">
        <div className="bg-elementBackground pr-2 border-t border-r border-l border-white border-opacity-20 rounded-tr-md">
          <h2
            className={`${
              mobile ? "text-sm" : "text-lg"
            } first-letter:ml-1 font-bold uppercase text-white text-opacity-80  `}
          >
            {`Maps in the match`}
          </h2>
        </div>
      </div>
      {mobileView}
      {selectedIndex !== false && (
        <MatchDetail
          handleRefetch={handleRefetch}
          serieData={matchData}
          matchData={matchData.matches[selectedIndex]}
          selectedIndex={selectedIndex}
          mobile={mobile}
          winreasons={matchData.winreasons ? matchData.winreasons : null}
        />
      )}
    </div>
  );
}
