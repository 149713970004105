import React, { lazy } from "react";
import { useQuery } from "react-query";
import LiveScore from "./LiveScore";
import teamLink from "../../components/utils/teamLink";
import { Link } from "react-router-dom";
import Networth from "./Details/Networth";
import CSPastRounds from "./Details/CSPastRounds";
import PlayerStats from "./Details/PlayerStats";
import Draft from "./Details/Draft";

//const Networth = lazy(() => import("./Details/Networth.js"));

export default function LiveSummary({
  serieData,
  id,
  matchData,
  handleRefetch,
  mobile,
  winreasons,
}) {
  // console.log(matchData);

  const fetchLiveMatch = async () => {
    let res = await fetch(
      `${process.env.REACT_APP_BE_URL}/api/matches/live?id=${id}`,
      {
        credentials: "same-origin",
      }
    );
    res = await res.json();
    if (res.data.lifecycle === "over") {
      handleRefetch();
    }
    // console.log(res.data);
    res = res.data;

    return res;
  };

  const { data, isLoading } = useQuery("livematch", fetchLiveMatch, {
    refetchOnWindowFocus: true,
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });
  //console.log(data);
  //  console.log(data);
  const logo = (participant) => {
    let logoReturn;

    participant !== undefined &&
    participant.teamData !== undefined &&
    participant.teamData.images !== null &&
    participant.teamData.images.length > 0
      ? (logoReturn = (
          <div className="">
            <div className="rounded-full flex h-10 w-10  items-center justify-center">
              <img
                className={`h-10 w-10 z-10 `}
                src={participant.teamData.images[0].thumbnail}
                alt={participant.teamData.name}
              />
            </div>
          </div>
        ))
      : (logoReturn = <div className="h-10 w-10 hover:bg-hoverBackground" />);

    return logoReturn;
  };

  return (
    <>
      <div className="w-full py-2 flex bg-serieRight2 justify-between h-14 text-xs text-white ">
        <Link
          to={`/team/${teamLink(
            serieData.participants[0].teamData,
            serieData.game[0]
          )}`}
        >
          <div
            className={`flex  pl-1 h-10 ${
              mobile ? "w-32" : "w-auto  px-2"
            }  border-white border hover:border-opacity-50 hover:bg-hoverBackground hover:rounded-md border-opacity-0 `}
          >
            <div className={``}>{logo(serieData.participants[0])}</div>
            <div className="h-10 align-middle items-center flex ml-2">
              <span
                className={`${
                  mobile ? "text-sm" : "text-base"
                } text-gray-400 text-opacity-70 font-normal`}
              >
                {serieData.participants[0].teamData.name.length > 10
                  ? serieData.participants[0].teamData.abbreviation
                  : serieData.participants[0].teamData.name}
              </span>
            </div>
          </div>
        </Link>
        <div className="w-14 md:w-44">
          {!isLoading &&
            data !== undefined &&
            matchData.coverage.data.live.cv.expectation !== "unsupported" && (
              <LiveScore
                data={data}
                coverage={matchData.coverage}
                game={matchData.game}
              />
            )}
          {matchData.coverage.data.live.cv.expectation === "unsupported" && (
            <div className="hidden md:flex w-full h-10 text-xs md:text-md align-middle items-center  justify-center">
              <span>Live Results N/A</span>
            </div>
          )}
        </div>
        <Link
          to={`/team/${teamLink(
            serieData.participants[1].teamData,
            serieData.game[0]
          )}`}
        >
          <div
            className={`flex pr-1  h-10 ${
              mobile ? "w-32" : "w-auto  px-2 "
            } justify-end border-white border hover:bg-hoverBackground hover:rounded-md border-opacity-0 hover:border-opacity-50`}
          >
            <div className="h-10 align-middle items-center flex mr-2">
              <span
                className={`${
                  mobile ? "text-sm" : "text-base"
                } text-gray-400 text-opacity-70 font-normal
            }`}
              >
                {serieData.participants[1].teamData.name.length > 10
                  ? serieData.participants[1].teamData.abbreviation
                  : serieData.participants[1].teamData.name}
              </span>
            </div>
            <div className={``}>{logo(serieData.participants[1])}</div>
          </div>
        </Link>
      </div>

      {data !== undefined &&
        serieData.game[0].id !== 5 &&
        data.detailed_match !== undefined &&
        serieData !== undefined &&
        serieData.participants !== undefined && (
          <div className="w-full mt-2 py-2 flex-col bg-serieRight2">
            <Networth
              detailed_match={data.detailed_match}
              serieData={serieData.participants}
              game={serieData.game}
              mobile={mobile}
            />
          </div>
        )}
      {data !== undefined &&
        serieData.game[0].id === 5 &&
        data.detailed_match !== undefined &&
        data.detailed_match.past_rounds !== undefined &&
        data.detailed_match.past_rounds.length > 0 &&
        winreasons !== undefined &&
        winreasons.length > 0 &&
        serieData !== undefined &&
        serieData.participants !== undefined && (
          <>
            <div className="w-full mt-2 py-2 flex-col bg-serieRight2">
              <CSPastRounds
                detailed_match={data.detailed_match}
                serieData={serieData.participants}
                mobile={mobile}
                game={serieData.game}
                winreasons={winreasons}
              />
            </div>
            <div className="w-auto mt-2 py-2 flex-col bg-serieRight2   ">
              <PlayerStats
                detailed_match={data.detailed_match}
                serieData={serieData.participants}
                mobile={mobile}
                game={serieData.game}
                players={serieData.players}
              />
            </div>
          </>
        )}
      {data !== undefined &&
        data.detailed_match !== undefined &&
        (serieData.game[0].id === 1 || serieData.game[0].id === 2) &&
        matchData.detailed_match !== undefined &&
        serieData !== undefined &&
        data.players !== undefined &&
        data.draft !== undefined &&
        serieData.participants !== undefined && (
          <div className="w-auto mt-2 py-2 flex-col bg-serieRight2    ">
            <PlayerStats
              detailed_match={data.detailed_match}
              serieData={serieData.participants}
              mobile={mobile}
              game={serieData.game}
              players={data.players}
              draft={data.draft}
            />
          </div>
        )}
      {data !== undefined &&
        data.detailed_match !== undefined &&
        serieData.game[0].id === 1 &&
        matchData.detailed_match !== undefined &&
        data.draft !== undefined &&
        serieData !== undefined &&
        serieData.participants !== undefined && (
          <div className="w-auto mt-2 py-2 flex-col bg-serieRight2    ">
            <Draft
              detailed_match={data.detailed_match}
              serieData={serieData.participants}
              mobile={mobile}
              game={serieData.game}
              players={serieData.players}
              draft={data.draft}
            />
          </div>
        )}
    </>
  );
}
