import React from "react";
import Odds from "./Odds";
import { Link } from "react-router-dom";

export default function RightTeam({
  data,
  odds,
  bookies,
  index,
  length,
  completedSeries,
  lifecycle,
  games,
  live = false,
}) {
  //console.log(data);

  const link = () => {
    //console.log(data, data.teamData, games);
    if (
      data !== undefined &&
      data.teamData !== undefined &&
      games !== undefined
    ) {
      let game = games.filter((game) => game.id === data.teamData.game.id)[0]
        .abbreviation;
      game = game.replace(/[:\s\-]/g, "").toLowerCase();

      //console.log(game);

      let link = data.teamData.name;
      link = link.toLowerCase();
      link = link.replace(/\s/g, "-");
      link = link.replace(/\//g, "-");
      link += "-" + data.teamData.id;

      let returnLink = game + "/" + link;

      return returnLink;
    }
    return;
  };

  const logo = () => {
    let logoReturn;

    data !== undefined &&
    data.teamData !== undefined &&
    data.teamData.images !== null &&
    data.teamData.images.length > 0 &&
    data.teamData.images[0].url !== ""
      ? (logoReturn = (
          <div className="rounded-full flex h-10 w-10   items-center justify-center">
            <img
              className="h-8 w-8 z-10"
              src={data.teamData.images[0].thumbnail}
              alt={data.teamData.name}
              title={data.teamData.name}
            />
          </div>
        ))
      : (logoReturn = <div className="h-10 w-10 rounded-l-lg " />);

    return logoReturn;
  };

  return (
    <div
      className={`flex justify-end h-full bg-serieRight2 w-1/2 pr-3 ${
        index === 0 && ""
      } ${index + 1 === length && ""} ${completedSeries !== false && "pl-12"}`}
    >
      <div
        className={`flex justify-between align-middle text-white items-center w-full ${
          live ? "ml-16" : "ml-8"
        } pr-2`}
      >
        {data !== undefined && data.teamData !== undefined && (
          <Link to={`/team/${link()}`}>
            <div className="flex gap-4 hover:bg-hoverBackground border-white hover:border-opacity-50 border  rounded-lg border-opacity-0">
              <div className="flex align-middle items-center">{logo()}</div>
              <div className="flex align-middle items-center text-xs font-medium  text-opacity-90">
                <span className="mr-2">{data.teamData.name}</span>
              </div>
            </div>
          </Link>
        )}
        {data === undefined && (
          <div className="flex gap-4">
            <div className="flex align-middle items-center">{logo()}</div>
            <div className="flex align-middle items-center text-xs font-medium  text-opacity-90">
              <span>TBD</span>
            </div>
          </div>
        )}
        {odds !== undefined ? <Odds odds={odds} bookies={bookies} /> : null}
      </div>
    </div>
  );
}
