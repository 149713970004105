import React, { useState } from "react";

export default function Results({
  results,
  lifecycle,
  mobile,
  id,
  open = false,
}) {
  const [showResults, setShowResults] = useState(open);

  const toogleResults = () => {
    if (!open) {
      setShowResults(!showResults);
    }
  };

  //console.log(results, id);

  const forfeitView = (
    <div className="flex  justify-center w-full text-sm ">
      <span>FORFEITED</span>
    </div>
  );

  const deletedView = (
    <div className="flex  justify-center w-full text-sm ">
      <span>CANCELLED</span>
    </div>
  );

  const resultView = (
    <div className="flex justify-between w-full font-bold text-md">
      <span className={`${mobile ? "pl-2" : "pl-7"}`}>{results[0].score}</span>

      <span className={`${mobile ? "pr-2" : "pr-7"}`}>{results[1].score}</span>
    </div>
  );

  const displayResults = () => {
    if (lifecycle === "over-forfeited") {
      return forfeitView;
    } else if (lifecycle === "deleted") {
      return deletedView;
    } else {
      return resultView;
    }
  };

  // console.log(id, results[0].score, results[1].score);

  return (
    <div className="absolute transform text-white -translate-x-1/2 left-1/2 h-full flex items-center justify-center overflow-hidden   ">
      <div className=" bg-serieLeft2 h-24 w-8 absolute right-1/2 transform rotate-20"></div>
      <div className="bg-serieRight2 h-24 w-8 absolute left-1/2 transform rotate-20"></div>
      <div
        className={`relative h-8 ${
          mobile ? "w-20" : "w-32"
        } flex items-center justify-center z-30 bg-elementBackground rounded-md border-2 border-white border-opacity-20 hover:bg-hoverBackground hover:text-red-600 cursor-pointer`}
        onClick={() => toogleResults()}
      >
        {!showResults ? <span>Show</span> : displayResults()}
      </div>
    </div>
  );
}
