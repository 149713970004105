import { useEffect, useState } from "react";
import React from "react";
import Player from "../Player/Player";
import matchLink from "../utils/matchLink";
import { Link } from "react-router-dom";

export default function TeamStatistics({ statistics = false, mobile = false }) {
  //console.log("TEAMFORM", teamData);

  return (
    <>
      {statistics !== false ? (
        <div className={``}>
          <div className="flex">
            <div className="flex mt-5 pr-2 bg-elementBackground border-t border-r border-l border-white border-opacity-20 rounded-tr-md">
              <h2
                className={`${
                  mobile ? "text-sm" : "text-lg"
                } first-letter:ml-1 font-bold uppercase text-white text-opacity-80  `}
              >
                {`Statistics`}
              </h2>
            </div>
          </div>
          <div className="flex">
            <div className="flex border border-white border-opacity-20 bg-serieRight2 rounded-tr-md">
              <div className={`flex  px-2 py-1`}>
                <div className="flex justify-center  items-center">
                  {statistics !== undefined && (
                    <span
                      className={`text-white text-opacity-80  text-base fond-bold `}
                    >
                      {statistics}%
                    </span>
                  )}
                </div>
                <div className="flex items-center ">
                  <span className="ml-2 text-xs italic text-white text-opacity-50">
                    {"Win Percentage (last 90 days)"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
