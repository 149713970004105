import React from "react";
import {
  StatusOnlineIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/outline";

import matchLink from "../../utils/matchLink";
import { Link } from "react-router-dom";

function LiveGame({ data }) {
  //console.log(data);
  return (
    <Link to={matchLink(data)}>
      <div>
        {data.casters !== undefined && data.casters.length > 0 ? (
          <div className="flex hover:bg-serieRight2 py-half  px-2 rounded text-white hover:text-red-500 bg-serieLeft2 w-24">
            {data.casters !== undefined && data.casters.length > 0 ? (
              <div className="flex w-24 items-center">
                <div className="font-semibold">WATCH</div>
                <StatusOnlineIcon className="ml-1 w-6 h-6 text-red-500 animate-pulse" />
                {/* <ChevronDoubleRightIcon className="mr-2 w-4 h-6" /> */}
              </div>
            ) : (
              <div className="font-semibold ml-1">ONGOING</div>
            )}
          </div>
        ) : (
          <div
            className="flex  py-half  px-2 rounded text-white bg-serieLeft2  w-2512"
            disabled
          >
            <div className="flex w-26">
              <div className="font-semibold ml-1">ONGOING</div>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}

export default LiveGame;
