import { useEffect, useState } from "react";
import React from "react";
import Player from "../Player/Player";
import matchLink from "../utils/matchLink";
import { Link } from "react-router-dom";
import TeamStatistics from "./TeamStatistics";

export default function TeamInformation({ teamData = false, mobile = false }) {
  // console.log("TEAMFORM", teamData);

  const color = (form) => {
    //   console.log(form);
    if (form.type == "D") {
      return "bg-yellow-500";
    } else if (form.type == "L") {
      return "bg-red-500";
    }
    return "bg-green-500";
  };

  const displayForfeit = (form) => {
    //   console.log(form);
    if (form.type == "D") {
      return "Draw";
    } else if (form.type == "L") {
      return "Loss";
    }
    return "Win";
  };

  // console.log(teamData.form);

  return (
    <>
      {teamData !== false ? (
        <div className={``}>
          <div className="flex">
            <div className="flex mt-5 pr-2 bg-elementBackground border-t border-r border-l border-white border-opacity-20 rounded-tr-md">
              <h2
                className={`${
                  mobile ? "text-sm" : "text-lg"
                } first-letter:ml-1 font-bold uppercase text-white text-opacity-80  `}
              >
                {`Last matches`}
              </h2>
            </div>
          </div>
          <div className="flex">
            <div className="flex border border-white border-opacity-20 bg-serieRight2 rounded-tr-md">
              <div
                className={`${
                  mobile ? "grid grid-cols-5 gap-2" : "flex"
                } py-2 px-2`}
              >
                {teamData.form !== undefined &&
                  teamData.form !== null &&
                  teamData.form.length > 0 &&
                  teamData.form.map((form, index) => {
                    return (
                      <Link to={`${matchLink(form.serie)}`}>
                        <div
                          className={`flex-col px-2 py-1 ${color(
                            form
                          )}  bg-opacity-90 rounded-sm hover:cursor-pointer ${
                            !mobile &&
                            index < teamData.form.length - 1 &&
                            "mr-1"
                          }`}
                          title={`${form.opponentData.name}`}
                        >
                          {form.opponentData !== undefined &&
                          form.opponentData.images !== null &&
                          form.opponentData.images !== undefined &&
                          form.opponentData.images.length > 0 ? (
                            <img
                              className="h-8"
                              src={
                                form.opponentData.images.filter(
                                  (img) => img.type === "default"
                                )[0].url
                              }
                              alt={`${form.opponentData.name}`}
                            />
                          ) : (
                            <div className="h-8 w-8"></div>
                          )}
                          <div className="text-center">
                            <span className="w-auto text-xs font-semibold text-white text-opacity-80">
                              {form.score === "null - null"
                                ? displayForfeit(form)
                                : form.score.trim()}
                            </span>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
          <TeamStatistics statistics={teamData.winPercentage} />
        </div>
      ) : null}
    </>
  );
}
