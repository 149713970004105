import React from "react";

export default function TopOdds({ menuOptions, sendUserToBookie, home }) {
  return (
    <div
      className={`flex flex-col cursor-pointer pt-1 px-1 border  rounded-lg border-opacity-0  hover:bg-hoverBackground border-white hover:border-opacity-50`}
      onClick={() => sendUserToBookie(menuOptions.name, 999, menuOptions.url)}
    >
      <div className="w-18 text-center ">
        <img
          className="h-4 mx-auto"
          src={menuOptions.imgUrl}
          alt={menuOptions.name}
        />
      </div>
      <div className="text-center">
        <span className="text-sm font-bold text-opacity-90">
          {menuOptions.odds}
        </span>
      </div>
    </div>
  );
}
