import React from "react";
import LeftTeam from "./LeftTeam";
import RightTeam from "./RightTeam";
import Versus from "./Versus";
import LiveScore from "./LiveScore";

export default function LiveRightSerieSection({
  lifecycle,
  data,
  bookies,
  index,
  length,
  team,
  games,
  page,
}) {
  const oddsPresent = () => {
    if (data.odds !== undefined && data.odds.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //console.log(data);

  return (
    <div className={`flex relative w-full items-center bg-serieLeft2  `}>
      <LeftTeam
        fullData={data}
        data={data.participants.filter((part) => part.seed === 1)[0]}
        lifecycle={data.lifecycle}
        team={team}
        games={games}
        live={true}
      />

      <LiveScore data={data} />

      <RightTeam
        data={data.participants.filter((part) => part.seed === 2)[0]}
        index={index}
        length={length}
        lifecycle={data.lifecycle}
        games={games}
        live={true}
      />
    </div>
  );
}
