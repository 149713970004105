import React, { useEffect, useState } from "react";
import OddsMenu from "./OddsMenu";
import TopOdds from "./TopOdds";

function Odds({ odds, bookies, home = false, mobile = false }) {
  //const [oddsType, setOddsType] = useState();
  const [menuOptions, setMenuOptions] = useState([]);

  useEffect(() => {
    if (odds !== undefined && odds.length > 0) {
      //now we will only have either 12 or 1x2. So..
      let cleanedOdds = odds[0];
      let key = Object.keys(cleanedOdds);

      cleanedOdds = cleanedOdds[key];
      //setOddsType(key);
      //console.log(odds, bookies);
      let oddsOptions = [];

      if (home) {
        cleanedOdds.home.forEach((co) => {
          let bookieValue = bookies.filter(
            (bookie) => bookie.bookieId === co.bookmakerID
          );

          //console.log(bookieValue);
          bookieValue = bookieValue[0];
          oddsOptions.push({
            odds: co.odds,
            url: bookieValue.affiliateLink,
            imgUrl: bookieValue.affiliateSmallLogoUrl,
            name: bookieValue.name,
          });
        });

        setMenuOptions(oddsOptions);
      } else {
        cleanedOdds.away.forEach((co) => {
          let bookieValue = bookies.filter(
            (bookie) => bookie.bookieId === co.bookmakerID
          );
          bookieValue = bookieValue[0];
          oddsOptions.push({
            odds: co.odds,
            url: bookieValue.affiliateLink,
            imgUrl: bookieValue.affiliateSmallLogoUrl,
            name: bookieValue.name,
          });
        });

        setMenuOptions(oddsOptions);
      }
    }
  }, [odds, bookies, home]);

  const sendUserToBookie = (name, index, url) => {
    console.log("sending user to: " + url + " User pressed index: " + index);
    fetch(`${process.env.REACT_APP_BE_URL}/api/statistics`, {
      method: "POST",
      body: JSON.stringify({ bookieLinkPressed: url, index: index }),
      headers: { "Content-Type": "application/json" },
    });
    window.open(url);
  };

  //CONTINUE HERE WITH MOBILE AND DESKTOP DEFINITIONS
  const mobileView = (
    <div className="flex align-middle w-27">
      {menuOptions.length > 0 ? (
        <TopOdds
          home={home}
          menuOptions={menuOptions[0]}
          sendUserToBookie={sendUserToBookie}
        />
      ) : null}
      {menuOptions.length > 1 ? (
        <OddsMenu
          home={home}
          menuOptions={menuOptions}
          sendUserToBookie={sendUserToBookie}
          mobile={mobile}
        />
      ) : null}
    </div>
  );

  const desktopView = (
    <div className="flex align-middle w-27">
      {menuOptions.length > 0 ? (
        <TopOdds
          home={home}
          menuOptions={menuOptions[0]}
          sendUserToBookie={sendUserToBookie}
        />
      ) : null}
      {menuOptions.length > 1 ? (
        <OddsMenu
          home={home}
          menuOptions={menuOptions}
          sendUserToBookie={sendUserToBookie}
          mobile={mobile}
        />
      ) : null}
    </div>
  );

  return <>{mobile ? mobileView : desktopView}</>;
}

export default Odds;
