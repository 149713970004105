import React, { lazy } from "react";
import teamLink from "../../components/utils/teamLink";
import { Link } from "react-router-dom";
import Networth from "./Details/Networth";
import CSPastRounds from "./Details/CSPastRounds";
import PlayerStats from "./Details/PlayerStats";
import Draft from "./Details/Draft";

//const Networth = lazy(() => import("./Details/Networth.js"));

export default function MatchSummary({
  serieData,
  matchData,
  mobile,
  winreasons,
}) {
  // console.log(matchData);
  const logo = (participant) => {
    let logoReturn;

    participant !== undefined &&
    participant.teamData !== undefined &&
    participant.teamData.images !== null &&
    participant.teamData.images.length > 0
      ? (logoReturn = (
          <div className="">
            <div className="rounded-full flex h-10 w-10  items-center justify-center">
              <img
                className={`h-10 w-10 z-10 `}
                src={participant.teamData.images[0].thumbnail}
                alt={participant.teamData.name}
              />
            </div>
          </div>
        ))
      : (logoReturn = <div className="h-10 w-10" />);

    return logoReturn;
  };

  const score = (
    <div className="flex  justify-between h-10 text-xl">
      <div className="mr-1 flex align-middle items-center">
        <span
          className={` ${
            matchData.participants[0].winner
              ? "font-bold text-opacity-90"
              : "text-gray-400 text-opacity-70 font-normal"
          }`}
        >
          {matchData.participants[0] !== undefined &&
            matchData.participants[0].score !== undefined &&
            matchData.participants[0].score}
        </span>
      </div>
      <div className="ml-1 flex align-middle items-center">
        <span
          className={` ${
            matchData.participants[1].winner
              ? "font-bold text-opacity-90"
              : "text-gray-400 text-opacity-70 font-normal"
          } `}
        >
          {matchData.participants[1] !== undefined &&
            matchData.participants[1].score !== undefined &&
            matchData.participants[1].score}
        </span>
      </div>
    </div>
  );

  return (
    <div className="">
      <div className="w-full  py-2 flex  justify-between h-14 text-xs text-white bg-serieRight2">
        <Link
          to={`/team/${teamLink(
            serieData.participants[0].teamData,
            serieData.game[0]
          )}`}
        >
          <div
            className={`flex pl-1 ${
              mobile ? "w-32" : "w-44"
            }  border-white border hover:border-opacity-50 hover:bg-hoverBackground hover:rounded-md border-opacity-0`}
          >
            <div
              className={`${!matchData.participants[0].winner && "grayscale"}`}
            >
              {logo(serieData.participants[0])}
            </div>
            <div className="h-10 align-middle items-center flex ml-2">
              <span
                className={`${mobile ? "text-sm" : "text-base"} ${
                  matchData.participants[0].winner
                    ? "font-bold text-opacity-90"
                    : "text-gray-400 text-opacity-70 font-normal"
                }`}
              >
                {serieData.participants[0].teamData.name.length > 10
                  ? serieData.participants[0].teamData.abbreviation
                  : serieData.participants[0].teamData.name}
              </span>
            </div>
          </div>
        </Link>
        <div className="w-14">{score}</div>
        <Link
          to={`/team/${teamLink(
            serieData.participants[1].teamData,
            serieData.game[0]
          )}`}
        >
          <div
            className={`flex pr-1 ${
              mobile ? "w-32" : "w-44"
            }w-32 justify-end border-white border hover:border-opacity-50 hover:bg-hoverBackground hover:rounded-md border-opacity-0`}
          >
            <div className="h-10 align-middle items-center flex mr-2">
              <span
                className={`${mobile ? "text-sm" : "text-base"} ${
                  matchData.participants[1].winner
                    ? "font-bold text-opacity-90"
                    : "text-gray-400 text-opacity-70 font-normal"
                }`}
              >
                {serieData.participants[1].teamData.name.length > 10
                  ? serieData.participants[1].teamData.abbreviation
                  : serieData.participants[1].teamData.name}
              </span>
            </div>
            <div
              className={`${!matchData.participants[1].winner && "grayscale"}`}
            >
              {logo(serieData.participants[1])}
            </div>
          </div>
        </Link>
      </div>
      {matchData !== undefined &&
        matchData.detailed_match !== undefined &&
        serieData.game[0].id !== 5 && (
          <div className="w-full mt-2 py-2 flex-col bg-serieRight2  ">
            <Networth
              detailed_match={matchData.detailed_match}
              serieData={serieData.participants}
              game={serieData.game}
              mobile={mobile}
            />
          </div>
        )}
      {/*       {matchData.detailed_match !== undefined && serieData.game[0].id === 5 && (
        <div className="w-full mt-2 py-2 flex-col bg-serieRight2  ">
          {matchData.detailed_match.past_rounds.map((pr) => (
            <p>{pr.win_reason.id}</p>
          ))}
        </div>
      )} */}
      {matchData !== undefined &&
        serieData.game[0].id === 5 &&
        matchData.detailed_match !== undefined &&
        matchData.detailed_match.past_rounds !== undefined &&
        matchData.detailed_match.past_rounds.length > 0 &&
        winreasons !== undefined &&
        winreasons.length > 0 &&
        serieData !== undefined &&
        serieData.participants !== undefined && (
          <>
            <div className="grow mt-2 py-2 flex-col bg-serieRight2   ">
              <CSPastRounds
                detailed_match={matchData.detailed_match}
                serieData={serieData.participants}
                mobile={mobile}
                game={serieData.game}
                winreasons={winreasons}
              />
            </div>
            <div className="w-auto mt-2 py-2 flex-col bg-serieRight2   ">
              <PlayerStats
                detailed_match={matchData.detailed_match}
                serieData={serieData.participants}
                mobile={mobile}
                game={serieData.game}
                players={serieData.players}
              />
            </div>
          </>
        )}

      {matchData !== undefined &&
        (serieData.game[0].id === 1 || serieData.game[0].id === 2) &&
        matchData.detailed_match !== undefined &&
        serieData !== undefined &&
        serieData.participants !== undefined && (
          <div className="w-auto mt-2 py-2 flex-col bg-serieRight2   ">
            <PlayerStats
              detailed_match={matchData.detailed_match}
              serieData={serieData.participants}
              mobile={mobile}
              game={serieData.game}
              players={serieData.players}
              draft={serieData.draft}
            />
          </div>
        )}

      {matchData !== undefined &&
        matchData.detailed_match !== undefined &&
        serieData !== undefined &&
        serieData.game[0].id === 1 &&
        serieData.draft !== undefined &&
        serieData.participants !== undefined && (
          <div className="w-auto mt-2 py-2 flex-col bg-serieRight2    ">
            <Draft
              detailed_match={matchData.detailed_match}
              serieData={serieData.participants}
              mobile={mobile}
              game={serieData.game}
              players={serieData.players}
              draft={serieData.draft}
            />
          </div>
        )}
    </div>
  );
}
