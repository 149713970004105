import { useEffect, useState } from "react";
import React from "react";
import Player from "../Player/Player";

export default function TeamInformation({
  teamData = false,
  mobile = false,
  games,
}) {
  // console.log(teamData.team[0].region);
  // console.log(teamData);
  // console.log(games);
  return (
    <>
      {teamData !== false ? (
        <div className={` rounded-tr-md ${mobile ? "" : ""}`}>
          <div className="flex flex-col">
            <div
              className={`flex bg-serieRight2 rounded-t-md ${
                mobile ? "py-2" : "py-5"
              }  shadow-md border border-white border-opacity-20`}
            >
              <div className={`${mobile ? "ml-2" : "ml-5"}`}>
                {teamData.team[0].images !== null &&
                teamData.team[0].images.length > 0 &&
                teamData.team[0].images[0].url !== undefined &&
                teamData.team[0].images[0].url !== "" ? (
                  <img
                    src={teamData.team[0].images[0].url}
                    className="h-20 "
                    alt={`${teamData.team.name} logo`}
                  ></img>
                ) : null}
              </div>
              <div className={`flex flex-col ${mobile ? "ml-2" : "ml-4"}`}>
                <div>
                  {teamData.team[0].name !== undefined ? (
                    <h1
                      className={` text-white ${
                        mobile ? "text-3xl" : "text-4xl"
                      } `}
                    >
                      {teamData.team[0].name}
                    </h1>
                  ) : null}
                </div>
                <div className="flex mt-2 ">
                  {games !== undefined &&
                    teamData.team[0].game[0] !== undefined && (
                      <div className="flex">
                        <img
                          src={
                            games.filter(
                              (g) => g.id === teamData.team[0].game[0].id
                            )[0].defaultThumbnail
                          }
                          className="h-6 rounded-sm"
                        />
                        <span className={`ml-2 text-white text-opacity-60`}>
                          {mobile
                            ? games.filter(
                                (g) => g.id === teamData.team[0].game[0].id
                              )[0].abbreviation
                            : games.filter(
                                (g) => g.id === teamData.team[0].game[0].id
                              )[0].title}
                        </span>
                      </div>
                    )}
                  <div></div>

                  {/*                   {teamData.team[0].region !== null &&
                  teamData.team[0].region.country !== undefined &&
                  teamData.team[0].region.country !== null &&
                  teamData.team[0].region.country.images !== undefined &&
                  teamData.team[0].region.country.images.length > 0 &&
                  teamData.team[0].region.country.images[0].url !==
                    undefined ? (
                    <img
                      src={teamData.team[0].region.country.images[0].url}
                      className=" h-3 "
                      alt={`${teamData.team[0].name} logo`}
                    ></img>
                  ) : null}
                  {teamData.team[0].region !== null &&
                  teamData.team[0].region.country !== null ? (
                    <span className=" ml-2 text-white text-xs text-opacity-50 ">
                      {teamData.team[0].region.country.name}
                    </span>
                  ) : null} */}
                </div>
              </div>
            </div>
            {/*                     players start here */}
            {teamData.rosterID !== undefined && (
              <>
                <div className="">
                  <div className="flex">
                    <div className="mt-5 bg-elementBackground pr-2 border-t border-r border-l border-white border-opacity-20 rounded-tr-md">
                      <h2
                        className={`${
                          mobile ? "text-sm" : "text-lg"
                        } first-letter:ml-1 font-bold uppercase text-white text-opacity-80  `}
                      >
                        {`Current lineup`}
                      </h2>
                    </div>
                  </div>
                </div>
                <div
                  className={` bg-serieRight shadow-md m-0 rounded-tr-md border border-white border-opacity-20 ${
                    mobile ? "" : ""
                  }`}
                >
                  <div className=""></div>
                  {teamData.roster.players !== undefined &&
                  teamData.roster.players.length > 0 ? (
                    <div
                      className={`${
                        mobile ? "grid grid-cols-3 gap-0" : "flex"
                      }`}
                    >
                      {mobile &&
                        teamData.roster.players.map((player, index) => (
                          <div
                            className={`${
                              mobile &&
                              "border-b border-white border-opacity-20"
                            } border-r ${
                              index > 5 &&
                              "border-r border-white border-opacity-20"
                            } `}
                            key={player.id}
                          >
                            <Player
                              player={player}
                              mobile={mobile}
                              key={player.id}
                            />
                          </div>
                        ))}
                      {!mobile &&
                        teamData.roster.players.map((player, index) => (
                          <div
                            className={`w-1/${teamData.roster.players.length} 
                                 ${
                                   index !==
                                     teamData.roster.players.length - 1 &&
                                   "border-r border-white border-opacity-20"
                                 } ${
                              index > 4 &&
                              "border-r border-white border-opacity-20"
                            }`}
                            key={player.id}
                          >
                            <Player player={player} key={player.id} />
                          </div>
                        ))}
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
