import React from "react";
import TimeLeft from "./TimeLeft";
import LiveGame from "./LiveGame";
import { Link } from "react-router-dom";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";

function TopSerieSectionMobile({
  start,
  lifecycle,
  data,
  handleTwitchPlayer,
  completedSeries,
  mobile,
}) {
  const displayTitles = () => {
    let displayString = data.tournament[0].short_title + " - " + data.title;
    if (displayString.length > 20) {
      if (data.tournament[0].title.length > 20) {
        return data.tournament[0].short_title;
      } else {
        return data.tournament[0].title;
      }
    } else {
      return displayString;
    }
  };

  const tournamentLink = () => {
    let gameLink = data.game[0].abbreviation
      .toLowerCase()
      .replace(/[:\s\-]/g, "");

    let link = data.tournament[0].title;
    link = link.toLowerCase();
    link = link.replace(/#/g, "");
    link = link.replace(/\s/g, "-");
    link = link.replace(/\//g, "-");
    link = link + "-" + data.tournament[0].id;
    return gameLink + "/" + link;
  };

  let gameLink = (abbreviation) => {
    let returnString = abbreviation;
    returnString = returnString.toLowerCase();
    returnString = returnString.replace(/[:\s\-]/g, "");
    return returnString;
  };

  const link = () => {
    let urlString = "";

    if (data.game[0].abbreviation !== undefined) {
      let game = data.game[0].abbreviation;
      game = game.replace(/[:\s\-]/g, "").toLowerCase();

      urlString += "/match/" + game + "/";
    }

    if (data.participants[0] !== undefined) {
      urlString += data.participants[0].teamData.name
        .replace(/ /g, "-")
        .toLowerCase();
    }
    if (data.participants[1] !== undefined) {
      urlString +=
        "-vs-" +
        data.participants[1].teamData.name.replace(/ /g, "-").toLowerCase();
    }

    if (data.title !== undefined) {
      let tempString = data.title;
      tempString = tempString.replace(/ - /g, "-");
      urlString += "-" + tempString.replace(/ /g, "-").toLowerCase();
    }

    if (
      data.tournament !== undefined &&
      data.tournament.length > 0 &&
      data.tournament[0].title !== undefined
    ) {
      urlString +=
        "-" +
        data.tournament[0].title
          .replace(/ /g, "-")
          .replace(/#/g, "")
          .toLowerCase();
    }

    if (data.id !== undefined) {
      urlString += "-" + data.id;
    }

    return urlString;
  };

  return (
    <div className="flex w-full p-1  bg-elementBackground justify-between">
      <div className="flex">
        <div className="flex w-7 h-7">
          <img
            src={data.game[0].defaultThumbnail}
            alt={data.game[0].title}
            className="rounded-sm"
          />
        </div>
        <div>
          <Link to={`/tournament/${tournamentLink()}`}>
            <span className="ml-2 text-xs text-white mt-1">
              {displayTitles()}
            </span>
          </Link>
        </div>
      </div>
      <div className="flex justify-end">
        {lifecycle === "live" ? (
          <LiveGame data={data} handleTwitchPlayer={handleTwitchPlayer} />
        ) : (
          <div className="h-7  w-44  flex justify-end">
            <div className="mr-2">
              <TimeLeft
                start={start}
                lifecycle={data.lifecycle}
                completedSeries={completedSeries}
                mobile={mobile}
              />
            </div>
            <div>
              <Link to={link()}>
                <button className="flex  hover:bg-serieRight2 py-half  px-1 rounded text-white text-opacity-80 bg-serieRight2  w-20">
                  <div className="font-semibold flex align-middle items-center">
                    <span className="ml-1">MATCH</span>
                    <ChevronDoubleRightIcon className="w-4 h-4 font-semibold text-red-500 text-opacity-70 " />
                  </div>
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TopSerieSectionMobile;
