import React, { useState, useEffect } from "react";

export default function Draft({
  detailed_match,
  serieData,
  mobile,
  game,
  players,
  draft,
}) {
  const [homeTeam, setHomeTeam] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);
  //console.log(data);

  useEffect(() => {
    if (
      detailed_match.last_state !== undefined &&
      serieData !== undefined &&
      serieData.length === 2
    ) {
      let homeFound = false;
      let awayFound = false;

      const homeRoster = detailed_match.last_state.teams.home.roster.id;
      //console.log(homeRoster);
      //try to find a match
      const homeTeamData = serieData.filter(
        (team) => team.roster.id === homeRoster
      );
      if (homeTeamData.length > 0) {
        homeFound = true;
        setHomeTeam(homeTeamData[0]);
      }

      const awayRoster = detailed_match.last_state.teams.away.roster.id;
      // console.log(awayRoster);
      //try to find a match
      const awayTeamData = serieData.filter(
        (team) => team.roster.id === awayRoster
      );
      if (awayTeamData.length > 0) {
        awayFound = true;
        setAwayTeam(awayTeamData[0]);
      }

      if (homeFound && !awayFound) {
        const awayTeamData = serieData.filter(
          (team) => team.roster.id !== homeRoster
        );
        if (awayTeamData.length > 0) {
          awayFound = true;
          setAwayTeam(awayTeamData[0]);
        }
      }

      if (!homeFound && awayFound) {
        const homeTeamData = serieData.filter(
          (team) => team.roster.id !== awayRoster
        );
        if (homeTeamData.length > 0) {
          homeFound = true;
          setHomeTeam(homeTeamData[0]);
        }
      }
    }
  }, [serieData, detailed_match]);

  const teamDraft = (draft, home = false) => {
    return (
      <div
        className={` text-white text-opacity-80 text-xs mt-2 ${
          mobile ? "flex-col" : "flex"
        }`}
      >
        <div className="flex-col ml-2">
          <div>
            {home
              ? homeTeam.teamData.name + " picks"
              : awayTeam.teamData.name + " picks"}
          </div>
          <div className="flex">
            {draft.picks.map((p) => (
              <div key={p.hero.id}>{draftImage(p.hero.id, 18)}</div>
            ))}
          </div>
        </div>
        <div className="flex-col ml-2">
          <div>
            {home
              ? homeTeam.teamData.name + " bans"
              : awayTeam.teamData.name + " bans"}
          </div>
          <div className="flex">
            {draft.bans.map((p) => (
              <div key={p.hero.id}>{draftImage(p.hero.id, 18)}</div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const draftImage = (hero, size) => {
    // console.log("DRAFTIMAGE");

    //console.log(hero, detailed_match, draft);
    let draftImageReturn = <div className={`w-${mobile ? 12 : size} `}></div>;
    if (hero !== undefined && draft !== undefined) {
      let heroData = draft.filter((h) => h.id === hero);
      //      console.log(heroData);
      if (heroData.length > 0 && heroData[0].images.length > 0) {
        {
          //        console.log("HERE2?");
          draftImageReturn = (
            <div className={`w-${mobile ? 12 : size} ${!mobile && "mr-1"} `}>
              <img
                src={
                  heroData[0].images.filter(
                    (image) => image.type === "large"
                  )[0].url
                }
                alt={"draft hero image"}
                title={heroData[0].name}
                className="rounded-sm"
              />
            </div>
          );
        }
      }

      return draftImageReturn;
    }
  };
  return (
    <div className={` flex overflow-x-auto `}>
      <div className="flex-col">
        {detailed_match !== undefined &&
          detailed_match.last_state !== undefined &&
          detailed_match.last_state.teams.home.draft !== undefined &&
          homeTeam !== null &&
          teamDraft(detailed_match.last_state.teams.home.draft, true)}
        {detailed_match !== undefined &&
          detailed_match.last_state !== undefined &&
          detailed_match.last_state.teams.away.draft !== undefined &&
          awayTeam !== null &&
          teamDraft(detailed_match.last_state.teams.away.draft)}
      </div>
    </div>
  );
}
