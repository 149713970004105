import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import teamLink from "../../components/utils/teamLink";

export default function TrendOdds({ mobile, matchData, games, bookies }) {
  const [highestHomeOdds, setHighestHomeOdds] = useState(null);
  const [highestAwayOdds, setHighestAwayOdds] = useState(null);
  const [highestDrawOdds, setHighestDrawOdds] = useState(null);
  const [lowestHomeOdds, setLowestHomeOdds] = useState(null);
  const [lowestDrawOdds, setLowestDrawOdds] = useState(null);
  const [lowestAwayOdds, setLowestAwayOdds] = useState(null);

  const [key, setKey] = useState(null);

  const [bookiesPresent, setBookiesPresent] = useState([]);

  useEffect(() => {
    let key;
    //console.log("MATCHDATA:", matchData);

    if (
      matchData !== undefined &&
      matchData.odds !== undefined &&
      matchData.best_of === 2
    ) {
      key = "oneDrawTwo";
      setKey("oneDrawTwo");
    } else if (
      matchData !== undefined &&
      matchData.odds !== undefined &&
      matchData.best_of !== 2
    ) {
      key = "oneTwo";
      setKey("oneTwo");
    }

    if (
      matchData !== undefined &&
      matchData.odds !== undefined &&
      matchData.odds[0][key] !== undefined
    ) {
      //console.log(matchData.odds[0].oneTwo);

      setHighestHomeOdds(
        matchData.odds[0][key].home.reduce((prev, current) => {
          // Changed the > to a <
          return prev.odds > current.odds ? prev : current;
        })
      );
      setLowestHomeOdds(
        matchData.odds[0][key].home.reduce((prev, current) => {
          // Changed the > to a <
          return prev.odds < current.odds ? prev : current;
        })
      );
      if (key === "oneDrawTwo") {
        setHighestDrawOdds(
          matchData.odds[0][key].draw.reduce((prev, current) => {
            // Changed the > to a <
            return prev.odds > current.odds ? prev : current;
          })
        );
        setLowestDrawOdds(
          matchData.odds[0][key].draw.reduce((prev, current) => {
            // Changed the > to a <
            return prev.odds < current.odds ? prev : current;
          })
        );
      }

      setHighestAwayOdds(
        matchData.odds[0][key].away.reduce((prev, current) => {
          // Changed the > to a <
          return prev.odds > current.odds ? prev : current;
        })
      );
      setLowestAwayOdds(
        matchData.odds[0][key].away.reduce((prev, current) => {
          // Changed the > to a <
          return prev.odds < current.odds ? prev : current;
        })
      );

      //matchData.odds[0], key);
      let unique_bookmakers = [
        ...new Set(
          matchData.odds[0][key].home.map((element) => element.bookmakerID)
        ),
      ];

      setBookiesPresent(unique_bookmakers);
      //get the unique bookmakers present...
    }
  }, [matchData.odds]);

  const displayOdds = (odds, high = false) => {
    //console.log(odds);
    /*     console.log(
      "TEST:",
      bookies.filter((bookie) => bookie.bookieId === odds.bookmakerID)
    ); */
    return (
      <a
        href={
          bookies.filter((bookie) => bookie.bookieId == odds.bookmakerID)[0]
            .affiliateLink
        }
        target="blank"
        rel="noopener noreferrer"
      >
        <div className="flex-col rounded-t-md w-16 pt-1  bg-serieLeft2 rounded-b-md text-white  hover:text-red-500 border-serieLeft2 border-2 hover:cursor-pointer hover:bg-hoverBackground    hover:border-white">
          <div
            className={`flex justify-center rounded-t-md ${
              high && "border-b  border-white border-opacity-40"
            } ${!high && "h-6"} `}
          >
            <p className="text-sm">{odds.odds}</p>
          </div>
          {high && (
            <div className="flex h-3  justify-center mt-1 rounded-b-md w-full">
              <img
                className="w-10"
                src={
                  bookies.filter(
                    (bookie) => bookie.bookieId == odds.bookmakerID
                  )[0].affiliateSmallLogoUrl
                }
              />
            </div>
          )}
        </div>
      </a>
    );
  };
  const calculateDelta = (high, low) => {
    let diff = high.odds - low.odds;
    let delta = ((diff / high.odds) * 100).toFixed(1);

    return delta;
  };

  //console.log("HD:", highestDrawOdds);

  const headerText = () => {
    if (matchData !== undefined && matchData.participants.length === 2) {
      let returnString = "";
      returnString +=
        matchData.participants.filter((part) => part.seed === 1)[0].teamData
          .name || "TBD";
      returnString += " vs ";
      returnString +=
        matchData.participants.filter((part) => part.seed === 2)[0].teamData
          .name || "TBD";

      return returnString;
    }
  };

  const displayBookieRow = (bookie, index) => {
    /*     console.log(bookie);
    console.log(
      bookies.filter((book) => book.bookieId === bookie)[0]
        .affiliateSmallLogoUrl
    ); */

    //console.log("TEST:", matchData.odds[0]);

    return (
      <div
        className={`${
          index === 0 ? "mt-1" : "mt-2  "
        } flex justify-between w-full h-8`}
      >
        <div className="flex align-middle items-center text-opacity-80 w-20 text-center">
          <img
            className=""
            src={
              bookies.filter((book) => book.bookieId === bookie)[0]
                .affiliateSmallLogoUrl
            }
          />
        </div>
        <div className="flex ">
          <div className="w-16 text-center">
            {displayOdds({
              odds: matchData.odds[0][key].home.filter(
                (odd) => odd.bookmakerID == bookie
              )[0].odds,
              bookmakerID: bookie,
            })}
          </div>
          {key === "oneDrawTwo" && (
            <div className=" ml-4  w-16 text-center">
              {displayOdds({
                odds: matchData.odds[0][key].draw.filter(
                  (odd) => odd.bookmakerID == bookie
                )[0].odds,
                bookmakerID: bookie,
              })}
            </div>
          )}
          <div className=" ml-4 mr-2 w-16 text-center">
            {displayOdds({
              odds: matchData.odds[0][key].away.filter(
                (odd) => odd.bookmakerID == bookie
              )[0].odds,
              bookmakerID: bookie,
            })}
          </div>
        </div>
      </div>
    );
  };

  //console.log(matchData);
  // console.log(highestHomeOdds);
  // console.log(bookiesPresent);
  return (
    <>
      <div className="flex">
        <div className="mt-5 bg-elementBackground pr-2 border-t border-r border-l border-white border-opacity-20 rounded-tr-md">
          <h2
            className={`${
              mobile ? "text-sm" : "text-lg"
            } first-letter:ml-1 font-bold uppercase text-white text-opacity-80  `}
          >
            {`ODDS for ${headerText()} `}
          </h2>
        </div>
      </div>
      <div className=" mb-10 pt-2 rounded-b-md rounded-tr-md  bg-serieRight2 shadow-md flex-col border-white border border-opacity-20">
        <div className="flex px-2 justify-between">
          <div className="flex align-middle items-center">
            <p className="text-white text-xs font-semibold italic uppercase ">
              HIGHEST ODDS
            </p>
          </div>
          <div className="flex  ">
            <div>
              {highestHomeOdds !== null && displayOdds(highestHomeOdds, true)}
            </div>
            {key === "oneDrawTwo" && (
              <div className=" ml-4 ">
                {highestDrawOdds !== null && displayOdds(highestDrawOdds, true)}
              </div>
            )}
            <div className=" ml-4 mr-2">
              {highestAwayOdds !== null && displayOdds(highestAwayOdds, true)}
            </div>
          </div>
        </div>

        <div className="mt-3 px-2 flex justify-between uppercase text-white text-xs text-opacity-50">
          <div className="flex align-middle items-center text-opacity-80 w-20 text-center">
            <p className="text-white text-xs text-opacity-50 uppercase w-20">
              bookmakers
            </p>
          </div>
          <div className="flex">
            <p className="w-16 text-center">Home</p>
            {key === "oneDrawTwo" && (
              <p className=" ml-4  w-16 text-center">Draw</p>
            )}
            <p className=" ml-4 mr-2 w-16 text-center">Away</p>
          </div>
        </div>
        <div className="px-2">
          {bookiesPresent.length > 0 &&
            bookiesPresent.map((bookie, index) => (
              <div className="flex">{displayBookieRow(bookie, index)}</div>
            ))}
        </div>
        {matchData !== undefined &&
          matchData.odds !== undefined &&
          matchData.odds[0] !== undefined &&
          key !== null &&
          matchData.odds[0][key].home !== undefined &&
          matchData.odds[0][key].home.length > 1 && (
            <>
              <div className="px-2 mt-2 h-8 bg-headerBackground w-full flex justify-between text-xs text-white text-opacity-60 align-middle">
                <div className="w-20 text-center align-middle items-center h-auto flex">
                  <p className="">Highest odds</p>
                </div>
                <div className="flex align-middle items-center">
                  <p className="w-16 text-center">
                    {highestHomeOdds !== null && highestHomeOdds.odds}
                  </p>
                  {key === "oneDrawTwo" && (
                    <p className=" ml-4 mr-2 w-16 text-center">
                      {highestDrawOdds !== null && highestDrawOdds.odds}
                    </p>
                  )}
                  <p className=" ml-4 mr-2 w-16 text-center">
                    {highestAwayOdds !== null && highestAwayOdds.odds}
                  </p>
                </div>
              </div>
              <div className="px-2 h-8 bg-serieLeft2 w-full flex justify-between text-xs text-white text-opacity-60 align-middle ">
                <div className="w-20 text-center align-middle items-center h-auto flex">
                  <p className="">Lowest odds</p>
                </div>
                <div className="flex align-middle items-center">
                  <p className="w-16 text-center">
                    {lowestHomeOdds !== null && lowestHomeOdds.odds}
                  </p>
                  {key === "oneDrawTwo" && (
                    <p className=" ml-4 mr-2 w-16 text-center">
                      {lowestDrawOdds !== null && lowestDrawOdds.odds}
                    </p>
                  )}
                  <p className=" ml-4 mr-2 w-16 text-center">
                    {lowestAwayOdds !== null && lowestAwayOdds.odds}
                  </p>
                </div>
              </div>
              <div className="px-2 h-8 bg-headerBackground rounded-b-md w-full flex justify-between text-xs text-white text-opacity-60 align-middle ">
                <div className="w-20 text-center align-middle items-center h-auto flex rounded-b-md">
                  <p className="">Delta %</p>
                </div>
                <div className="flex align-middle items-center">
                  <p className="w-16 text-center">
                    {highestHomeOdds !== null &&
                      lowestHomeOdds !== null &&
                      `${calculateDelta(highestHomeOdds, lowestHomeOdds)}%`}
                  </p>
                  {key === "oneDrawTwo" && (
                    <p className=" ml-4 mr-2 w-16 text-center">
                      {lowestDrawOdds !== null &&
                        lowestDrawOdds.odds &&
                        `${calculateDelta(highestDrawOdds, lowestDrawOdds)}%`}
                    </p>
                  )}
                  <p className=" ml-4 mr-2 w-16 text-center">
                    {lowestAwayOdds !== null &&
                      lowestAwayOdds.odds &&
                      `${calculateDelta(highestAwayOdds, lowestAwayOdds)}%`}
                  </p>
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
}
