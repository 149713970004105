import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TournamentInformation from "../components/Tournament/TournamentInformation";
import { Helmet } from "react-helmet-async";
import Lottie from "lottie-light-react";
import loadingAnimation from "../assets/loading_1.json";
import TournamentSeriesUpcoming from "../components/Teams/TeamUpcomingSeries";
import TournamentSeriesUpcomingLiveSeries from "../components/Teams/TeamLivesSeries";
import TournamentSeriesUpcomingCompletedSeries from "../components/Teams/TeamCompletesSeries";
import TournamentStages from "../components/Tournament/TournamentStages";
import TournamentParticipants from "../components/Tournament/TournamentParticipants";

export default function NewTournamentPage({
  mobile = false,
  games,
  locationFilter,
  cryptoOnly,
  refetchData,
  bookies,
}) {
  let params = useParams();
  let navigate = useNavigate();
  const didMount = useRef(false);

  const fetchTournament = async (id) => {
    //console.log("FETCH TOURNAMENT ID: ", id);

    let res = await fetch(
      `${process.env.REACT_APP_BE_URL}/api/tournaments/tournament?id=${id}&locationFilter=${locationFilter}&crypto=${cryptoOnly}`,
      {
        credentials: "same-origin",
      }
    );

    res = await res.json();
    //console.log("RES:", res);
    return res;
  };

  //console.log(params);

  //console.log("MOBILE: ", mobile);

  const [tournamentData, setTournamentData] = useState(null);
  const [tournamentSeries, setTournamentSeries] = useState([]);
  const [loadedID, setLoadedID] = useState(null);
  const [reloading, setReloading] = useState(false);
  const [liveSeries, setLiveSeries] = useState([]);
  const [nextSeries, setNextSeries] = useState([]);

  useEffect(() => {
    if (tournamentSeries.length > 0) {
      //console.log("TOURNAMENTSERIES");
      let lSeries = tournamentSeries.filter(
        (serie) => serie.lifecycle === "live"
      );
      if (lSeries.length > 0) {
        setLiveSeries(lSeries);
      }

      //LETS DO NEXT GAMES WITHIN NEXT 48 HOURS
      let today = new Date();
      let date = today.setDate(today.getDate() + 2);
      //console.log("DATE: ", new Date(date));

      let upcoming = tournamentSeries.filter((serie) => {
        if (new Date(serie.start) < new Date(date)) {
          if (serie.lifecycle === "upcoming") return serie;
        }
      });

      if (upcoming.length > 0) {
        setNextSeries(upcoming);
      }
      //console.log("UPC", upcoming);
    }
  }, [tournamentSeries]);

  useEffect(() => {
    (async () => {
      //console.log(params);

      let calculateID = params.tournamentId;
      let IDStart = calculateID.lastIndexOf("-");
      let subString = calculateID.substring(IDStart + 1);
      //console.log(subString);
      if (loadedID !== null && loadedID !== calculateID) {
        setReloading(true);
      }
      let res = await fetchTournament(subString);
      //console.log(res);

      if (res.success) {
        setTournamentData(res.data);
        setTournamentSeries(res.series.series);
        setReloading(false);
        if (loadedID === null) {
          setLoadedID(calculateID);
        }
      } else {
        navigate("/");
      }
    })();

    window.scrollTo(0, 0);
    //setTournamentData(res[0]);
  }, [params.tournamentId, navigate]);

  useEffect(() => {
    if (didMount.current) {
      (async () => {
        //console.log(params);

        let calculateID = params.tournamentId;
        let IDStart = calculateID.lastIndexOf("-");
        let subString = calculateID.substring(IDStart + 1);
        //console.log(subString);

        setReloading(true);

        let res = await fetchTournament(subString);
        //console.log(res);

        if (res.success) {
          setTournamentData(res.data);
          setTournamentSeries(res.series.series);
          setReloading(false);
          if (loadedID === null) {
            setLoadedID(calculateID);
          }
        } else {
          navigate("/");
        }
      })();

      window.scrollTo(0, 0);
    } else {
      didMount.current = true;
    }
    //setTournamentData(res[0]);
  }, [refetchData]);

  //console.log("UPCOMING: ", upcomingSeriesData);

  //console.log(tournamentData);
  return (
    <>
      <Helmet>
        <title>
          {tournamentData !== null
            ? `OddsArena | ${tournamentData.title}`
            : "OddsArena | Tournament"}
        </title>
        {tournamentData !== null && (
          <meta
            name="description"
            content={`OddsArena | ${tournamentData.title} in ${
              tournamentData.game[0].title
            } - matches, streams, results and odds. ${
              tournamentData.copy.short_description !== undefined
                ? tournamentData.copy.short_description
                : null
            }`}
          />
        )}
      </Helmet>
      <div className="">
        <div>
          {tournamentData !== null && reloading !== true ? (
            <>
              <TournamentInformation
                tournamentData={tournamentData}
                games={games}
                mobile={mobile}
              />
              <TournamentParticipants tournamentData={tournamentData} />
              {liveSeries !== undefined && liveSeries.length > 0 && (
                <div className="mt-4">
                  <TournamentSeriesUpcomingLiveSeries
                    data={liveSeries}
                    mobile={mobile}
                    bookies={bookies}
                    team={{ name: tournamentData.title }}
                    games={games}
                  />
                </div>
              )}
              {nextSeries !== undefined && nextSeries.length > 0 && (
                <div className="mt-4">
                  <TournamentSeriesUpcoming
                    data={nextSeries}
                    mobile={mobile}
                    bookies={bookies}
                    team={{ name: tournamentData.title }}
                    games={games}
                  />
                </div>
              )}
              <TournamentStages
                data={tournamentData}
                games={games}
                mobile={mobile}
                series={tournamentSeries}
                bookies={bookies}
              />
            </>
          ) : (
            <Lottie animationData={loadingAnimation} style={{ height: 200 }} />
          )}
        </div>
      </div>
    </>
  );
}
