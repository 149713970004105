import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TournamentList from "../components/Tournament/TournamentList";
import Lottie from "lottie-light-react";
import loadingAnimation from "../assets/loading_1.json";

export default function TournamentPage({
  mobile = false,
  filter,
  menuData,
  games,
  setPage,
}) {
  let navigate = useNavigate();
  const { game } = useParams();

  //console.log("GAME: ", game);
  useEffect(() => {
    setPage("tournaments");
  }, []);
  const fetchTournaments = async (id) => {
    //console.log("FETCH TOURNAMENT ID: ", id);
    let res;
    if (game !== undefined) {
      if (games !== undefined) {
        let singleGame = games.filter((sg) => sg.gameMenu === game)[0];

        res = await fetch(
          `${process.env.REACT_APP_BE_URL}/api/tournaments/tournaments?&game=${singleGame.id}`,
          { credentials: "same-origin" }
        );
      }
    } else {
      res = await fetch(
        `${process.env.REACT_APP_BE_URL}/api/tournaments/tournaments`,
        {
          credentials: "same-origin",
        }
      );
    }

    res = await res.json();
    return res;
  };

  //console.log(params);

  //console.log("MOBILE: ", mobile);
  //console.log("INCOMING FILTER", filter);

  const [ongoingTournaments, setOngoingTournaments] = useState([]);
  const [upcomingTournaments, setUpcomingTournaments] = useState([]);
  const [filteredOngoingTournaments, setFilteredOngoingTournaments] = useState(
    []
  );
  const [filteredUpcomingTournaments, setFilteredUpcomingTournaments] =
    useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [gameFiltered, setGameFiltered] = useState(null);

  const handleSelectedTab = (id) => {
    setSelectedTab(id);
  };

  const tabs = [
    { id: 0, name: "Ongoing" },
    { id: 1, name: "Upcoming" },
    //    { id: 2, name: "Past" },
  ];

  useEffect(() => {
    (async () => {
      //console.log(params);
      setLoading(true);
      let res = await fetchTournaments();

      if (res.success) {
        if (res.data.ongoingTournaments.length > 0) {
          setOngoingTournaments(res.data.ongoingTournaments);
        } else if (res.data.ongoingTournaments.length === 0) {
          setOngoingTournaments([]);
        }
        if (res.data.upcomingTournaments.length > 0) {
          setUpcomingTournaments(res.data.upcomingTournaments);
        } else if (res.data.upcomingTournaments.length === 0) {
          setUpcomingTournaments([]);
        }
      } else if (res.success === false) {
        navigate("/");
      }

      setLoading(false);
    })();

    //setTournamentData(res[0]);
  }, [navigate]);

  useEffect(() => {
    if (ongoingTournaments.length > 0) {
      handleSelectedTab(0);
    } else if (
      (ongoingTournaments.length === 0) &
      (upcomingTournaments.length > 0)
    ) {
      handleSelectedTab(1);
    }
  }, [ongoingTournaments, upcomingTournaments]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (filter !== undefined) {
      console.log(filter);
    }
    //console.log(filter);
  }, [filter, menuData]);

  useEffect(() => {
    //console.log(data, typeof filteredGame);
    let tempOngoing = ongoingTournaments;
    let tempUpcoming = upcomingTournaments;

    //console.log("Running useffect");
    if (filter !== undefined && filter !== null && typeof filter === "number") {
      //console.log(filterSplitted);

      if (filter !== 0) {
        //console.log("inside game filter");
        if (tempOngoing.length > 0)
          tempOngoing = tempOngoing.filter(
            (tournament) => tournament.game[0].id === parseInt(filter)
          );

        if (tempUpcoming.length > 0) {
          tempUpcoming = tempUpcoming.filter(
            (tournament) => tournament.game[0].id === parseInt(filter)
          );
        }
      }
    }
    setFilteredOngoingTournaments(tempOngoing);
    setFilteredUpcomingTournaments(tempUpcoming);
    //prepare object
  }, [upcomingTournaments, ongoingTournaments, filter]);

  return (
    <>
      <div
        className={`flex flex-col w-full px-3 py-2 bg-elementBackground rounded-md shadow-lg  max-w-none border border-white border-opacity-60 ${
          !mobile && "w-1/2"
        }`}
      >
        <h2 className="uppercase text-white font-bold text-2xl">{`Tournaments`}</h2>
        <p className="  text-white text-base text-opacity-80">
          Schedule for ongoing and upcoming tournaments.
        </p>
        <p className="text-white text-xs text-opacity-40 italic">
          Past tournaments can be accessed using the searchbar.
        </p>
      </div>

      {loading === true && (
        <Lottie animationData={loadingAnimation} style={{ height: 200 }} />
      )}

      <div className=" h-8 flex text-white mb-2  justify-between mt-8 ">
        <div>
          {!loading && (
            <div className="flex">
              {filteredOngoingTournaments.length > 0 && (
                <div className="">
                  <button
                    className={`w-32 h-8 pl-3 pr-3  pt-1 pb-1 rounded-l-md shadow-md uppercase border-t border-b border-r border-l border-white border-opacity-60   ${
                      selectedTab === 0
                        ? "bg-serieRight2 font-bold text-opacity-90"
                        : "bg-elementBackground text-gray-500 hover:text-red-600 "
                    } ${
                      filteredUpcomingTournaments.length === 0 &&
                      "rounded-r-md border-r"
                    }`}
                    onClick={() => handleSelectedTab(0)}
                  >
                    {tabs[0].name}
                  </button>
                </div>
              )}
              {filteredUpcomingTournaments.length > 0 && (
                <div>
                  <button
                    className={`${
                      ongoingTournaments.length < 1 && "rounded-l-md "
                    } w-32 h-8 shadow-md  pl-2 pb-1 pt-1 pr-3 bg-elementBackground uppercase rounded-r-md border-l border-white border-t border-b border-r border-opacity-60 ${
                      selectedTab === 1
                        ? "bg-serieRight2 font-bold text-opacity-90"
                        : "bg-elementBackground  text-gray-500 hover:text-red-600 "
                    } ${
                      filteredOngoingTournaments.length === 0 &&
                      "rounded-l-md border-l"
                    } `}
                    onClick={() => handleSelectedTab(1)}
                  >
                    {tabs[1].name}
                  </button>
                </div>
              )}
              {/*               {filteredUpcomingTournaments.length > 0 && (
                <div>
                  <button
                    className={`${
                      ongoingTournaments.length > 0
                        ? "border-l-2 border-white border-opacity-20"
                        : "rounded-tl-md"
                    }  w-32 h-8 shadow-md  pl-2 pb-1 pt-1 pr-3 rounded-r-md bg-elementBackground uppercase font-semibold  ${
                      selectedTab === 2
                        ? "bg-serieRight text-red-500 text-opacity-90"
                        : "bg-elementBackground hover:text-red-600 "
                    }`}
                    onClick={() => handleSelectedTab(2)}
                  >
                    {tabs[2].name}
                  </button>
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
      <div>
        {!loading && (
          <TournamentList
            data={
              selectedTab === 0
                ? filteredOngoingTournaments
                : filteredUpcomingTournaments
            }
          />
        )}
      </div>
    </>
  );
}
