import React, { useState, useEffect } from "react";
import { ChevronDoubleUpIcon } from "@heroicons/react/solid";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const toogleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toogleVisibility);

    return () => {
      window.removeEventListener("scroll", toogleVisibility);
    };
  }, []);

  return (
    <div className="fixed right-2 bottom-20 cursor-pointer z-50">
      {isVisible && (
        <div
          onClick={scrollToTop}
          className=" hover:bg-hoverBackground text-white hover:text-red-600 rounded h-12 w-12 flex align-middle justify-center  bg-transparent border-serieRight border-2"
        >
          <div className="flex justify-center align-middle mt-1  ">
            <ChevronDoubleUpIcon className="w-10 h-10 " alt="scroll to top" />
          </div>
        </div>
      )}
    </div>
  );
}
