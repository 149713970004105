import React, { useEffect, useState, useRef } from "react";
import TwitchPlayer from "../../components/TwitchPlayer";

import { useInView } from "react-intersection-observer";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import SingleMatchHeader from "./SingleMatchHeader";
import SingleMatchTournament from "./SingleMatchTournament";
import SingleMatchTournamentInfo from "./SingleMatchTournamentInfo";
import StreamStart from "./StreamStart";
import MatchHistory from "./MatchHistory";
import MatchOdds from "./MatchOdds";
import MatchList from "./MatchList";
import HeadToHead from "./HeadToHead";
import Lottie from "lottie-light-react";
import loadingAnimation from "../../assets/loading_1.json";
import NextMatch from "./NextMatch";
import PageAd from "../../components/PageAd";
import TrendOdds from "./TrendOdds";

export default function SingleMatch({
  game,
  mobile = false,
  games,
  locationFilter,
  cryptoOnly,
  refetchData,
}) {
  const [matchData, setMatchData] = useState(null);
  const [loadedID, setLoadedID] = useState(null);
  const [reloading, setReloading] = useState(false);
  const [singleRefetch, setSingleRefetch] = useState(false);
  const [twitchGame, setTwitchGame] = useState(null);
  const [twitchPlaying, setTwitchPlaying] = useState(false);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  let params = useParams();
  let navigate = useNavigate();

  // console.log(params);
  /*   useEffect(() => {
    if (game !== undefined && games !== undefined) {
      console.log(games);
      let row = games.filter((sg) => sg.gameMenu === game)[0];
      console.log(row.id);
      setSelection(row.id);
    }
  }, [game]); */

  let overCycles = ["over", "over-draw", "over-forfeited"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const didMount = useRef(false);

  const handleRefetch = () => {
    setSingleRefetch(!singleRefetch);
  };

  const fetchMatch = async (matchID) => {
    //console.log("FETCH Team with Roster ID: ", id);

    let res = await fetch(
      `${process.env.REACT_APP_BE_URL}/api/matches?serie=${matchID}&locationFilter=${locationFilter}&crypto=${cryptoOnly}`,
      {
        credentials: "same-origin",
      }
    );

    res = await res.json();
    // console.log(res);
    return res;
  };

  const trendOdds = async (matchID) => {
    let res = await fetch(
      `${process.env.REACT_APP_BE_URL}/api/odds?serie=${matchID}`,
      {
        credentials: "same-origin",
      }
    );

    res = await res.json();
    //console.log(res);
    return res;
  };

  useEffect(() => {
    (async () => {
      //console.log(params);

      let calculateID = params.serieID;
      let IDStart = calculateID.lastIndexOf("-");
      let subString = calculateID.substring(IDStart + 1);
      //console.log(subString);

      if (loadedID !== null && loadedID !== calculateID) {
        setReloading(true);
      }

      let matchID = parseInt(subString);

      let res = await fetchMatch(matchID);
      let oddsRes = await trendOdds(matchID);
      // console.log(oddsRes);
      //console.log(teamID);

      if (res.success) {
        setMatchData(res.data);
        setReloading(false);
        if (loadedID === null) {
          setLoadedID(calculateID);
        }
      } else {
        navigate("/");
      }
    })();
    window.scrollTo(0, 0);

    //setTournamentData(res[0]);
  }, [params.teamId, navigate]);

  useEffect(() => {
    if (didMount.current) {
      (async () => {
        //console.log(params);

        let calculateID = params.serieID;
        let IDStart = calculateID.lastIndexOf("-");
        let subString = calculateID.substring(IDStart + 1);
        //console.log(subString);

        setReloading(true);

        let matchID = parseInt(subString);

        let res = await fetchMatch(matchID);
        //console.log(res);
        //console.log(teamID);

        if (res.success) {
          setMatchData(res.data);
          setReloading(false);
          if (loadedID === null) {
            setLoadedID(calculateID);
          }
        } else {
          navigate("/");
        }
      })();
      window.scrollTo(0, 0);
    } else {
      didMount.current = true;
    }

    //setTournamentData(res[0]);
  }, [refetchData, singleRefetch]);

  useEffect(() => {
    if (matchData !== null) {
      if (matchData.series.lifecycle === "live") {
        handleTwitchPlayer(matchData.series);
      } else {
        closeTwitchPlayer();
      }
    }

    //setTournamentData(res[0]);
  }, [matchData]);

  useEffect(() => {
    if (matchData !== null) {
      if (matchData.series.lifecycle === "live") {
        handleTwitchPlayer(matchData.series);
      } else {
        closeTwitchPlayer();
      }
    }

    //setTournamentData(res[0]);
  }, [matchData]);

  const handleTwitchPlayer = (data) => {
    setTwitchPlaying(true);
    setTwitchGame(data);

    //console.log("starting player", data);
  };

  const closeTwitchPlayer = () => {
    setTwitchGame(false);
    setTwitchPlaying(false);
  };

  /*   const fetchSingleSerie = async () => {
    let data;
    //console.log(filter);
    data = await fetch(`${process.env.REACT_APP_BE_URL}/api/matches?serie=${serieID}`, {
      credentials: "same-origin",
    });

    data = await data.json();

    return data;
  };

  console.log(serieData); */

  const renderMatchHistory = () => {
    if (matchData.homeTeamSeries.length > 0) {
      return true;
    } else if (matchData.awayTeamSeries.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //console.log(matchData);
  //console.log(twitchPlaying, matchData.series.lifecycle);
  return (
    <>
      <div className="mt-4">
        <Helmet>
          <title>
            {matchData !== null && matchData.series !== undefined
              ? `${
                  matchData.series.participants.length > 0 &&
                  matchData.series.participants[0].teamData.name !== undefined
                    ? matchData.series.participants[0].teamData.name
                    : "TBD"
                } vs ${
                  matchData.series.participants.length === 2 &&
                  matchData.series.participants[1].teamData.name !== undefined
                    ? matchData.series.participants[1].teamData.name
                    : "TBD"
                } - ${matchData.series.game[0].abbreviation} | ${
                  matchData.series.start.split("T")[0]
                }`
              : "OddsArena | "}
          </title>
          {matchData !== null && matchData.series !== undefined && (
            <meta
              name="description"
              content={`Watch the ${
                matchData.series.game[0].abbreviation
              } game between ${
                matchData.series.participants.length > 0 &&
                matchData.series.participants[0].teamData.name !== undefined
                  ? matchData.series.participants[0].teamData.name
                  : "TBD"
              } vs ${
                matchData.series.participants.length === 2 &&
                matchData.series.participants[1].teamData.name !== undefined
                  ? matchData.series.participants[1].teamData.name
                  : "TBD"
              } live @ OddsArena.gg. It's played the ${
                matchData.series.start.split("T")[0]
              } and the match is in ${matchData.series.tournament[0].title} (${
                matchData.series.tournament[0].short_title
              }).`}
            />
          )}
        </Helmet>
        {matchData !== null && reloading !== true ? (
          <>
            <div
              className={`  rounded-md ${mobile ? "" : "p-2"}  w-full ${
                mobile && ""
              }`}
            >
              <div className=" rounded-t-md">
                <SingleMatchHeader
                  className="pb-2"
                  matchData={matchData.series}
                  mobile={mobile}
                />
                <SingleMatchTournamentInfo
                  className="mt-5"
                  tournamentData={matchData.series.tournament[0]}
                  mobile={mobile}
                  matchdata={matchData.series}
                />
              </div>
              {twitchPlaying &&
                twitchGame !== false &&
                !overCycles.includes(matchData.series.lifecycle) &&
                matchData.series.casters !== undefined &&
                matchData.series.casters.length > 0 && (
                  <div className=" mt-2">
                    <TwitchPlayer
                      serie={matchData.series}
                      closeTwitchPlayer={closeTwitchPlayer}
                      style={"bg-serieRight2"}
                      mobile={mobile}
                    />
                  </div>
                )}
              {twitchPlaying === false &&
                matchData.series.lifecycle === "upcoming" && (
                  <StreamStart matchData={matchData} mobile={mobile} />
                )}

              {/*               {matchData.series.participants.length > 1 &&
                matchData.bookies !== undefined &&
                matchData.series.odds !== undefined &&
                !overCycles.includes(matchData.series.lifecycle) && (
                  <>
                    <MatchOdds
                      mobile={mobile}
                      matchData={matchData}
                      games={games}
                    />
                  </>
                )} */}
              {matchData.series.participants.length > 1 &&
                matchData.bookies !== undefined &&
                matchData.series.odds !== undefined &&
                !overCycles.includes(matchData.series.lifecycle) && (
                  <>
                    <TrendOdds
                      mobile={mobile}
                      matchData={matchData.series}
                      bookies={matchData.bookies}
                      games={games}
                    />
                  </>
                )}
              {matchData.series.matches.length > 0 && (
                <MatchList
                  matchData={matchData.series}
                  mobile={mobile}
                  handleRefetch={handleRefetch}
                />
              )}

              {/*               {matchData.series !== undefined && (
                <div className="flex w-full mt-5  ">
                  <PageAd
                    game={
                      params.game === undefined || params.game === "csgo"
                        ? "cyberbet"
                        : "thunder"
                    }
                  />
                </div>
              )} */}

              {renderMatchHistory() === true &&
                !overCycles.includes(matchData.series.lifecycle) && (
                  <MatchHistory
                    homeTeamSeries={matchData.homeTeamSeries}
                    homeTeam={
                      matchData.series.participants.filter(
                        (part) => part.seed === 1
                      )[0]
                    }
                    awayTeamSeries={matchData.awayTeamSeries}
                    awayTeam={
                      matchData.series.participants.filter(
                        (part) => part.seed === 2
                      )[0]
                    }
                    mobile={mobile}
                  />
                )}

              {!overCycles.includes(matchData.series.lifecycle) && (
                <HeadToHead matchData={matchData} mobile={mobile} />
              )}
              {matchData.nextHomeMatch.length > 0 && (
                <NextMatch
                  match={matchData.nextHomeMatch}
                  home={true}
                  bookies={matchData.bookies}
                  team={{
                    name: matchData.series.participants.filter(
                      (part) => part.seed === 1
                    )[0].teamData.name,
                  }}
                  games={games}
                  mobile={mobile}
                />
              )}
              {matchData.nextAwayMatch.length > 0 && (
                <NextMatch
                  match={matchData.nextAwayMatch}
                  home={true}
                  bookies={matchData.bookies}
                  team={{
                    name: matchData.series.participants.filter(
                      (part) => part.seed === 2
                    )[0].teamData.name,
                  }}
                  games={games}
                  mobile={mobile}
                />
              )}
            </div>
          </>
        ) : (
          <Lottie animationData={loadingAnimation} style={{ height: 200 }} />
        )}
      </div>
    </>
  );
}
