import { Popover } from "@headlessui/react";
import React from "react";

export default function MatchItem({
  match,
  mobile,
  index,
  length,
  selectMatch,
  participants,
  selectedIndex,
}) {
  //console.log(match);

  let overCycles = ["over", "over-draw", "over-forfeited"];

  const winnerLogo = () => {
    //need to get team from roster
    //find the winner

    if (overCycles.includes(match.lifecycle)) {
      //console.log(match);
      let winningSeed = match.participants.filter(
        (team) => team.winner === true
      )[0].seed;
      //console.log(winningSeed);
      //get team data
      /*       console.log("WINNER:", winner);
      let winnerRoster = winner.roster.id;
      console.log("winnerroster:", winnerRoster, participants); 
      w */
      //console.log(participants);
      let winningTeam = participants.filter(
        (part) => part.seed === winningSeed
      )[0];
      //console.log(winningTeam);
      return (
        <div
          className={`flex items-center justify-center ${
            selectedIndex === index ? " bg-serieRight2" : "bg-hoverBackground "
          } `}
        >
          {winningTeam.teamData.images !== undefined &&
            winningTeam.teamData.images !== null &&
            winningTeam.teamData.images[0] !== undefined &&
            winningTeam.teamData.images[0].url !== undefined && (
              <img
                className="h-5 w-5"
                src={winningTeam.teamData.images[0].url}
                alt={winningTeam.teamData.name}
              />
            )}
          <div className="flex items-center justify-center text-center text-xs text-white text-opacity-80 h-6">
            <span
              className={`${
                mobile ? "ml-[2px]" : "ml-1"
              } text-xs text-white text-opacity-80`}
            >
              {winningTeam.teamData.name.length > 12
                ? winningTeam.teamData.abbreviation
                : winningTeam.teamData.name}
            </span>
          </div>
        </div>
      );
    } else {
      //continue here
      return (
        <div className="flex items-center justify-center text-center text-xs text-white text-opacity-80 h-6">
          <span>
            {match.game.id === 5 &&
            match.map !== null &&
            match.map.name !== undefined
              ? match.map.name
              : match.lifecycle === "live"
              ? "Ongoing"
              : "Not started"}
          </span>
        </div>
      );
    }
  };
  //console.log(match);

  return (
    <div
      className={`w-1/3 flex-col ${mobile ? "px-[2px]" : "px-1"}   rounded-sm ${
        index !== 0 && "ml-1"
      } border-2 ${
        selectedIndex === index
          ? "border-red-500  bg-serieRight2"
          : "bg-hoverBackground  border-hoverBackground"
      } ${match.lifecycle === "upcoming" ? "" : "cursor-pointer"}`}
      onClick={() =>
        match.lifecycle === "upcoming" ? null : selectMatch(index)
      }
    >
      <div className="flex justify-between h-4 align-middle items-center text-center">
        <div className={`text-xs `}>
          <span className="text-white text-opacity-50 underline">
            Map {index + 1}
          </span>
        </div>
        <div className={`text-xs `}>
          {match.lifecycle === "live" && (
            <span className=" text-opacity-90 text-red-500 font-bold animate-pulse">
              WATCH
            </span>
          )}
          {overCycles.includes(match.lifecycle) && (
            <span className=" text-opacity-30  text-white "></span>
          )}
        </div>
      </div>
      <div className="">{winnerLogo()}</div>
    </div>
  );
}
