import React from "react";

export default function TournamentDescription({
  startDate,
  endDate,
  tournamentData,
}) {
  //console.log(tournamentData);
  return (
    <div className="mt-2">
      <div className="mt-2 md:mt-0 px-3 py-2 rounded-md w-full  bg-serieRight    shadow-md">
        <h2 className=" text-white   text-sm uppercase ">
          Tournament Information
        </h2>
        <div className="flex text-sm text-white text-opacity-80">
          <div className="flex flex-col w-20">
            <span>Duration: </span>
            <span>Region:</span>

            <span>Prize pool:</span>
          </div>
          <div className="flex flex-col">
            <span>{`${startDate} - ${endDate}`}</span>
            <span>{tournamentData.location.host.name}</span>

            <div className="flex">
              <div>{tournamentData.string_prize_pool.total}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-serieRight shadow-md  rounded-md mt-2 py-2 px-3 w-full text-white  max-w-none ">
        <h2 className="  text-sm uppercase ">Description</h2>
        <p
          className="h-auto text-white text-opacity-80 text-sm prose max-w-none"
          dangerouslySetInnerHTML={{
            __html: tournamentData.copy.general_description,
          }}
        ></p>
      </div>
    </div>
  );
}
