import React from "react";
import vsIcon from "../../../assets/icon-vs.png";

export default function Versus() {
  return (
    <div className="absolute transform w-12 h-full flex items-center justify-center overflow-hidden -translate-x-1/2 left-1/2">
      <div className=" bg-serieLeft2 h-24 w-8 absolute right-1/2 transform rotate-20"></div>
      <div className="bg-serieRight2 h-24 w-8 absolute left-1/2 transform rotate-20"></div>
      <img src={vsIcon} className="relative z-20" alt="" />
    </div>
  );
}
