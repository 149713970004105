import React from "react";
import { Link } from "react-router-dom";

export default function MobileLeftTeam({ data, odds, bookies, games }) {
  const link = () => {
    //console.log(data, data.teamData, games);
    if (
      data !== undefined &&
      data.teamData !== undefined &&
      games !== undefined
    ) {
      let game = games.filter((game) => game.id === data.teamData.game.id)[0]
        .abbreviation;
      game = game.replace(/[:\s\-]/g, "").toLowerCase();

      //console.log(game);

      let link = data.teamData.name;
      link = link.toLowerCase();
      link = link.replace(/\s/g, "-");
      link = link.replace(/\//g, "-");
      link += "-" + data.teamData.id;

      let returnLink = game + "/" + link;

      return returnLink;
    }
    return;
  };

  const logo = () => {
    let logoReturn;

    data !== undefined &&
    data.teamData !== undefined &&
    data.teamData.images !== null &&
    data.teamData.images.length > 0
      ? (logoReturn = (
          <div className="rounded-full flex h-10 w-10  items-center justify-center">
            <img
              className="h-8 w-8 z-10"
              src={data.teamData.images[0].thumbnail}
              alt={data.teamData.name}
            />
          </div>
        ))
      : (logoReturn = <div className="h-10 w-10 bg-serieLeft2" />);

    return logoReturn;
  };
  return (
    <div className="flex justify-start h-full bg-serieLeft2 w-1/2 pl-1">
      <div className="flex justify-between align-middle text-white items-center w-full">
        {data === undefined ? (
          <div>TBD</div>
        ) : (
          <Link to={`/team/${link()}`}>
            <div className="flex gap-4 pr-8 pl-2">
              <div className="flex align-middle items-center">{logo()}</div>
              <div className="flex align-middle items-center">
                <span className="text-xs font-medium  text-opacity-90">
                  {data.teamData.name.length > 14
                    ? data.teamData.abbreviation
                    : data.teamData.name}
                </span>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}
