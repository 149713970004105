import React from "react";
import TournamentStage from "./TournamentStage";

export default function TournamentStages({
  data,
  mobile,
  games,
  series,
  bookies,
}) {
  //console.log("TOURNAMENTSTAGE: ", series.length);
  //console.log(data.stages);
  return (
    <div className="mt-4">
      {data.stages !== undefined && data.stages.length > 0 && (
        <div>
          <div>
            <h2 className="text-white text-sm uppercase font-bold text-opacity-80  ml-1 underline mt-2">
              Tournament Stages
            </h2>
          </div>
          {data.stages.map((stage, idx) => {
            if (stage.substages !== null && stage.substages.length !== 0) {
              return (
                <TournamentStage
                  game={data.game[0]}
                  data={stage}
                  mobile={mobile}
                  games={games}
                  key={stage.id}
                  teams={data.teams}
                  series={series}
                  bookies={bookies}
                  index={idx}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
