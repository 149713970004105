export default function teamLink(data, selectedGame) {
  //console.log(data, selectedGame);
  if (data !== undefined && selectedGame !== undefined) {
    let game = selectedGame.abbreviation;
    game = game.replace(/[:\s\-]/g, "").toLowerCase();

    //console.log(game);

    let link = data.name;
    link = link.toLowerCase();
    link = link.replace(/\s/g, "-");
    link = link.replace(/\//g, "-");
    link += "-" + data.id;

    let returnLink = game + "/" + link;

    return returnLink;
  }
  return;
}
