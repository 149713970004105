export default function tournamentLink(data, selectedGame, tournament = false) {
  //console.log(data, selectedGame);
  if (data !== undefined && selectedGame !== undefined) {
    let game = selectedGame.abbreviation;
    game = game.replace(/[:\s\-]/g, "").toLowerCase();

    //console.log(game);
    let link;
    if (tournament) {
      link = data.title;
      link = link.toLowerCase();
      link = link.replace(/#/g, "");
      link = link.replace(/\s/g, "-");
      link = link.replace(/\//g, "-");
      link = link + "-" + data.id;
    } else {
      link = data.tournament[0].title;
      link = link.toLowerCase();
      link = link.replace(/#/g, "");
      link = link.replace(/\s/g, "-");
      link = link.replace(/\//g, "-");
      link = link + "-" + data.tournament[0].id;
    }
    let returnLink = game + "/" + link;

    return returnLink;
  }
  return;
}
