import React, { useState } from "react";
import { useForm } from "react-hook-form";
import delay from "../../utils/Delay";

export default function SuggestionForm({ closeSuggestionModal }) {
  const [showSubmissionMessage, setShowSubmissionMessage] = useState(false);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  async function onSubmit(data) {
    let processedData = requestOptions;
    processedData.body = JSON.stringify(data);
    await fetch(
      `${process.env.REACT_APP_BE_URL}/api/suggestions`,
      requestOptions
    );
    setShowSubmissionMessage(true);
    await delay(4000);
    //console.log(response);
    closeSuggestionModal();
  }
  //console.log(process.env.REACT_APP_BE_URL);
  return (
    <div className="w-full">
      <div className=" bg-headerBackground rounded-t shadow-md mt-2">
        <div className="font-bold text-lg md:text-xl  ">
          <span className="ml-4 text-white font-brand ">ODDS</span>
          <span className="mr-2 text-red-600 font-brand ">ARENA</span>
          <span className="text-white font-brand text-base">
            - Improvement form
          </span>
        </div>
      </div>

      <form
        className="bg-white  rounded-b px-8 pt-6 pb-2 mb-4 text-base"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="contact"
          >
            Your E-mail
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.contact && "border-red-500"
            }`}
            id="contact"
            type="text"
            placeholder="Your email address"
            {...register("contact", {
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          {errors.contact && (
            <span className="text-xs text-red-500 ">
              Enter your email address.
            </span>
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="category"
          >
            Improvement Category
          </label>
          <div className="inline-block relative w-full">
            <select
              id="category"
              className="block appearance-none border rounded w-full text-gray-700 px-3 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
              {...register("category")}
              htmlFor="category"
            >
              <option>Site Functionality</option>
              <option>Missing Bookmaker</option>
              <option>Other</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-sm text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="mb-1">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="description"
          >
            Description of improvement
          </label>
          <textarea
            className={`shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline ${
              errors.description && "border-red-500"
            }`}
            rows="5"
            id="description"
            type="password"
            placeholder="Please input a few words to describe your feedback / suggestion"
            {...register("description", { required: true, minLength: 20 })}
          />
          {errors.description && (
            <span className=" text-xs text-red-500">
              Describe your suggestion/feedback
            </span>
          )}
        </div>
        {!showSubmissionMessage ? (
          <div className="flex items-center justify-end">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={closeSuggestionModal}
            >
              Cancel
            </button>
            <button
              className="ml-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        ) : (
          <div>
            <p className="text-base font-bold">Thanks for your submission!</p>
            <p className="test-xs mt-2">
              This window will close automatically.
            </p>
          </div>
        )}

        <p className="text-center text-gray-500 text-xs mt-5">
          On behalf of OddsArena, thanks for helping us become better!
        </p>
      </form>
    </div>
  );
}
