import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
//import YouTube from "react-youtube";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CogIcon,
  XIcon,
} from "@heroicons/react/outline";

export default function TwitchPlayer({
  serie,
  closeTwitchPlayer,
  style = false,
  mobile,
  arena = false,
  handleStreams,
}) {
  const [playing, setPlaying] = useState(false);
  const [playingURL, setPlayingURL] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [casters, setCasters] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (serie !== true) {
      //console.log(serie);

      setPlayingURL(serie.primaryCaster[0].additional_info.url);
      setPlaying(true);

      setPlatform(serie.primaryCaster[0].additional_info.platform.id);
      //console.log(ReactPlayer.canPlay(serie.primaryCaster[0].additional_info.url));
    }
    if (serie.casters !== undefined && serie.casters.length > 0) {
      setCasters(serie.casters);
    }
  }, [serie]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  //console.log(arena);

  const handleShow = () => {
    setShow(!show);
  };

  //console.log(serie);

  //console.log(serie, playingURL);
  return (
    <>
      {playing && playingURL !== null && platform !== null ? (
        <div>
          {!arena && (
            <div className="flex justify-end">
              <button
                className={`py-1 px-3 hover:text-red-600 ${
                  style !== false ? style : "bg-elementBackground"
                } text-sm text-white  rounded-t-md`}
                onClick={() => closeTwitchPlayer()}
              >
                Close Stream
              </button>
            </div>
          )}

          <div className="relative pt-5625 rounded-tl-md group">
            <div className="absolute  invisible top-4 right-4 z-50   group-hover:visible flex-col   text-white ">
              <div>
                <XIcon
                  className="h-9 hover:text-red-500 hover:cursor-pointer"
                  onClick={() => handleStreams(serie, true)}
                />
              </div>
              {serie.casters !== undefined && serie.casters.length > 1 && (
                <div className="relative flex">
                  <CogIcon
                    className="h-9 hover:text-red-500 hover:cursor-pointer"
                    onClick={() => handleShow()}
                  />
                </div>
              )}
              {show &&
                serie.casters !== undefined &&
                serie.casters.length > 1 && (
                  <div className="absolute  w-72 right-12 top-9 flex-col border-white border border-opacity-60 rounded-md">
                    <div className="flex justify-center  bg-elementBackground rounded-t-md">
                      <h2 className="text-sm text-white font-bold uppercase py-1">
                        Identified streams for the match
                      </h2>
                    </div>
                    {serie.casters.map((caster, index) => (
                      <p
                        onClick={() => {
                          setPlayingURL(caster.additional_info.url);
                          setPlaying(true);
                          setPlatform(caster.additional_info.platform.id);
                          setShow(false);
                        }}
                        className={`${
                          index === serie.casters.length - 1 && "rounded-b-md"
                        } ${index === 0 && ""} ${
                          playingURL ===
                          "https://www.twitch.tv/" +
                            caster.additional_info.username
                            ? " bg-serieRight p-1"
                            : "hover:text-red-600 bg-serieLeft2  hover:bg-hoverBackground hover:cursor-pointer p-1  "
                        }  }`}
                      >
                        {`${caster.additional_info.display_name}`}
                      </p>
                    ))}
                  </div>
                )}
            </div>

            {platform === 1 && (
              <ReactPlayer
                className="absolute top-0 left-0 rounded-md z-10 "
                width="100%"
                height="100%"
                playing={true}
                url={playingURL}
                config={{
                  options: {
                    parent: ["oddsarena.gg", "www.oddsarena.gg"],
                  },
                  twitch: {
                    options: {
                      parent: ["oddsarena.gg", "www.oddsarena.gg"],
                    },
                  },
                }}
              />
            )}
            {/*             {arena &&
              casters !== undefined &&
              casters !== null &&
              casters.length > 1 && (
                <div className="absolute right-12 top-1/3  bg-elementBackground  z-50">
                  MORE CASTERS
                </div>
              )} */}
            {platform === 8 && (
              <ReactPlayer
                className="absolute top-0 left-0 rounded-md "
                width="100%"
                height="100%"
                playing={true}
                controls
                url={playingURL}
                config={{
                  youtube: {
                    playerVars: { showinfo: 1 },
                  },
                }}
              />
            )}
          </div>

          {arena === false &&
            casters !== undefined &&
            casters !== null &&
            casters.length > 1 && (
              <div
                className={`flex flex-col ${
                  style !== false ? style : "bg-elementBackground"
                } ${
                  mobile
                    ? "mt-1 py-1 px-1 rounded-tr-xs"
                    : "mt-2 py-1 px-2 rounded-tr-md"
                } `}
              >
                <div
                  className=" text-xs md:text-sm text-white text-opacity-80 flex justify-between cursor-pointer hover:text-red-600 "
                  onClick={() => handleExpand()}
                >
                  <div>
                    <span className="underline">
                      Additional streams for the game
                    </span>
                  </div>
                  <div>
                    {expanded ? (
                      <ChevronUpIcon className="w-4 h-4" />
                    ) : (
                      <ChevronDownIcon className="w-4 h-4" />
                    )}
                  </div>
                </div>
                {expanded ? (
                  <div className="flex mt-2 pb-1 flex-wrap gap-2">
                    {casters.map((caster) => (
                      <div
                        className={`px-2 py-1 text-white text-opacity-80 text-xs md:text-sm   rounded-md mr-1 ${
                          playingURL ===
                          "https://www.twitch.tv/" +
                            caster.additional_info.username
                            ? " bg-serieRight"
                            : "hover:text-red-600 bg-serieLeft2  "
                        }`}
                      >
                        <button
                          onClick={() => {
                            setPlayingURL(caster.additional_info.url);
                            setPlaying(true);
                            setPlatform(caster.additional_info.platform.id);
                          }}
                          disabled={
                            playingURL ===
                            "https://www.twitch.tv/" +
                              caster.additional_info.username
                          }
                        >
                          {caster.additional_info.display_name}
                        </button>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
        </div>
      ) : null}
    </>
  );
}
