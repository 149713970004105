import React from "react";
import vsIcon from "../../assets/icon-vs.png";

export default function VsHeader({ matchData }) {
  //console.log(matchData.participants[0].score);

  //console.log(matchData);
  let lifecycles = ["upcoming"];
  let overCycles = ["over", "over-draw", "over-forfeited"];
  function score(participant) {
    if (
      matchData.lifecycle === "upcoming" ||
      matchData.lifecycle === "deleted"
    ) {
      return "";
    } else if (matchData.lifecycle === "live") {
      return participant.score;
    } else {
      return participant.score;
    }
  }

  function gameStart(start) {
    let date = new Date(start);
    let locale = false;
    if (navigator.language !== undefined) {
      locale = navigator.language;
    }

    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    if (locale !== false) {
      return new Intl.DateTimeFormat(locale, options).format(date);
    }
  }
  //console.log(matchData.lifecycle);
  return (
    <div className="flex-col text-xl">
      <div className="flex justify-center">
        <div className="mr-3">
          {matchData.participants.length > 0 &&
            matchData.participants[0] !== undefined &&
            matchData.participants[0].score !== undefined &&
            score(matchData.participants[0])}
        </div>
        <div className="flex ">
          <img className="w-8 mt-1" src={vsIcon} />
        </div>
        <div className="ml-4">
          {matchData.participants.length > 0 &&
            matchData.participants[1] !== undefined &&
            matchData.participants[1].score !== undefined &&
            score(matchData.participants[1])}
        </div>
      </div>
      {lifecycles.includes(matchData.lifecycle) && (
        <div className="flex-col  justify-center">
          <div className="text-center mt-1 text-xs text-white text-opacity-50 w-auto">
            <span>Best of {matchData.best_of}</span>
          </div>
          <div className="hidden md:flex text-xs text-white text-opacity-80">
            {gameStart(matchData.start)}
          </div>
        </div>
      )}
      {overCycles.includes(matchData.lifecycle) && (
        <div className="flex-col justify-center">
          <div className="text-center mt-1 text-xs text-white text-opacity-50 w-auto">
            <span>Best of {matchData.best_of}</span>
          </div>
          <div className="flex text-lg text-center justify-center text-white text-opacity-80">
            <span>
              {matchData.lifecycle === "over-forfeited"
                ? "Forfeited"
                : "Completed"}
            </span>
          </div>
        </div>
      )}
      {matchData.lifecycle == "live" && (
        <div className="flex-col justify-center">
          <div className="text-center mt-1 text-xs text-white text-opacity-50 w-auto">
            <span>Best of {matchData.best_of}</span>
          </div>
          <div className="flex text-md lg:text-lg text-center justify-center text-red-500 animate-pulse text-opacity-80">
            <span>LIVE</span>
          </div>
        </div>
      )}
    </div>
  );
}
