import React from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export default function CookieModal({ cookieConsent }) {
  return (
    <div className="w-screen h-20  bg-headerBackground flex items-center  justify-between pl-2 md:pl-5">
      <div className="flex items-center">
        <div>
          <InformationCircleIcon className="w-10 h-10 text-white" />
        </div>
        <div className=" font-base text-xs md:text-sm text-gray-900">
          <p className="text-white pl-1 md:pl-3 pr-1 md:pr-3">
            Cookies are used on this webpage to help us improve our services. By
            using OddsArena you agree to it's usage. Read more{" "}
            <Link to="/integrity"> here </Link>
          </p>
        </div>
      </div>
      <div className="align-middle pr-2 md:pr-7 pl-2 md:pl-5">
        <button
          onClick={() => cookieConsent()}
          className="md:font-bold text-white py-1 px-1 md:py-2 md:px-3 rounded bg-blue-500 hover:bg-hoverBackground hover:text-red-600"
        >
          Understood
        </button>
      </div>
    </div>
  );
}
