import React from "react";
import LeftSerieSection from "./Serie/LeftSerieSection";
import RightSerieSection from "./Serie/RightSerieSection";
import LiveRightSerieSection from "./Serie/LiveRightSerieSection";
import TopSerieSectionMobile from "./Serie/TopSerieSectionMobile";
import MiddleSerieSectionMobile from "./Serie/MiddleSerieSectionMobile";
import MobileOdds from "./Serie/MobileOdds";
import MatchButton from "./Serie/MatchButton";
import LiveScoreMobile from "./Serie/LiveScoreMobile";
import { Link } from "react-router-dom";

function Serie({
  data,
  bookies,
  mobile,
  index,
  length,
  completedSeries = false,
  page,
  games,
}) {
  const oddsPresent = () => {
    if (data.odds !== undefined && data.odds.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //console.log(data);

  const link = () => {
    let urlString = "";

    if (data.game[0].abbreviation !== undefined) {
      let game = data.game[0].abbreviation;
      game = game.replace(/[:\s\-]/g, "").toLowerCase();

      urlString += "/match/" + game + "/";
    }

    if (data.participants[0] !== undefined) {
      urlString += data.participants[0].teamData.name
        .replace(/ /g, "-")
        .toLowerCase();
    }
    if (data.participants[1] !== undefined) {
      urlString +=
        "-vs-" +
        data.participants[1].teamData.name.replace(/ /g, "-").toLowerCase();
    }

    if (data.title !== undefined) {
      let tempString = data.title;
      tempString = tempString.replace(/ - /g, "-");
      urlString += "-" + tempString.replace(/ /g, "-").toLowerCase();
    }

    if (
      data.tournament !== undefined &&
      data.tournament.length > 0 &&
      data.tournament[0].title !== undefined
    ) {
      urlString +=
        "-" +
        data.tournament[0].title
          .replace(/ /g, "-")
          .replace(/#/g, "")
          .toLowerCase();
    }

    if (data.id !== undefined) {
      urlString += "-" + data.id;
    }

    urlString = urlString.replace("#", "");

    return urlString;
  };

  /*   if (data !== undefined) {
    if (data.casters.length === 0) {
      console.log(data.id);
    }
  } */

  const deskTop = (
    <div
      className={`flex h-16 transition   ease-in-out duration-300 mt-[6px] border-white border rounded-md border-opacity-60 hover:border-red-500 hover:border-2 `}
    >
      <LeftSerieSection
        lifecycle={data.lifecycle}
        start={data.start}
        data={data}
        index={index}
        length={length}
        completedSeries={completedSeries}
      />
      {data.lifecycle === "live" ? (
        <LiveRightSerieSection
          data={data}
          bookies={bookies}
          index={index}
          length={length}
          completedSeries={completedSeries}
          games={games}
        />
      ) : (
        <RightSerieSection
          data={data}
          bookies={bookies}
          index={index}
          length={length}
          completedSeries={completedSeries}
          games={games}
        />
      )}

      <MatchButton link={link()} index={index} page={page} />
    </div>
  );

  const mobileView = (
    <div className="block border-white border-y  border-opacity-20 mb-2 ">
      <div className="flex flex-col">
        <TopSerieSectionMobile
          lifecycle={data.lifecycle}
          start={data.start}
          data={data}
          completedSeries={completedSeries}
          mobile={mobile}
        />
        <MiddleSerieSectionMobile
          data={data}
          bookies={bookies}
          completedSeries={completedSeries}
          mobile={mobile}
          games={games}
        />
        {data.lifecycle === "live" && <LiveScoreMobile data={data} />}

        {oddsPresent() === true ? (
          <MobileOdds odds={data.odds} bookies={bookies} />
        ) : null}
      </div>
    </div>
  );

  return <>{mobile === true ? mobileView : deskTop}</>;
}

export default Serie;
