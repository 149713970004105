import React from "react";
import CSDetail from "./Details/CSDetail";
import MatchSummary from "./MatchSummary";
import LiveSummary from "./LiveSummary";

export default function MatchDetail({
  serieData,
  matchData,
  selectedIndex,
  handleRefetch,
  mobile,
  winreasons,
}) {
  // console.log(selectedIndex, matchData);
  let overCycles = ["over", "over-draw", "over-forfeited"];
  //console.log(winreasons);
  return (
    <div className="">
      <div className="mt-1 rounded-tr-md   w-full flex-col">
        {overCycles.includes(matchData.lifecycle) && (
          <MatchSummary
            serieData={serieData}
            matchData={matchData}
            mobile={mobile}
            winreasons={winreasons !== null ? winreasons : null}
          />
        )}
        {matchData.lifecycle === "live" && (
          <LiveSummary
            serieData={serieData}
            id={matchData.id}
            matchData={matchData}
            handleRefetch={handleRefetch}
            mobile={mobile}
            winreasons={winreasons !== null ? winreasons : null}
          />
        )}
        {/*         {overCycles.includes(matchData.lifecycle) && matchData.game.id === 5 && (
          <div>
            <CSDetail serieData={serieData} matchData={matchData} />
          </div>
        )} */}
      </div>
    </div>
  );
}
