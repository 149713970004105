import React, { useState, useEffect } from "react";
import ParticipantLogo from "../../../components/utils/ParticipantLogo";
import CSHalfView from "./CSHalfView";

export default function CSPastRounds({
  detailed_match,
  serieData,
  mobile,
  winreasons,
  game,
}) {
  const [halfs, setHalfs] = useState(null);

  const factions = [
    { id: 5440, name: "Terrorists" },
    { id: 5441, name: "Counter-Terrorists" },
  ];

  useEffect(() => {
    //// console.log(detailed_match.past_rounds.length);
    //lets figure out the logic here...
    if (detailed_match.past_rounds.length < 13) {
      setHalfs([[...detailed_match.past_rounds]]);
    }
    if (
      detailed_match.past_rounds.length > 12 &&
      detailed_match.past_rounds.length < 25
    ) {
      let cloneArray = [...detailed_match.past_rounds];

      let firstHalf = cloneArray.splice(0, 12);

      setHalfs([[...firstHalf], [...cloneArray]]);
    } else if (detailed_match.past_rounds.length > 24) {
      let cloneArray = [...detailed_match.past_rounds];

      let firstHalf = cloneArray.splice(0, 12);
      let secondHalf = cloneArray.splice(0, 12);
      setHalfs([[...firstHalf], [...secondHalf], [...cloneArray]]);
    }
  }, [detailed_match.past_rounds]);

  //console.log(detailed_match.past_rounds);
  // console.log(winreasons);
  //console.log(detailed_match);

  //  console.log("HALFS: ", halfs);
  // //lets figure out hometeam first half

  return (
    <div className="flex w-auto   flex-nowrap whitespace-nowrap overflow-x-auto  ">
      <div
        className={`${mobile ? "flex-col grow " : "flex grow "} ${
          halfs !== null && halfs.length > 1 && " justify-around"
        }`}
      >
        {halfs !== null &&
          halfs.map((half, index) => (
            <div className="ml-1" key={index}>
              <CSHalfView
                data={half}
                winreasons={winreasons}
                serieData={serieData}
                mobile={mobile}
                index={index}
                game={game}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
