export default function link(data) {
  //console.log("HERE");
  //console.log(data);
  let urlString = "";

  if (data.game[0].abbreviation !== undefined) {
    let game = data.game[0].abbreviation;
    game = game.replace(/[:\s\-]/g, "").toLowerCase();

    urlString += "/match/" + game + "/";
  }

  if (data.participants[0] !== undefined) {
    urlString += data.participants[0].teamData.name
      .replace(/ /g, "-")
      .toLowerCase();
  }
  if (data.participants[1] !== undefined) {
    urlString +=
      "-vs-" +
      data.participants[1].teamData.name.replace(/ /g, "-").toLowerCase();
  }

  if (data.title !== undefined) {
    let tempString = data.title;
    tempString = tempString.replace(/ - /g, "-");
    urlString += "-" + tempString.replace(/ /g, "-").toLowerCase();
  }

  if (
    data.tournament !== undefined &&
    data.tournament.length > 0 &&
    data.tournament[0].title !== undefined
  ) {
    urlString +=
      "-" +
      data.tournament[0].title
        .replace(/ /g, "-")
        .replace(/#/g, "")
        .toLowerCase();
  }

  if (data.id !== undefined) {
    urlString += "-" + data.id;
  }

  return urlString;
}
