import React, { useState, useEffect } from "react";
import TournamentItem from "./TournamentItem";

export default function TournamentList({ data, mobile }) {
  return (
    <div className="flex flex-col  shadow-lg rounded-t-md">
      {data !== undefined && data.length > 0
        ? data.map((tournament, index) => (
            <div key={tournament.id}>
              <TournamentItem
                tournament={tournament}
                mobile={mobile}
                length={data.length}
                index={index}
              />
            </div>
          ))
        : null}
    </div>
  );
}
