import React from "react";
import Odds from "./Odds";
import { Link } from "react-router-dom";

export default function LeftTeam({
  data,
  odds,
  bookies,
  completedSeries,
  fullData,
  team = false,
  lifecycle,
  games,
  live = false,
}) {
  //console.log(games);
  const link = () => {
    //console.log(data, data.teamData, games);
    if (
      data !== undefined &&
      data.teamData !== undefined &&
      games !== undefined
    ) {
      let game = games.filter((game) => game.id === data.teamData.game.id)[0]
        .abbreviation;
      game = game.replace(/[:\s\-]/g, "").toLowerCase();

      //console.log(game);

      let link = data.teamData.name;
      link = link.toLowerCase();
      link = link.replace(/\s/g, "-");
      link = link.replace(/\//g, "-");
      link += "-" + data.teamData.id;

      let returnLink = game + "/" + link;

      return returnLink;
    }
    return;
  };
  const logo = () => {
    let logoReturn;

    data !== undefined &&
    data.teamData !== undefined &&
    data.teamData.images !== null &&
    data.teamData.images.length > 0 &&
    data.teamData.images[0].url !== ""
      ? (logoReturn = (
          <div className="rounded-full flex h-10 w-10   items-center justify-center">
            <img
              className="h-8 w-8 z-20"
              src={data.teamData.images[0].thumbnail}
              alt={data.teamData.name}
              title={data.teamData.name}
            />
          </div>
        ))
      : (logoReturn = <div className="h-10 w-10  rounded-r-lg" />);

    return logoReturn;
  };

  if (data !== undefined && data.teamData === undefined) {
    console.log(fullData);
  }

  //console.log(data);
  // console.log(lifecycle, fullData.id, data.winner);
  return (
    <div
      className={`flex justify-end h-full bg-serieLeft2 w-1/2 pl-3 ${
        completedSeries !== false && "pr-12"
      }`}
    >
      <div className="flex justify-between align-middle text-white items-center w-full">
        {odds !== undefined ? (
          <Odds odds={odds} bookies={bookies} home={true} />
        ) : (
          <div />
        )}
        {data !== undefined && data.teamData !== undefined && (
          <Link to={`/team/${link()}`}>
            <div
              className={`flex gap-4 ${
                live ? " mr-16" : "mr-8"
              } pl-2 z-30 hover:bg-hoverBackground border-white hover:border-opacity-50 border rounded-lg border-opacity-0`}
            >
              <div className="flex align-middle items-center text-xs font-medium  ">
                <span>{data.teamData.name}</span>
              </div>
              <div className="flex align-middle items-center">{logo()}</div>
            </div>
          </Link>
        )}
        {data === undefined && (
          <div className="flex gap-4 pr-8 pl-2">
            <div className="flex align-middle items-center text-xs font-medium  ">
              <span>TBD</span>
            </div>
            <div className="flex align-middle items-center">{logo()}</div>
          </div>
        )}
      </div>
    </div>
  );
}
