import React from "react";
import { Link } from "react-router-dom";

const thunderLink = (
  <div>
    <a
      href="https://go.thunder.partners/visit/?bta=35423&nci=5395"
      target="_blank"
      className="w-full h-auto"
    >
      <img
        src="https://thunder.ck-cdn.com/tn/serve/?cid=384474"
        className="w-full object-fill    "
      />
    </a>
  </div>
);

const cyberBet = (
  <div className="">
    <a
      href="https://link.affiliates.cyber.bet/click?b=0&pid=6181&offer_id=53&sub4=ODDSARENA&sub8=_esports&sub5=959"
      target="_blank"
      className="w-full h-full"
    >
      <img
        src="https://cdnstatic.cyber.bet/img/app/14aeced7-6457-4d9c-a658-10336bc0fc67.jpeg"
        className="w-full object-fill  "
      />
    </a>
  </div>
);

function bcgame(mobile) {
  let num = Math.random();
  return (
    <div className="">
      <a
        href="https://bc.game/i-oddsarena-n/"
        target="_blank"
        className="w-full h-full"
      >
        <img
          src={`${
            mobile
              ? "https://oa-img-public.s3.eu-north-1.amazonaws.com/oddsarena/banners/bcgame__320x50.gif"
              : "https://oa-img-public.s3.eu-north-1.amazonaws.com/oddsarena/banners/bcgame__970x90.gif"
          }`}
          className={`w-full object-fill  ${
            mobile ? "rounded-sm" : "rounded-md"
          }`}
        />
      </a>
    </div>
  );
}

export default function PageAd({ game, mobile }) {
  return (
    <div className={`w-full h-full mb-3  ${!mobile && "rounded-md"}`}>
      {bcgame(mobile)}
    </div>
  );
}
