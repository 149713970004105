import React from "react";
import Countdown from "react-countdown";
export default function StreamStart({ matchData, mobile }) {
  return (
    <div>
      <div className="mt-5 flex">
        <div className="bg-elementBackground pr-2 border-t border-r border-l border-white border-opacity-20 rounded-tr-md">
          <h2
            className={`${
              mobile ? "text-sm" : "text-lg"
            } first-letter:ml-1 font-bold uppercase text-white text-opacity-80  `}
          >
            {`Stream`}
          </h2>
        </div>
      </div>

      <div className={`rounded-tr-md bg-gray-700 h-24 relative rounded-b-md `}>
        <div className="absolute inset-0 flex items-center justify-center flex-col">
          <div>
            <span className={`text-white ${mobile && "text-xs"}`}>
              Stream will be displayed here when the match starts in:{" "}
            </span>
          </div>
          <Countdown
            className="text-red-500  font-bold ml-2"
            date={matchData.series.start}
          />
          {matchData.series.casters.length === 0 && (
            <div className="mt-1">
              <span className="text-sm text-white text-opacity-60">
                No stream identified for this game yet
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
