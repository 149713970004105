import React from "react";
import BookmakerItem from "./BookmakerItem";

export default function BookmakersList({ bookies }) {
  /*   const [cryptoFilter, setCryptoFilter] = useState(false);
  const [filteredBookies, setFilteredBookies] = useState([]);

  useEffect(() => {
    if (bookies !== undefined && bookies.length > 0) {
      if (cryptoFilter) {
        let returnBookies = bookies.filter((bookie) => bookie.crypto === "Yes");
        setFilteredBookies(returnBookies);
      } else {
        setFilteredBookies(bookies);
      }
    }

    console.log(filteredBookies);
  }, [cryptoFilter]);
 */

  //console.log(bookies);
  return (
    <div className="mt-2">
      {/*       <div className="flex justify-start text-white">
        <div>
          <button
            className={`  pl-3 pr-3  pt-1 pb-1 rounded-l-md shadow-md ${
              cryptoFilter === false
                ? "bg-serieRight2 "
                : "bg-elementBackground hover:text-red-600 "
            }`}
            onClick={() => setCryptoFilter(false)}
          >
            All Bookmakers
          </button>
          <button
            className={`${"border-l-2 border-white border-opacity-20"} shadow-md  pl-2 pb-1 pt-1 pr-3 rounded-r-md  bg-elementBackground  ${
              cryptoFilter === true
                ? "bg-serieRight2 "
                : "bg-elementBackground hover:text-red-600 "
            }`}
            onClick={() => setCryptoFilter(true)}
          >
            Crypto Only
          </button>
        </div>
      </div> */}
      <div className="">
        {bookies.map((bookie, index) => (
          <div
            className="mt-1 bg-elementBackground rounded-md shadow-lg px-2 py-2 mb-2 border-white border border-opacity-60"
            key={index}
          >
            <BookmakerItem bookie={bookie} />
          </div>
        ))}
      </div>
    </div>
  );
}
