import React from "react";
import teamLink from "../../utils/teamLink";
import { Link } from "react-router-dom";

export default function GroupRow({ team, data, game, index, length }) {
  //console.log(team);
  return (
    <Link to={`/team/${teamLink(team.teamData, game)}`}>
      <div
        className={`flex w-full text-sm py-2 text-white  text-opacity-80  border-serieRight2 border hover:border-white hover:rounded-md hover:bg-hoverBackground hover:cursor-pointer  ${
          index % 2 ? "bg-headerBackground" : "bg-serieLeft2"
        } ${index === 0 && "rounded-tl-md"} ${
          index + 1 === length && "rounded-b-md"
        } `}
      >
        <div className="w-2/5 flex">
          <div className="w-5 h-5 ml-1 mr-2">
            {team.teamData.images !== undefined &&
              team.teamData.images.length > 0 &&
              team.teamData.images[0].url !== "" && (
                <img src={team.teamData.images[0].url} />
              )}
          </div>
          <span className="font-bold">
            {team.teamData !== undefined && team.teamData.name}
          </span>
        </div>
        <div className="w-3/5 relative flex">
          <div className="w-1/5 text-center">
            <span className="">{data.wins + data.draws + data.losses}</span>
          </div>
          <div className="w-1/5 text-center  ">{data.wins}</div>
          <div className="w-1/5 text-center">{data.draws}</div>
          <div className="w-1/5 text-center">{data.losses}</div>
          <div className="w-1/5 text-right mr-2 font-bold">{data.points}</div>
        </div>
      </div>
    </Link>
  );
}
