import React from "react";
import { Link } from "react-router-dom";
import teamLink from "../utils/teamLink";
import tournamentLink from "../utils/tournamentLink";

export default function SearchResults({ data, mobile, resetSearchTerm }) {
  //console.log(data);

  const isObjectEmpty = (objectName) => {
    return JSON.stringify(objectName) === "{}";
  };

  function displayTournament(tournamentData) {
    let returnString;

    if (mobile) {
      if (tournamentData.title.length > 55) {
        returnString = tournamentData.short_title;
      } else {
        returnString = tournamentData.title;
      }
    } else {
      if (tournamentData.title.length > 60) {
        returnString = tournamentData.short_title;
      } else {
        returnString = tournamentData.title;
      }
    }

    return returnString;
  }

  const tournamentView = (
    <div className="flex-col  ">
      <div className=" bg-headerBackground px-1  text-white text-opacity-40 h-6 ">
        <span className="text-sm ml-1">Tournaments</span>
      </div>
      {data.tournamentData !== undefined &&
        data.tournamentData.map((tournament, indx) => (
          <Link
            to={`/tournament/${tournamentLink(
              tournament,
              tournament.game[0],
              true
            )}`}
          >
            <div
              className={`p-1  h-8 w-auto flex  ${
                indx !== data.tournamentData.length - 1
                  ? "border-b  border-white border-opacity-30 "
                  : "rounded-b-md"
              } ${
                indx % 2 === 0 ? " bg-serieLeft2" : " bg-subHeadBackground"
              } hover:text-red-500 text-white cursor-pointer hover:bg-hoverBackground justify-between text-opacity-70`}
              onClick={resetSearchTerm}
            >
              <div className="flex">
                <div>
                  {tournament !== undefined &&
                  tournament.images !== null &&
                  tournament.images.length > 0 &&
                  tournament.images[0].url !== "" ? (
                    <img className="h-6 w-6 " src={tournament.images[0].url} />
                  ) : (
                    <div className="h-6 w-6" />
                  )}
                </div>
                <div className="ml-2">
                  <span className={` ${mobile ? "text-xs" : "text-sm"} h-4 `}>
                    {displayTournament(tournament)}
                  </span>
                </div>
              </div>
              <div>
                {tournament.game[0].defaultThumbnail !== undefined && (
                  <img
                    className="h-4 w-4 mr-1 mt-1"
                    src={tournament.game[0].defaultThumbnail}
                  />
                )}
              </div>
            </div>
          </Link>
        ))}
    </div>
  );

  const teamView = (
    <div className="flex-col ">
      <div className=" bg-headerBackground px-1  text-white text-opacity-40 h-6   font-normal rounded-t-md">
        <span className="ml-1 text-sm">Teams</span>
      </div>
      {data.teamsData !== undefined &&
        data.teamsData.map((team, indx) => (
          <Link to={`/team/${teamLink(team, team.game[0])}`}>
            <div
              className={`p-1  h-8 w-auto flex  ${
                indx !== data.teamsData.length - 1
                  ? "border-b  border-white  border-opacity-30"
                  : "rounded-b-md"
              } ${
                indx % 2 === 0 ? " bg-serieLeft2" : " bg-subHeadBackground"
              } hover:text-red-500 text-white text-opacity-70 cursor-pointer hover:bg-hoverBackground justify-between`}
              onClick={resetSearchTerm}
            >
              <div className="flex">
                <div>
                  {team !== undefined &&
                  team.images !== null &&
                  team.images.length > 0 &&
                  team.images[0].url !== "" ? (
                    <img
                      className="h-6 w-6 "
                      src={team.images[0].thumbnail}
                      alt={team.name}
                      title={team.name}
                    />
                  ) : (
                    <div className="h-6 w-6" />
                  )}
                </div>
                <div className="ml-2">
                  <span className={`h-4  ${mobile ? "text-xs" : "text-sm"}`}>
                    {team.name}
                  </span>
                </div>
              </div>
              <div>
                {team.game[0].defaultThumbnail !== undefined && (
                  <img
                    className="h-4 w-4 mr-1 mt-1"
                    src={team.game[0].defaultThumbnail}
                  />
                )}
              </div>
            </div>
          </Link>
        ))}
    </div>
  );

  return (
    <div
      className={`flex-col w-full rounded-md ${
        mobile
          ? " bg-headerBackground py-2 rounded-md rounded-b-md"
          : ` border border-white rounded-md}`
      }   h-auto`}
    >
      {data.teamsData !== undefined && teamView}
      {data.tournamentData !== undefined && tournamentView}
      {isObjectEmpty(data) && (
        <div
          className={`bg-headerBackground p-2  text-white text-opacity-80  rounded-md  font-normal `}
        >
          <span>No search results, please try another search term</span>
        </div>
      )}
    </div>
  );
}
