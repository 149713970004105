import React, { useState, useEffect } from "react";
import Serie from "./TeamSerie";

export default function TeamLiveSeries({
  data,
  bookies,
  mobile = false,
  team,
  handleTwitchPlayer,
  games,
}) {
  //console.log(team);
  const sortedSeries = (
    <div>
      <h2 className="text-white text-xs text-opacity-50 font-bold ml-1 underline mt-2">
        Live matches for {team.name !== undefined && team.name}
      </h2>
      <div className="flex flex-col   shadow-lg">
        {data !== undefined && data.length > 0
          ? data.map((serie, index) => (
              <Serie
                index={index}
                key={serie.id}
                data={serie}
                bookies={bookies}
                mobile={mobile}
                length={data.length}
                handleTwitchPlayer={handleTwitchPlayer}
                games={games}
              />
            ))
          : null}
      </div>
    </div>
  );

  return (
    <>
      <div>{sortedSeries}</div>
    </>
  );
}
