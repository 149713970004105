import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Networth({ detailed_match, serieData, game, mobile }) {
  const [graphData, setGraphData] = useState(null);

  const mobileOptions = {
    responsive: true,
    maintainAspectRatio: false,
    redraw: true,
    plugins: {
      legend: {
        position: "top",
        color: "orange",
      },
      title: {
        display: true,

        font: {
          size: 15,
        },

        text: "Networth over time",
        font: {
          size: 13,
        },
        color: "white",
        padding: 2,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
        },
        /*         ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return "$" + value;
          },
        }, */
      },
      y: {},
      y1: {
        display: true,

        position: "right",
      },
    },
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    redraw: false,
    plugins: {
      legend: {
        position: "top",
        color: "orange",
      },
      title: {
        display: true,
        font: {
          size: 15,
        },

        text: "Networth over time",
        font: {
          size: 13,
        },
        color: "white",
      },
    },

    scales: {
      x: {
        title: {
          display: true,
          text: "Minutes",
          font: {
            size: 12,
          },
          color: "white",
        },
        /*         ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return "$" + value;
          },
        }, */
      },
      y: {
        title: {
          display: true,
          text: "Networth",
          font: {
            size: 12,
          },
          color: "white",
        },
      },
      y1: {
        position: "right",
        title: {
          display: true,
          text: "Delta networth",
          font: {
            size: 12,
          },
          color: "white",
        },
      },
    },
  };
  /*
  console.log(detailed_match); /*
  console.log(serieData);
  console.log(game);
  console.log(game.id); */
  useEffect(() => {
    if (game[0].id === 1) {
      if (
        detailed_match !== undefined &&
        detailed_match.dota !== undefined &&
        detailed_match.dota.length > 1 &&
        serieData !== undefined &&
        serieData.length === 2
      ) {
        const labels = detailed_match.dota.map((data) =>
          parseInt(data.update / 60000)
        );
        setGraphData({
          labels,
          datasets: [
            {
              label: serieData.filter((part) => part.seed === 1)[0].teamData
                .name,
              data: detailed_match.dota.map((dp) => dp.home),
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
              label: serieData.filter((part) => part.seed === 2)[0].teamData
                .name,
              data: detailed_match.dota.map((dp) => dp.away),
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
              label: "Networth delta",
              data: detailed_match.dota.map((dp) => dp.home - dp.away),
              borderColor: "green",
              backgroundColor: "green",
              yAxisID: "y1",
            },
          ],
        });
      }
    } else if (game[0].id === 2) {
      if (
        detailed_match.lol !== undefined &&
        detailed_match.lol.length > 1 &&
        serieData !== undefined &&
        serieData.length === 2
      ) {
        console.log("DO WE GET HERE?");
        const labels = detailed_match.lol.map((data) =>
          parseInt(data.update / 60000)
        );
        setGraphData({
          labels,
          datasets: [
            {
              label: serieData.filter((part) => part.seed === 1)[0].teamData
                .name,
              data: detailed_match.lol.map((dp) => dp.home),
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
              yAxisID: "y",
            },
            {
              label: serieData.filter((part) => part.seed === 2)[0].teamData
                .name,
              data: detailed_match.lol.map((dp) => dp.away),
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
              yAxisID: "y",
            },
            {
              label: "Networth delta",
              data: detailed_match.lol.map((dp) => dp.home - dp.away),
              borderColor: "green",
              backgroundColor: "green",
              yAxisID: "y1",
            },
          ],
        });
      }
    }
  }, [detailed_match, game, serieData]);
  // console.log("GRAPH : ", graphData);
  return (
    <div className="w-auto  h-72 flex ">
      {graphData !== null && (
        <Line
          options={mobile ? mobileOptions : options}
          data={graphData}
          redraw={true}
        />
      )}
    </div>
  );
}
