import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Fragment } from "react";

export default function OddsMenu({
  menuOptions,
  sendUserToBookie,
  home,
  mobile,
}) {
  const mobileHomeStyle =
    "origin-top-right absolute mt-2  w-32 shadow-lg rounded-sm  bg-subHeadBackground ring-1  ring-white ring-opacity-100 focus:outline-none z-20";
  const desktopStyle =
    "origin-top-right right-0 absolute mt-2 rounded-sm w-32 shadow-lg  bg-subHeadBackground ring-1  ring-white ring-opacity-100 focus:outline-none z-20";

  const mobileButton =
    "inline-flex justify-center w-full border-white border border-opacity-50 text-white text-opacity-90 hover:bg-hoverBackground hover:text-red-600 bg-elementBackground";

  const desktopButton =
    "inline-flex justify-center w-full border-white border border-opacity-50 text-white text-opacity-90 hover:bg-hoverBackground hover:text-red-600 bg-elementBackground";

  return (
    <div className="flex align-middle items-center">
      <Menu as="div" className="relative inline-block text-left mt-1 pl-1">
        <div>
          <Menu.Button
            className={mobile ? mobileButton : desktopButton}
            aria-label="expand odds button"
          >
            <ChevronDownIcon className=" h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={mobile && home ? mobileHomeStyle : desktopStyle}
          >
            <div className="py-0  rounded-md">
              {menuOptions.map((element, index) => {
                return (
                  <Menu.Button as={React.Fragment} key={index}>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          sendUserToBookie(element.name, index, element.url)
                        }
                        className=" block text-sm text-gray-700 hover:bg-serieRight2 w-full border-t-1  border-serieRight2 "
                      >
                        <div
                          className={`${
                            index !== 0
                              ? "border-white border-t border-opacity-50"
                              : ""
                          }  p-2`}
                        >
                          <div className="flex justify-between align-middle  items-center">
                            <div>
                              <img
                                className="h-4 px-1"
                                src={element.imgUrl}
                                alt={element.name}
                              />
                            </div>

                            <div className="px-2">
                              <span
                                className={`text-md text-white ${
                                  index === 0 ? "font-bold" : "font-normal"
                                }`}
                              >
                                {element.odds}
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    )}
                  </Menu.Button>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
