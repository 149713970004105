import React from "react";

export default function DesktopSpinner({ mobile = false }) {
  const mobileSpinner = (
    <div className={`h-16 flex animate-pulse  rounded-md mt-2 bg-white ml-1`}>
      <div
        className={`flex w-60 pl-3 pr-3 items-center h-full border-r-2 border-gray-200`}
      >
        <div className="flex flex-col w-full">
          <div className="flex justify-between w-full">
            <div className="w-7 h-7 rounded-sm  bg-gray-200 "></div>

            <div className="flex rounded-sm w-32 justify-end bg-gray-200"></div>
          </div>
          <div className=" mt-1.5 w-40 h-4 bg-gray-200 rounded-sm"></div>
        </div>
      </div>
      <div className="flex relative w-full items-center  rounded-r-md">
        <div className={`flex justify-end h-full   w-1/2 pl-3 `}>
          <div className="flex justify-between align-middle items-center w-full">
            <div className="flex align-middlebg-yellow-500"></div>
            <div className="flex gap-4 pr-8 pl-2">
              <div className="flex align-middle items-center ">
                <div className="bg-gray-200  w-36  h-5 rounded-sm"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return mobile ? (
    mobileSpinner
  ) : (
    <div className={`h-16 flex animate-pulse  rounded-md mt-2 bg-white`}>
      <div
        className={`flex w-60 pl-3 pr-3 items-center h-full border-r-2 border-gray-200`}
      >
        <div className="flex flex-col w-full">
          <div className="flex justify-between w-full">
            <div className="w-7 h-7 rounded-sm  bg-gray-200 "></div>

            <div className="flex rounded-sm w-32 justify-end bg-gray-200"></div>
          </div>
          <div className=" mt-1.5 w-40 h-4 bg-gray-200 rounded-sm"></div>
        </div>
      </div>
      <div className="flex relative w-full items-center  rounded-r-md">
        <div className={`flex justify-end h-full   w-1/2 pl-3 `}>
          <div className="flex justify-between align-middle items-center w-full">
            <div className="flex align-middlebg-yellow-500"></div>
            <div className="flex gap-4 pr-8 pl-2">
              <div className="flex align-middle items-center ">
                <div className="bg-gray-200  w-36  h-5 rounded-sm"></div>
              </div>
              <div className="flex align-middle items-center">
                <div className="rounded-full flex h-10 w-10 bg-gray-200" />
              </div>
            </div>
          </div>
        </div>
        <div className={`flex justify-end h-full w-1/2 pr-3 rounded-r-md`}>
          <div className="flex justify-between align-middle  items-center w-full pl-8 pr-2">
            <div className="flex gap-4">
              <div className="flex align-middle items-center">
                <div className="rounded-full flex h-10 w-10 bg-gray-200" />
              </div>
              <div className="flex align-middle items-center">
                <div className="bg-gray-200  w-32 h-5 rounded-sm"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
