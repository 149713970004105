import React, { useEffect } from "react";
import Select from "react-select";
import { clsx } from "clsx";

export default function SubstageDropdown({
  data,
  handleSetSelectedItem,
  selectItem,
}) {
  const controlStyles = {
    base: "border rounded-lg bg-white hover:cursor-pointer",
    focus: "border-red-600 ring-1 ring-red-500",
    nonFocus: "border-red-300 hover:border-red-400",
  };

  useEffect(() => {
    handleSetSelectedItem(data[0]);
  }, []);
  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      //console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#DC2626" : null,
        color: "#333333",
        cursor: "pointer",
      };
    },
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  //console.log("DATA LENGTH, ", data);
  return (
    <div className=" w-60 mt-4 mb-2 hover:cursor-pointer ml-1 ">
      <Select
        theme={theme}
        value={selectItem.label}
        onChange={handleSetSelectedItem}
        options={data}
        defaultValue={data[0]}
        isDisabled={data.length === 1}
        classNames={{
          control: ({ isFocused }) =>
            clsx(
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base
            ),
        }}
        styles={colourStyles}
      />
    </div>
  );
}
