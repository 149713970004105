import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TeamInformation from "../components/Teams/TeamInformation";
import TeamForm from "../components/Teams/TeamForm";
import TeamSeriesUpcoming from "../components/Teams/TeamUpcomingSeries";
import TeamLiveSeries from "../components/Teams/TeamLivesSeries";
import TeamCompletedSeries from "../components/Teams/TeamCompletesSeries";
import Lottie from "lottie-light-react";
import loadingAnimation from "../assets/loading_1.json";

export default function SingleTeamPage({
  mobile = false,
  games,
  locationFilter,
  cryptoOnly,
  refetchData,
}) {
  let params = useParams();
  let navigate = useNavigate();

  const didMount = useRef(false);

  const fetchTeam = async (id) => {
    //console.log("FETCH Team with Roster ID: ", id);

    let res = await fetch(
      `${process.env.REACT_APP_BE_URL}/api/team?teamId=${id}&locationFilter=${locationFilter}&crypto=${cryptoOnly}`,
      {
        credentials: "same-origin",
      }
    );

    res = await res.json();
    //console.log(res);
    return res;
  };

  //console.log(params);

  //console.log("MOBILE: ", mobile);

  const [teamData, setTeamData] = useState(null);
  const [loadedID, setLoadedID] = useState(null);
  const [reloading, setReloading] = useState(false);

  useEffect(() => {
    (async () => {
      //console.log(params);

      let calculateID = params.teamID;
      let IDStart = calculateID.lastIndexOf("-");
      let subString = calculateID.substring(IDStart + 1);
      //console.log(subString);

      if (loadedID !== null && loadedID !== calculateID) {
        setReloading(true);
      }

      let teamID = parseInt(subString);

      let res = await fetchTeam(teamID);
      //console.log(res);
      //console.log(teamID);

      if (res.success) {
        setTeamData(res.data);
        setReloading(false);
        if (loadedID === null) {
          setLoadedID(calculateID);
        }
      } else {
        navigate("/");
      }
    })();
    window.scrollTo(0, 0);

    //setTournamentData(res[0]);
  }, [params.teamId, navigate]);

  useEffect(() => {
    if (didMount.current) {
      (async () => {
        //console.log(params);

        let calculateID = params.teamID;
        let IDStart = calculateID.lastIndexOf("-");
        let subString = calculateID.substring(IDStart + 1);
        //console.log(subString);

        setReloading(true);

        let teamID = parseInt(subString);

        let res = await fetchTeam(teamID);
        //console.log(res);
        //console.log(teamID);

        if (res.success) {
          setTeamData(res.data);
          setReloading(false);
          if (loadedID === null) {
            setLoadedID(calculateID);
          }
        } else {
          navigate("/");
        }
      })();
      window.scrollTo(0, 0);
    } else {
      didMount.current = true;
    }

    //setTournamentData(res[0]);
  }, [refetchData]);

  //console.log("UPCOMING: ", upcomingSeriesData);
  // console.log(teamData);

  return (
    <>
      <Helmet>
        <title>
          {teamData !== null
            ? `${teamData.team[0].name} - ${teamData.team[0].game[0].abbreviation} esports team | OddsArena.gg`
            : "OddsArena | Team page"}
        </title>
        {teamData !== null && (
          <meta
            name="description"
            content={`OddsArena | ${teamData.team[0].game[0].abbreviation} - ${teamData.team[0].name} | current player line-up, live / upcoming matches, results, live streams and the best odds around`}
          />
        )}
      </Helmet>
      <div className="mt-6">
        <div>
          {teamData !== null && reloading !== true ? (
            <>
              <TeamInformation
                teamData={teamData}
                mobile={mobile}
                games={games}
              />{" "}
              <TeamForm teamData={teamData} mobile={mobile} />
              {teamData.liveSeries.length > 0 && (
                <TeamLiveSeries
                  data={teamData.liveSeries}
                  mobile={mobile}
                  bookies={teamData.bookies}
                  team={teamData.team[0]}
                  games={games}
                />
              )}
              {teamData.upcomingSeries.length > 0 ? (
                <TeamSeriesUpcoming
                  data={teamData.upcomingSeries}
                  mobile={mobile}
                  bookies={teamData.bookies}
                  team={teamData.team[0]}
                  games={games}
                />
              ) : (
                <div>
                  <h2 className="text-white text-xs text-opacity-50 font-bold ml-1 underline mt-4">
                    No upcoming matches for{" "}
                    {teamData.team[0].name !== undefined &&
                      teamData.team[0].name}
                    {" found."}
                  </h2>
                </div>
              )}
              {/*               {teamData.pastSeries.length > 0 && (
                <TeamCompletedSeries
                  data={teamData.pastSeries}
                  mobile={mobile}
                  team={teamData.team[0]}
                  games={games}
                />
              )} */}
            </>
          ) : (
            <Lottie animationData={loadingAnimation} style={{ height: 200 }} />
          )}
        </div>
      </div>
    </>
  );
}
