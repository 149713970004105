import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export default function Integrity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>OddsArena | Integrity</title>
        <meta
          name="description"
          content="OddsArena | esports, streams, odds and integrity"
        ></meta>
      </Helmet>
      <div className="mx-auto text-white  mt-4 prose-headings:text-white  prose  prose-h3:text-opacity-50 prose-h4:text-opacity-50 text-opacity-60  prose-h2:text-opacity-60 bg-elementBackground rounded-md shadow-lg px-2 md:px-5 py-3 max-w-none ">
        <h1>Privacy & Cookie Policy</h1>
        <figcaption>Effective date: January 27th, 2022</figcaption>
        <p>
          This page informs you of our policies regarding the collection, use
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data.
        </p>
        <p>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy.
        </p>
        <h2>Definitions</h2>
        <ul>
          <span className="font-bold">Service</span>
          <br />
          <span>
            Service is the website accessed at https://www.oddsarena.gg
          </span>
        </ul>
        <ul>
          <span className="font-bold">Personal Data</span>
          <br />
          <span>
            Personal Data means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession).
          </span>
        </ul>
        <ul>
          <span className="font-bold">Usage Data</span>
          <br />
          <span>
            Usage Data is data collected automatically either generated by the
            use of the Service or from the Service infrastructure itself (for
            example, the duration of a page visit).
          </span>
        </ul>
        <ul>
          <span className="font-bold">Cookies</span>
          <br />
          <span>
            Cookies are small files stored on your device (computer or mobile
            device).
          </span>
        </ul>
        <ul>
          <span className="font-bold">Data Controller</span>
          <br />
          <span>
            Data Controller means the natural or legal person who (either alone
            or jointly or in common with other persons) determines the purposes
            for which and the manner in which any personal information are, or
            are to be, processed. For the purpose of this Privacy Policy, we are
            a Data Controller of your Personal Data.
          </span>
        </ul>
        <ul>
          <span className="font-bold">
            Data Processors (or Service Providers)
          </span>
          <br />
          <span>
            Data Processor (or Service Provider) means any natural or legal
            person who processes the data on behalf of the Data Controller. We
            may use the services of various Service Providers in order to
            process your data more effectively.
          </span>
        </ul>
        <ul>
          <span className="font-bold">Data Subject (or User)</span>
          <br />
          <span>
            Data Subject is any living individual who is using our Service and
            is the subject of Personal Data.
          </span>
        </ul>
        <h2>Information Collection and Use</h2>
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
        <h3>Types of Data Collected</h3>
        <h4> Personal Data</h4>
        <p>
          {" "}
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </p>{" "}
        <li>Email address</li>
        <li> First name and last name</li> <li>Phone number</li>
        <li> Address, State, Province, ZIP/Postal code, City</li>
        <li> Cookies and Usage Data</li>{" "}
        <p>
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you. You may opt out of receiving any, or all, of these
          communications from us by following the unsubscribe link or the
          instructions provided in any email we send.
        </p>{" "}
        <h4>Usage Data</h4>
        <p>
          {" "}
          We may also collect information on how the Service is accessed and
          used ("Usage Data"). This Usage Data may include information such as
          your computer's Internet Protocol address (e.g. IP address), browser
          type, browser version, the pages of our Service that you visit, the
          time and date of your visit, the time spent on those pages, unique
          device identifiers and other diagnostic data.
        </p>{" "}
        <h4>Location Data</h4>{" "}
        <p>
          We may use and store information about your location if you give us
          permission to do so ("Location Data"). We use this data to provide
          features of our Service, to improve and customize our Service.
        </p>
        <p>
          You can enable or disable location services when you use our Service
          at any time by way of your device settings.
        </p>{" "}
        <h4>Tracking Cookies</h4>{" "}
        <p>
          Data We use cookies and similar tracking technologies to track the
          activity on our Service and we hold certain information.
        </p>{" "}
        <p>
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are
          also used such as beacons, tags and scripts to collect and track
          information and to improve and analyze our Service.
        </p>{" "}
        <p>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.{" "}
        </p>
        <p>Examples of Cookies we use:</p>{" "}
        <p>
          <span className="font-bold">Session Cookies.</span> We use Session
          Cookies to operate our Service.{" "}
        </p>
        <p>
          <span className="font-bold">Preference Cookies.</span> We use
          Preference Cookies to remember your preferences and various settings.{" "}
        </p>
        <p>
          <span className="font-bold">Security Cookies.</span> We use Security
          Cookies for security purposes.
        </p>
        <p>
          <span className="font-bold">Advertising Cookies.</span> Advertising
          Cookies are used to serve you with advertisements that may be relevant
          to you and your interests.
        </p>
        <h2>Use of Data</h2>
        <p>We use the collected data for various purposes: </p>
        <li>To provide and maintain our Service</li>
        <li>To notify you about changes to our Service</li>
        <li>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </li>
        <li>To provide customer support</li>
        <li>
          To gather analysis or valuable information so that we can improve our
          Service
        </li>{" "}
        <li>
          To monitor the usage of our Service To detect, prevent and address
          technical issues
        </li>
        <li>
          To provide you with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless you
          have opted not to receive such information
        </li>
        <h2>
          Legal Basis for Processing Personal Data under the General Data
          Protection Regulation (GDPR)
        </h2>
        <p>
          If you are from the European Economic Area (EEA), Our legal basis for
          collecting and using the personal information described in this
          Privacy Policy depends on the Personal Data we collect and the
          specific context in which we collect it.
        </p>{" "}
        <p>We may process your Personal Data because: </p>
        <li>We need to perform a contract with you</li>{" "}
        <li>You have given us permission to do so</li>{" "}
        <li>
          The processing is in our legitimate interests and it is not overridden
          by your rights
        </li>{" "}
        <li> For payment processing purposes</li>
        <li> To comply with the law</li> <h2>Retention of Data</h2>{" "}
        <p>
          We will retain your Personal Data only for as long as is necessary for
          the purposes set out in this Privacy Policy. We will retain and use
          your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to
          comply with applicable laws), resolve disputes and enforce our legal
          agreements and policies.
        </p>
        <p>
          We also retain Usage Data for internal analysis purposes. Usage Data
          is generally retained for a shorter period of time, except when this
          data is used to strengthen the security or to improve the
          functionality of our Service, or we are legally obligated to retain
          this data for longer periods.
        </p>
        <h2>Transfer of Data</h2>{" "}
        <p>
          Your information, including Personal Data, may be transferred to - and
          maintained on - computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction. If you are located
          outside United States and choose to provide information to us, please
          note that we transfer the data, including Personal Data, to United
          States and process it there.
        </p>
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <p>
          We will take all the steps reasonably necessary to ensure that your
          data is treated securely and in accordance with this Privacy Policy
          and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
        <h2>Disclosure of Data</h2>
        <h3> Legal Requirements</h3>{" "}
        <p>
          We may disclose your Personal Data in the good faith belief that such
          action is necessary to:
        </p>
        <li>To comply with a legal obligation</li>
        <li> To protect and defend the rights or property of the Service</li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </li>{" "}
        <li>
          {" "}
          To protect the personal safety of users of the Service or the public
        </li>
        <li> To protect against legal liability</li> <h2>Security of Data</h2>
        <p>
          {" "}
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
        <h2>
          Your Data Protection Rights under the General Data Protection
          Regulation (GDPR)
        </h2>
        <p>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. We aim to take reasonable steps to
          allow you to correct, amend, delete or limit the use of your Personal
          Data.
        </p>
        <p>
          If you wish to be informed about what Personal Data we hold about you
          and if you want it to be removed from our systems, please contact us.
        </p>
        <p>
          In certain circumstances, you have the following data protection
          rights:
        </p>
        <ul>
          <p>
            <span className="font-bold">
              The right to access, update or delete the information we have on
              you.
            </span>
            Whenever made possible, you can access, update or request deletion
            of your Personal Data directly within your account settings section.
            If you are unable to perform these actions yourself, please contact
            us to assist you.
          </p>
        </ul>
        <ul>
          <p>
            <span className="font-bold">The right of rectification.</span>
            You have the right to have your information rectified if that
            information is inaccurate or incomplete.
          </p>
        </ul>
        <ul>
          <p>
            <span className="font-bold">The right to object.</span>
            You have the right to object to our processing of your Personal
            Data.
          </p>
        </ul>
        <ul>
          <p>
            <span className="font-bold">The right of restriction.</span>
            You have the right to request that we restrict the processing of
            your personal information.
          </p>
        </ul>
        <ul>
          <p>
            <span className="font-bold">The right to data portability.</span>
            You have the right to be provided with a copy of the information we
            have on you in a structured, machine-readable and commonly used
            format.
          </p>
        </ul>
        <ul>
          <p>
            <span className="font-bold">The right to withdraw consent. </span>
            You also have the right to withdraw your consent at any time where
            we relied on your consent to process your personal information.
          </p>
        </ul>
        <p>
          Please note that we may ask you to verify your identity before
          responding to such requests.
        </p>
        <p>
          You have the right to complain to a Data Protection Authority about
          our collection and use of your Personal Data. For more information,
          please contact your local data protection authority in the European
          Economic Area (EEA).
        </p>
        <h2>Service Providers </h2>
        <p>
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), provide the Service on our behalf,
          perform Service-related services or assist us in analyzing how our
          Service is used.{" "}
        </p>{" "}
        <p>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>
        <h3>Analytics</h3>
        <p>
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
        </p>
        <h4>Google Analytics</h4>{" "}
        <p>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualise and personalise the ads of its own advertising network.
        </p>
        <p>
          You can opt-out of having made your activity on the Service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js and dc.js) from sharing information with Google Analytics
          about visits activity. For more information on the privacy practices
          of Google, please visit the Google Privacy Terms web page:
          https://policies.google.com/privacy?hl=en{" "}
        </p>{" "}
        <h3>Advertising</h3>
        <p>
          {" "}
          We may use third-party Service Providers to show advertisements to you
          to help support and maintain our Service.
        </p>
        <li> We might show local Advertisements.</li>
        <h3>Behavioral Remarketing</h3>
        <p>
          We may use remarketing services to advertise on third party websites
          to you after you visited our Service. We and our third-party vendors
          use cookies to inform, optimize and serve ads based on your past
          visits to our Service.
        </p>
        <h4> Google Ads (AdWords)</h4>
        <p>
          {" "}
          Google Ads (AdWords) remarketing service is provided by Google Inc.
          You can opt-out of Google Analytics for Display Advertising and
          customise the Google Display Network ads by visiting the Google Ads
          Settings page: http://www.google.com/settings/ads Google also
          recommends installing the Google Analytics Opt-out Browser Add-on -
          https://tools.google.com/dlpage/gaoptout - for your web browser.
          Google Analytics Opt-out Browser Add-on provides visitors with the
          ability to prevent their data from being collected and used by Google
          Analytics. For more information on the privacy practices of Google,
          please visit the Google Privacy Terms web page:
          https://policies.google.com/privacy?hl=en
        </p>
        <h4>Facebook</h4>
        <p>
          {" "}
          Facebook remarketing service is provided by Facebook Inc. You can
          learn more about interest-based advertising from Facebook by visiting
          this page: https://www.facebook.com/help/164968693837950 To opt-out
          from Facebook's interest-based ads, follow these instructions from
          Facebook: https://www.facebook.com/help/568137493302217 Facebook
          adheres to the Self-Regulatory Principles for Online Behavioural
          Advertising established by the Digital Advertising Alliance. You can
          also opt-out from Facebook and other participating companies through
          the Digital Advertising Alliance in the USA
          http://www.aboutads.info/choices/, the Digital Advertising Alliance of
          Canada in Canada http://youradchoices.ca/ or the European Interactive
          Digital Advertising Alliance in Europe
          http://www.youronlinechoices.eu/, or opt-out using your mobile device
          settings. For more information on the privacy practices of Facebook,
          please visit Facebook's Data Policy:
          https://www.facebook.com/privacy/explanation{" "}
        </p>{" "}
        <h2>Links to Other Sites</h2>
        <p>
          {" "}
          Our Service may contain links to other sites that are not operated by
          us. If you click a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>{" "}
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.{" "}
        </p>
        <h2>Children's Privacy</h2>{" "}
        <p>
          Our Service does not address anyone under the age of 18 ("Children").
        </p>
        <p>
          {" "}
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Child has provided us with Personal Data, please
          contact us. If we become aware that we have collected Personal Data
          from children without verification of parental consent, we take steps
          to remove that information from our servers.
        </p>
        <h2>Changes to This Privacy Policy</h2>{" "}
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>{" "}
        <p>
          {" "}
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </p>{" "}
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h2> Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <p> By email: privacy@oddsarena.gg</p>
      </div>
    </>
  );
}
