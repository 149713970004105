import React, { useState, useEffect } from "react";
import Serie from "./SerieItem";
import PageAd from "../PageAd";

export default function SerieList({
  game,
  data,
  games,
  bookies,
  mobile = false,
  tournament = false,
  completedSeries = false,
  selectedTab,
  handleSelectedTab,
  live = false,
  results = false,
  series,
}) {
  const [filterDisplay, setFilterDisplay] = useState("all games");
  const [displayLive, setDisplayLive] = useState(true);
  //console.log(data, filter);

  useEffect(() => {
    //console.log("GAME:", game);

    if (game === undefined) {
      setFilterDisplay("all games");
    } else {
      //console.log(games);
      let gameName = games.filter((sg) => sg.gameMenu === game);
      //console.log(gameName);
      setFilterDisplay(gameName[0].abbreviation);
    }

    //console.log(filter);
  }, [game, games]);

  //console.log(displayLive);
  useEffect(() => {
    //console.log(tournament);
    if (tournament !== null && tournament !== false) {
      setFilterDisplay(tournament.game[0].abbreviation);
    }
  }, [tournament]);

  //console.log(completedSeries.length);

  const sortedSeries = (
    <div className="flex flex-col   shadow-lg mt-2">
      {data !== undefined && data.pages !== undefined && data.pages.length > 0
        ? data.pages.map((group, i) => (
            <div key={i}>
              <React.Fragment>
                {group.data.map((serie, index) => (
                  <Serie
                    page={i}
                    index={index}
                    key={serie.id}
                    data={serie}
                    bookies={bookies}
                    mobile={mobile}
                    length={group.data.length}
                    games={games}
                    completedSeries={completedSeries}
                  />
                ))}
              </React.Fragment>
              {completedSeries && (
                <div className="flex w-full mt-5 ">
                  <PageAd
                    mobile={mobile}
                    game={
                      game === undefined || game === "csgo"
                        ? "cyberbet"
                        : "thunder"
                    }
                  />
                </div>
              )}
            </div>
          ))
        : null}
    </div>
  );

  return (
    <>
      <div>{sortedSeries}</div>
    </>
  );
}
