import React, { useState, useEffect } from "react";
import { LightBulbIcon } from "@heroicons/react/solid";
import { Routes, Route, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

import Navigation from "../layout/Navigation/Navigation";

import SuggestionModal from "../components/SuggestionModal/SuggestionModal";
import CookieModal from "../components/CookieModal/CookieModal";

import SingleTournamentPage from "../pages/SingleTournamentPage";
import Footer from "./Footer/Footer";
import Integrity from "../pages/Integrity";
import WhichSideBar from "./WhichSideBar";
import ScrollToTop from "../components/utils/ScrollToTop";
import Advertise from "../pages/Advertise";
import ResponsibleGambling from "../pages/ResponsibleGambling";
import OurService from "../pages/OurService";
import InfinitySeries from "../pages/InfinitySeries";
import SingleMatch from "../pages/SingleMatch/SingleMatch";
import SingleTeamPage from "../pages/SingleTeamPage";
import NewTournamentPage from "../pages/NewTournamentPage";
//import OurService from "../pages/ServicePage";
import Bookmakers from "../pages/Bookmakers";
import Results from "../pages/Results/Results";
import { Helmet } from "react-helmet-async";
import { useInView } from "react-intersection-observer";
import { useQuery } from "react-query";
import SearchResults from "../components/SearchBar/SearchResults";
import TournamentPage from "../pages/TournamentPage";
import Arena from "../components/TheArena/Arena";
import BGImage from "../assets/16-min.png";

const fetchGB = async () => {
  let res = await fetch(`${process.env.REACT_APP_BE_URL}/api/standard/gb`, {
    credentials: "same-origin",
  });

  res = await res.json();
  res = res.data;
  //console.log(res);
  return res;
};

const getSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

async function updateExistingCookie() {
  await axios(`${process.env.REACT_APP_BE_URL}/api/cookies/updateExisting`, {
    withCredentials: true,
  });
  //console.log(response);
}

export default function Layout() {
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [cookies, setCookies] = useCookies(["OddsArena"]);
  const [games, setGames] = useState();
  const [page, setPage] = useState("matches");
  const [filteredGame, setFilterGame] = useState(null);
  const [windowSize, setWindowSize] = useState(getSize());
  const [selectedTab, setSelectedTab] = useState(0);
  const [bookies, setBookies] = useState([]);
  const [subMenu, SetSubMenu] = useState(false);
  const [refetchData, SetRefetchData] = useState(false);
  const [locationFilter, SetLocationFilter] = useState();
  const [cryptoOnly, SetCryptoOnly] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);

  const handleSelectedTab = (id) => {
    setSelectedTab(id);
  };

  function useDebounce(watch, func, delay) {
    useEffect(() => {
      const handler = setTimeout(() => {
        func();
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [watch]);
  }

  const handleSetPage = (pageParam) => {
    //console.log("FIRING SETPAGE", pageParam);
    setPage(pageParam);
    //console.log(pageParam);
  };

  //console.log(window.location);

  const fetchSearchResults = async () => {
    let data;
    //console.log(filter);
    if (searchTerm.length > 2) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ searchTerm: searchTerm }),
        credentials: "same-origin",
      };

      data = await fetch(
        `${process.env.REACT_APP_BE_URL}/api/search/`,
        requestOptions
      );
    }

    if (data.status !== 204) {
      data = await data.json();
      setShowSearchResults(true);
      //console.log(data);
    }

    return data;
  };

  const { data, status, refetch, isLoading } = useQuery(
    ["searchResults", searchTerm],
    fetchSearchResults,
    { enabled: false }
  );

  const val = useDebounce(
    searchTerm,
    () => {
      if (searchTerm.length > 2) {
        refetch();
      }
    },
    500
  );

  function handleSearchTermChange(e) {
    //console.log(e.target.value);
    setSearchTerm(e.target.value);
  }

  function resetSearchTerm() {
    //console.log(e.target.value);

    setSearchTerm("");
    setShowSearchResults(false);
  }

  useEffect(() => {
    const handleResize = () => setWindowSize(getSize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    (async () => {
      let res = await fetchGB();

      setGames(res.games);
      setBookies(res.bookies);
      //console.log(res.bookies);
    })();
  }, []);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  useEffect(() => {
    if (inView === true) {
      SetSubMenu(true);
    } else {
      SetSubMenu(false);
    }
  }, [inView]);

  useEffect(() => {
    //console.log(cookies["OddsArena"]);
    //console.log(cookies);
    if (
      cookies !== undefined &&
      cookies["OddsArena"] !== undefined &&
      cookies["OddsArena"].locationFilter !== undefined
    ) {
      if (cookies["OddsArena"].locationFilter == "true") {
        SetLocationFilter(true);
      } else {
        SetLocationFilter(false);
      }
    }
    if (
      cookies !== undefined &&
      cookies["OddsArena"] !== undefined &&
      cookies["OddsArena"].cryptoOnly !== undefined
    ) {
      if (cookies["OddsArena"].cryptoOnly == "true") {
        SetCryptoOnly(true);
      } else {
        SetCryptoOnly(false);
      }
    }

    if (cookies["OddsArena"] == undefined) {
      SetLocationFilter(false);
      SetCryptoOnly(false);
      //console.log("SHOULD FIRE IN LOCALHOST");
    }

    if (
      cookies["OddsArena"] !== undefined &&
      cookies["OddsArena"].cryptoOnly === undefined &&
      cookies["OddsArena"].locationFilter === undefined
    ) {
      updateExistingCookie();
    }

    if (
      cookies["OddsArena"] !== undefined &&
      cookies["OddsArena"].cookieConsent === false
    ) {
      setShowCookieModal(true);
    }
  }, [cookies]);

  const closeSuggestionModal = () => {
    setShowSuggestionModal(false);
  };

  const launchSuggestionModal = () => {
    setShowSuggestionModal(true);
  };

  async function cookieConsent() {
    await axios("${process.env.REACT_APP_BE_URL}/api/cookies/cookieconsent", {
      withCredentials: true,
    });
    setShowCookieModal(false);
  }

  //console.log(windowSize);
  //console.log(filter);

  //console.log("LAYOUT", document);

  //console.log(searchTerm);
  // console.log(backgroundImage);
  return (
    <>
      <Helmet>
        <title>
          OddsArena | Esports matches, tournaments, streams, schedules, results
          and odds
        </title>
        <meta
          name="description"
          content="OddsArena | Esports matches, tournaments,  streams, schedules, results and odds"
        ></meta>
      </Helmet>
      <div className="sticky top-0 z-30  overscroll-none">
        <Navigation
          mobile={windowSize.width < 1024 && true}
          launchSuggestionModal={launchSuggestionModal}
          closeSuggestionModal={closeSuggestionModal}
          handleSelectedTab={handleSelectedTab}
          page={page}
          handleSetPage={handleSetPage}
          subMenu={subMenu}
          SetRefetchData={SetRefetchData}
          refetchData={refetchData}
          locationFilter={locationFilter}
          SetLocationFilter={SetLocationFilter}
          cryptoOnly={cryptoOnly}
          SetCryptoOnly={SetCryptoOnly}
          filteredGame={filteredGame}
          handleSearchTermChange={handleSearchTermChange}
          searchResults={data}
          isLoading={isLoading}
          searchTerm={searchTerm}
          resetSearchTerm={resetSearchTerm}
        />
      </div>

      <div>
        {showSuggestionModal && (
          <SuggestionModal
            openSuggestionModal={showSuggestionModal}
            closeSuggestionModal={closeSuggestionModal}
          />
        )}
      </div>
      <Routes>
        <Route
          path="/arena"
          element={
            <Arena mobile={windowSize.width < 1024 && true} games={games} />
          }
        />
      </Routes>
      <div
        className={`flex flex-col     bg-mainBackground  ${
          window.location.pathname !== "/arena" &&
          windowSize.width >= 1024 &&
          "bg-mainImage  bg-contain   bg-no-repeat   bg-blend-darken  min-h-screen"
        }`}
      >
        <div ref={ref}></div>
        <div
          className={` ${
            window.location.pathname !== "/arena" &&
            "flex-grow py-3  pr-2 lg:px-7"
          }`}
        >
          <div className="flex flex-col xl:flex-row rounded-md">
            <Routes>
              <Route
                path="/"
                element={
                  <WhichSideBar
                    windowSize={windowSize}
                    menuData={games}
                    handleSelectedTab={handleSelectedTab}
                    page={page}
                    setFilterGame={setFilterGame}
                    filteredGame={filteredGame}
                  />
                }
              />
              <Route
                path="/tournaments"
                element={
                  <WhichSideBar
                    windowSize={windowSize}
                    menuData={games}
                    handleSelectedTab={handleSelectedTab}
                    page={page}
                    setFilterGame={setFilterGame}
                    filteredGame={filteredGame}
                  />
                }
              />
              <Route
                path="/tournaments/:game"
                element={
                  <WhichSideBar
                    windowSize={windowSize}
                    menuData={games}
                    handleSelectedTab={handleSelectedTab}
                    page={page}
                    setFilterGame={setFilterGame}
                    filteredGame={filteredGame}
                  />
                }
              />
              {/*               <Route
                path="/tournament/:tournamentId"
                element={
                  <WhichSideBar
                    windowSize={windowSize}
                    menuData={games}
                    handleSelectedTab={handleSelectedTab}
                  />
                }
              /> */}

              <Route
                path="/matches/:game"
                element={
                  <WhichSideBar
                    windowSize={windowSize}
                    menuData={games}
                    handleSelectedTab={handleSelectedTab}
                    page={page}
                    setFilterGame={setFilterGame}
                    filteredGame={filteredGame}
                  />
                }
              />
              {/*               <Route
                path="/advertise"
                element={
                  <WhichSideBar
                    windowSize={windowSize}
                    menuData={games}
                    handleSelectedTab={handleSelectedTab}
                  />
                }
              /> */}

              <Route
                path="*"
                element={
                  windowSize.width > 1024 && (
                    <div className={`flex w-72 p-3`}></div>
                  )
                }
              />
            </Routes>

            <div
              className={`${
                window.location.pathname !== "/arena" &&
                "flex flex-col xl:ml-5 w-full xl:w-3/5 mt-2 xl:mt-0 mb-10"
              }`}
            >
              {locationFilter !== undefined &&
                cryptoOnly !== undefined &&
                games !== undefined && (
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <InfinitySeries
                          mobile={windowSize.width < 1024 && true}
                          handleSelectedTab={handleSelectedTab}
                          selectedTab={selectedTab}
                          bookies={bookies}
                          games={games}
                          setPage={setPage}
                          refetchData={refetchData}
                          SetRefetchData={SetRefetchData}
                          locationFilter={locationFilter}
                          cryptoOnly={cryptoOnly}
                        />
                      }
                    />

                    <Route
                      path="/tournaments"
                      element={
                        <TournamentPage
                          mobile={windowSize.width < 1024 && true}
                          games={games}
                          page={page}
                          refetchData={refetchData}
                          setPage={setPage}
                          SetRefetchData={SetRefetchData}
                        />
                      }
                    />

                    <Route
                      path="/tournament/:game/:tournamentId"
                      element={
                        <NewTournamentPage
                          mobile={windowSize.width < 1024 && true}
                          handleSelectedTab={handleSelectedTab}
                          selectedTab={selectedTab}
                          games={games}
                          bookies={bookies}
                          locationFilter={locationFilter}
                          cryptoOnly={cryptoOnly}
                          refetchData={refetchData}
                        />
                      }
                    />

                    <Route
                      path="/team/:game/:teamID/"
                      element={
                        <SingleTeamPage
                          mobile={windowSize.width < 1024 && true}
                          games={games}
                          locationFilter={locationFilter}
                          cryptoOnly={cryptoOnly}
                          refetchData={refetchData}
                        />
                      }
                    />

                    <Route
                      path="/match/:game/:serieID"
                      element={
                        <SingleMatch
                          mobile={windowSize.width < 1024 && true}
                          locationFilter={locationFilter}
                          cryptoOnly={cryptoOnly}
                          refetchData={refetchData}
                          games={games}
                        />
                      }
                    />

                    <Route
                      path="/integrity"
                      element={
                        <Integrity mobile={windowSize.width < 1024 && true} />
                      }
                    />
                    <Route
                      path="/bookmakers"
                      element={
                        <Bookmakers
                          mobile={windowSize.width < 1024 && true}
                          refetchData={refetchData}
                          locationFilter={locationFilter}
                          cryptoOnly={cryptoOnly}
                        />
                      }
                    />
                    <Route
                      path="/integrity"
                      element={
                        <Integrity mobile={windowSize.width < 1024 && true} />
                      }
                    />
                    <Route
                      path="/advertise"
                      element={
                        <Advertise mobile={windowSize.width < 1024 && true} />
                      }
                    />
                    <Route
                      path="/our-service"
                      element={
                        <OurService mobile={windowSize.width < 1024 && true} />
                      }
                    />
                    <Route
                      path="/responsible-gambling"
                      element={
                        <ResponsibleGambling
                          mobile={windowSize.width < 1024 && true}
                        />
                      }
                    />
                    {games !== undefined && (
                      <>
                        <Route
                          path="/matches/:game"
                          element={
                            <InfinitySeries
                              mobile={windowSize.width < 1024 && true}
                              handleSelectedTab={handleSelectedTab}
                              selectedTab={selectedTab}
                              bookies={bookies}
                              games={games}
                              setPage={setPage}
                              refetchData={refetchData}
                              SetRefetchData={SetRefetchData}
                              locationFilter={locationFilter}
                              cryptoOnly={cryptoOnly}
                            />
                          }
                        />
                        <Route
                          path="/tournaments/:game"
                          element={
                            <TournamentPage
                              mobile={windowSize.width < 1024 && true}
                              games={games}
                              page={page}
                              refetchData={refetchData}
                              setPage={setPage}
                              SetRefetchData={SetRefetchData}
                            />
                          }
                        />
                      </>
                    )}
                  </Routes>
                )}
            </div>
          </div>
          <ScrollToTop />
          <div className="flex justify-center">
            {showCookieModal ? (
              <div className="z-50 fixed bottom-1 pb-">
                <CookieModal cookieConsent={cookieConsent} />
              </div>
            ) : (
              <div
                className="hidden md:flex z-50 fixed right-1 bottom-1"
                onClick={() => setShowSuggestionModal(true)}
              >
                <LightBulbIcon
                  className="w-12 h-12  text-white hover:text-yellow-400 cursor-pointer"
                  alt="open suggestion form"
                  title="open suggestion form"
                />
              </div>
            )}
          </div>
        </div>

        {window.location.pathname !== "/arena" && <Footer />}
      </div>
      <div></div>
    </>
  );
}
