import React, { useState, useEffect } from "react";
import ParticipantLogo from "../../../components/utils/ParticipantLogo";

export default function CSHalfView({
  data,
  winreasons,
  serieData,
  mobile,
  index,
  game,
}) {
  const [homeTeam, setHomeTeam] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);
  //console.log(data);
  useEffect(() => {
    if (
      data !== undefined &&
      serieData !== undefined &&
      serieData.length === 2 &&
      data.length > 0
    ) {
      let homeFound = false;
      let awayFound = false;

      const homeRoster = data[0].teams.home.roster.id;
      //console.log(homeRoster);
      //try to find a match
      const homeTeamData = serieData.filter(
        (team) => team.roster.id === homeRoster
      );
      if (homeTeamData.length > 0) {
        homeFound = true;
        setHomeTeam(homeTeamData[0]);
      }

      const awayRoster = data[0].teams.away.roster.id;
      // console.log(awayRoster);
      //try to find a match
      const awayTeamData = serieData.filter(
        (team) => team.roster.id === awayRoster
      );
      if (awayTeamData.length > 0) {
        awayFound = true;
        setAwayTeam(awayTeamData[0]);
      }

      if (homeFound && !awayFound) {
        const awayTeamData = serieData.filter(
          (team) => team.roster.id !== homeRoster
        );
        if (awayTeamData.length > 0) {
          awayFound = true;
          setAwayTeam(awayTeamData[0]);
        }
      }

      if (!homeFound && awayFound) {
        const homeTeamData = serieData.filter(
          (team) => team.roster.id !== awayRoster
        );
        if (homeTeamData.length > 0) {
          homeFound = true;
          setHomeTeam(homeTeamData[0]);
        }
      }
    }
  }, [serieData, data]);

  //console.log(homeTeam, awayTeam);
  const imageURLS = [
    {
      url: "https://oa-img-public.s3.eu-north-1.amazonaws.com/oddsarena/csgo/Disarmed.svg",
      id: 78,
      alt: "Bomb defused",
    },
    {
      url: "https://oa-img-public.s3.eu-north-1.amazonaws.com/oddsarena/csgo/Explosion.svg",
      id: 81,
      alt: "Bomb exploded",
    },
    {
      id: 79,
      url: "https://oa-img-public.s3.eu-north-1.amazonaws.com/oddsarena/csgo/Skull_T.svg",
      alt: "Terrorists wins",
    },
    {
      id: 83,
      url: "https://oa-img-public.s3.eu-north-1.amazonaws.com/oddsarena/csgo/Skull_CT.svg",
      alt: "Counter-Terrorists wins",
    },
    {
      id: 82,
      url: "https://oa-img-public.s3.eu-north-1.amazonaws.com/oddsarena/csgo/Timeout.svg",
      alt: "Round time-out",
    },
  ];

  const headerText = (index) => {
    if (index === 0) {
      return "First half";
    } else if (index === 1) {
      return "Second half";
    } else if (index === 2) {
      return "Overtime";
    }
  };

  return (
    <div className={`flex `}>
      <div className="flex-col">
        <div
          className={`${
            index === 0 ? "ml-9" : "ml-1"
          } text-sm text-white text-opacity-70 font-bold ${
            mobile && index !== 0 && "ml-9      "
          }  `}
        >
          {headerText(index)}
        </div>
        <div className="flex">
          {(index === 0 || mobile) && (
            <div className="flex">
              {homeTeam !== null && ParticipantLogo(homeTeam, 7, game, true)}
            </div>
          )}
          <div className="flex">
            {data !== undefined &&
              data.map((pr, index) =>
                pr.teams.home.is_winner ? (
                  <div
                    className="w-5 h-7  bg-serieLeft2 rounded-sm mb-1 pt-1 ml-1"
                    key={index + pr.teams.home.is_winner}
                  >
                    <img
                      className={`w-5 h-5 `}
                      src={
                        imageURLS.filter(
                          (url) => url.id === pr.win_reason.id
                        )[0].url
                      }
                      alt={
                        imageURLS.filter(
                          (url) => url.id === pr.win_reason.id
                        )[0].alt
                      }
                    ></img>
                  </div>
                ) : (
                  <div
                    className="w-5 h-7 bg-serieLeft2 rounded-sm mb-1 pt-1 ml-1"
                    key={pr + index}
                  >
                    <p className="w-5 h-5 ml-1"></p>
                  </div>
                )
              )}

            <div className={`${mobile ? "mx-1" : "mx-2"} text-md text-white`}>
              <p
                className={`${
                  data[data.length - 1].teams.home.score >
                  data[data.length - 1].teams.away.score
                    ? "font-bold "
                    : "text-opacity-30"
                }`}
              >
                {data[data.length - 1].teams.home.score}
              </p>
            </div>
          </div>
        </div>
        <div className="flex">
          {(index === 0 || mobile) && (
            <div className="flex">
              {awayTeam !== null && ParticipantLogo(awayTeam, 7, game, true)}
            </div>
          )}
          <div className="flex">
            {data !== undefined &&
              data.map((pr, index) =>
                pr.teams.away.is_winner ? (
                  <div
                    className="w-5 h-7  bg-serieLeft2 rounded-sm mb-1 pt-1 ml-1"
                    key={index + pr.teams.home.is_winner}
                  >
                    <img
                      className={`w-5 h-5 `}
                      src={
                        imageURLS.filter(
                          (url) => url.id === pr.win_reason.id
                        )[0].url
                      }
                      alt={
                        imageURLS.filter(
                          (url) => url.id === pr.win_reason.id
                        )[0].alt
                      }
                    ></img>
                  </div>
                ) : (
                  <div
                    className="w-5 h-7 bg-serieLeft2 rounded-sm mb-1 pt-1 ml-1"
                    key={pr + index}
                  >
                    <p className="w-5 h-5 ml-1"></p>
                  </div>
                )
              )}

            <div className={`${mobile ? "mx-1 " : "mx-2 "} text-md text-white`}>
              <p
                className={`${
                  data[data.length - 1].teams.away.score >
                  data[data.length - 1].teams.home.score
                    ? "font-bold "
                    : "text-opacity-30"
                }`}
              >
                {data[data.length - 1].teams.away.score}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
