import React, { useState, useEffect } from "react";

export default function LiveScoreMobile({ data, mobile = true }) {
  const [homeBars, setHomeBars] = useState(0);
  const [awayBars, setAwayBars] = useState(0);
  const [bars, setBars] = useState([]);
  const [liveMatch, setLiveMatch] = useState(null);

  //console.log(results, id);
  //console.log(data);

  useEffect(() => {
    if (data !== undefined && data.best_of !== undefined) {
      let tmpHome = [];
      let tmpAway = [];

      let amountOfBars;

      if (data.best_of < 3) {
        amountOfBars = 1;
      } else if (data.best_of === 3) {
        amountOfBars = 2;
      } else if (data.best_of === 5) {
        amountOfBars = 3;
      } else if (data.best_of === 7) {
        amountOfBars = 4;
      }

      //home here

      let homeParticipant = data.participants.filter(
        (part) => part.seed === 1
      )[0];
      let awayParticipant = data.participants.filter(
        (part) => part.seed === 2
      )[0];
      for (let i = 0; i < amountOfBars; i++) {
        //get participant with correct seed..

        if (homeParticipant.score === null) {
          tmpHome.push({ outcome: 0 });
        }

        if (homeParticipant.score !== null) {
          //score == 1
          if (i < homeParticipant.score) {
            tmpHome.push({ outcome: 1 });
          } else {
            tmpHome.push({ outcome: 0 });
          }
        }
      }
      tmpHome = tmpHome.reverse();
      setHomeBars(tmpHome);

      for (let i = 0; i < amountOfBars; i++) {
        //get participant with correct seed..

        if (awayParticipant.score === null) {
          tmpAway.push({ outcome: false });
        }

        if (awayParticipant.score !== null) {
          //score == 1
          if (i < awayParticipant.score) {
            tmpAway.push({ outcome: true });
          } else {
            tmpAway.push({ outcome: false });
          }
        }
      }
      //tmpAway = tmpAway.reverse();
      setAwayBars(tmpAway);
    }
  }, [data]);

  useEffect(() => {
    if (data.matches !== undefined && data.matches.length > 0) {
      let activeMatch = data.matches.findIndex(
        (match) => match.lifecycle === "live"
      );
      if (activeMatch !== -1) {
        setLiveMatch(activeMatch);
      }
    }
  }, [data]);

  //console.log(homeBars, awayBars);

  const resultView = (
    <div className="flex justify-between w-full font-bold text-md">
      <span className={`${mobile ? "pl-2" : "pl-7"}`}>{"bars"}</span>

      <span className={`${mobile ? "pr-2" : "pr-7"}`}>{"test"}</span>
    </div>
  );

  const barView = (
    <div className="flex justify-between w-full font-bold text-md mt-1">
      <div className="flex justify-end">
        <span className={` flex `}>
          {homeBars.length > 0 &&
            homeBars.map((bar, index) => {
              return (
                <div
                  key={index}
                  className={`ml-1 h-8 w-2 ${
                    bar.outcome ? "bg-yellow-600" : " bg-mainBackground"
                  }  rounded-sm`}
                ></div>
              );
            })}
        </span>
      </div>
      {data !== undefined &&
      liveMatch !== null &&
      data.matches[liveMatch].detailed_match !== undefined ? (
        <div className="flex h-auto justify-between w-auto align-middle items-center text-lg text-white text-opacity-90">
          <div>
            {data !== undefined &&
              liveMatch !== null &&
              data.matches[liveMatch].detailed_match !== undefined &&
              data.matches[liveMatch].detailed_match.last_state !== undefined &&
              data.matches[liveMatch].detailed_match.last_state.teams !==
                undefined &&
              data.matches[liveMatch].detailed_match.last_state.teams[
                "home"
              ] !== undefined &&
              data.matches[liveMatch].detailed_match.last_state.teams["home"]
                .score}
            {data !== undefined &&
              liveMatch !== null &&
              data.matches[liveMatch].data !== undefined &&
              data.matches[liveMatch].data.teams !== undefined &&
              data.matches[liveMatch].data["home"] !== undefined &&
              data.matches[liveMatch].data.teams["home"].score}
          </div>
          <div className="flex h-auto justify-center  w-full align-middle items-center text-xs text-white text-opacity-50 mx-4">
            <span>
              {`Map ${liveMatch !== null && liveMatch + 1}`}
              {data.game[0].id === 5 &&
                liveMatch !== null &&
                data.matches[liveMatch].map !== null &&
                `\xa0- ${data.matches[liveMatch].map.name}`}
            </span>
          </div>

          <div>
            {data !== undefined &&
              liveMatch !== null &&
              data.matches[liveMatch].detailed_match !== undefined &&
              data.matches[liveMatch].detailed_match.last_state !== undefined &&
              data.matches[liveMatch].detailed_match.last_state.teams !==
                undefined &&
              data.matches[liveMatch].detailed_match.last_state.teams[
                "away"
              ] !== undefined &&
              data.matches[liveMatch].detailed_match.last_state.teams["away"]
                .score}
            {data !== undefined &&
              liveMatch !== null &&
              data.matches[liveMatch].data !== undefined &&
              data.matches[liveMatch].data.teams !== undefined &&
              data.matches[liveMatch].data["away"] !== undefined &&
              data.matches[liveMatch].data.teams["away"].score}
          </div>
        </div>
      ) : (
        <div className="flex h-auto justify-center  w-28 align-middle items-center text-xs text-white text-opacity-50 ">
          <span>
            {`Map ${liveMatch !== null && liveMatch + 1}`}
            {data.game[0].id === 5 &&
              liveMatch !== null &&
              data.matches[liveMatch].map !== null &&
              `\xa0- ${data.matches[liveMatch].map.name}`}
          </span>
        </div>
      )}
      <div className="flex justify-start">
        <span className={` flex `}>
          {awayBars.length > 0 &&
            awayBars.map((bar, index) => {
              return (
                <div
                  key={index}
                  className={`mr-1 h-8 w-2 ${
                    bar.outcome ? "bg-yellow-600" : " bg-mainBackground"
                  }  rounded-sm`}
                ></div>
              );
            })}
        </span>
      </div>
    </div>
  );

  const infoView = (
    <div className="flex text-xs w-full  justify-center text-white text-opacity-50">
      <span>Map {liveMatch !== null && liveMatch + 1}</span>
      <span>
        {data.game[0].id === 5 &&
          liveMatch !== null &&
          data.matches[liveMatch].map !== null &&
          `\xa0- ${data.matches[liveMatch].map.name}`}
      </span>
    </div>
  );

  /*   const displayResults = () => {
    if (lifecycle === "over-forfeited") {
      return forfeitView;
    } else if (lifecycle === "deleted") {
      return deletedView;
    } else {
      return resultView;
    }
  }; */

  // console.log(id, results[0].score, results[1].score);

  return (
    <div className=" text-white h-full flex items-center justify-center overflow-hidden   ">
      {/*       <div className=" bg-serieLeft2 h-24 w-8 absolute right-1/2 transform rotate-20"></div>
      <div className="bg-serieRight2 h-24 w-8 absolute left-1/2 transform rotate-20"></div> */}
      <div
        className={`relative  ${
          mobile ? "w-full" : " w-44"
        } flex-col items-center justify-center  bg-elementBackground h-10 `}
      >
        {barView}
      </div>
    </div>
  );
}
