import React from "react";
import TimeLeft from "./TimeLeft";
import LiveGame from "./LiveGame";
import { Link } from "react-router-dom";
import tournamentLink from "../../utils/tournamentLink";

function LeftSerieSection({
  start,
  lifecycle,
  data,
  handleTwitchPlayer,
  index,
  length,
  completedSeries,
}) {
  const tournamentTitle = () => {
    let totalStringLength =
      data.tournament[0].short_title.length + data.title.length + 3;

    let returnString = data.tournament[0].short_title + " - " + data.title;

    if (totalStringLength > 25) {
      returnString = `${returnString.slice(0, 25)}...`;
      return returnString;
    } else {
      return returnString;
    }
  };

  //console.log(data);

  /*   const tournamentLink = () => {
    let gameLink = data.game[0].abbreviation
      .toLowerCase()
      .replace(/[:\s\-]/g, "");

    let link = data.tournament[0].title;
    link = link.toLowerCase();
    link = link.replace(/#/g, "");
    link = link.replace(/\s/g, "-");
    link = link.replace(/\//g, "-");
    link = link + "-" + data.tournament[0].id;
    return gameLink + "/" + link;
  };
 */
  let gameLink = (abbreviation) => {
    let returnString = abbreviation;
    returnString = returnString.toLowerCase();
    returnString = returnString.replace(/[:\s\-]/g, "");
    return returnString;
  };

  return (
    <div
      className={`flex w-60 pl-3 pr-3 items-center h-full bg-elementBackground rounded-l-md`}
    >
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full">
          <div className="w-7 h-7 ">
            <img
              className="rounded-sm"
              src={data.game[0].defaultThumbnail}
              alt={data.game[0].title}
              title={data.game[0].title}
            />
          </div>

          <div className="flex  w-32 justify-end">
            {completedSeries === false && (
              <div>
                {lifecycle === "live" ? (
                  <LiveGame
                    data={data}
                    handleTwitchPlayer={handleTwitchPlayer}
                  />
                ) : null}
              </div>
            )}
            <div className="text-white text-sm text-right flex">
              {lifecycle !== "live" && (
                <TimeLeft
                  start={start}
                  lifecycle={lifecycle}
                  completedSeries={completedSeries}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-1 text-white text-xs text-opacity-50 hover:text-red-600">
          <span title={`${data.tournament[0].title} - ${data.title}`}>
            <Link to={`/tournament/${tournamentLink(data, data.game[0])}`}>
              {tournamentTitle()}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default LeftSerieSection;
