import React, { useEffect, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import SerieList from "../components/Series/SerieList";
import LiveSerieList from "../components/Series/LiveSerieList";

import { useInView } from "react-intersection-observer";
import DesktopSpinner from "../components/Spinners/DesktopSpinner";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Lottie from "lottie-light-react";
import loadingAnimation from "../assets/loading_1.json";
import { useCookies } from "react-cookie";
import PageAd from "../components/PageAd";
import SEOFooter from "./SEOFooter";
import SearchBar from "../components/SearchBar/SearchBar";

export default function InfinitySeries({
  mobile = false,
  bookies,
  games,
  refetchData,
  locationFilter,
  cryptoOnly,
  setPage,
}) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    setPage("matches");
  }, []);
  const { game } = useParams();
  const [series, setSeries] = useState(true);

  //development only for cookies
  /*   let cookie = {
    OddsArena: { locationFilter: "true", cryptoOnly: "false" },
  }; */

  //console.log(game);

  //console.log("GAMES: ", games);
  /*   useEffect(() => {
    if (game !== undefined && games !== undefined) {
      console.log(games);
      let row = games.filter((sg) => sg.gameMenu === game)[0];
      console.log(row.id);
      setSelection(row.id);
    }
  }, [game]); */
  const [cookie, setCookies] = useCookies(["OddsArena"]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!series) {
    }
    if (inView === true && !isFetchingNextResultsPage) {
      resultsFetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (series) {
    }
    if (inView === true && !isFetchingNextPage) {
      serieFetchNextPage();
    }
  }, [inView]);

  function mainLoads() {
    if (selectedTab === 0) {
      if (serieData !== undefined && liveData !== undefined) {
        return false;
      }
    } else {
      if (liveData !== undefined && resultsData !== undefined) {
        return false;
      }
    }
    return true;
  }

  //console.log(game);

  const fetchProjects = async ({ pageParam = 0 }) => {
    let data;
    //console.log(filter);

    if (game !== undefined) {
      if (games !== undefined) {
        let singleGame = games.filter((sg) => sg.gameMenu === game)[0];

        data = await fetch(
          `${process.env.REACT_APP_BE_URL}/api/series/inf?cursor=${pageParam}&game=${singleGame.id}&locationFilter=${locationFilter}&crypto=${cryptoOnly}`,
          { credentials: "same-origin" }
        );
      }
    } else {
      data = await fetch(
        `${process.env.REACT_APP_BE_URL}/api/series/inf?cursor=${pageParam}&locationFilter=${locationFilter}&crypto=${cryptoOnly}`,
        { credentials: "same-origin" }
      );
    }

    //console.log(game, games);
    if (data.status !== 204) {
      data = await data.json();

      //console.log(data);
    }
    return data;
  };

  const fetchLive = async () => {
    let data;
    //console.log(filter);

    if (game !== undefined) {
      if (games !== undefined) {
        let singleGame = games.filter((sg) => sg.gameMenu === game)[0];

        data = await fetch(
          `${process.env.REACT_APP_BE_URL}/api/series/live?&game=${singleGame.id}`,
          { credentials: "same-origin" }
        );
      }
    } else {
      data = await fetch(`${process.env.REACT_APP_BE_URL}/api/series/live`, {
        credentials: "same-origin",
      });
    }

    if (data.status !== 204) {
      data = await data.json();
    }
    return data;
  };

  const fetchResults = async ({ pageParam = 0 }) => {
    let data;
    //console.log(filter);

    if (game !== undefined) {
      if (games !== undefined) {
        let singleGame = games.filter((sg) => sg.gameMenu === game)[0];

        data = await fetch(
          `${process.env.REACT_APP_BE_URL}/api/series/completed?cursor=${pageParam}&game=${singleGame.id}`,
          { credentials: "same-origin" }
        );
      }
    } else {
      data = await fetch(
        `${process.env.REACT_APP_BE_URL}/api/series/completed?cursor=${pageParam}`,
        { credentials: "same-origin" }
      );
    }

    //console.log(game, games);
    if (data.status !== 204) {
      data = await data.json();

      //console.log(data);
    }
    return data;
  };

  const {
    data: serieData,
    fetchNextPage: serieFetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    refetch,
  } = useInfiniteQuery(["projects", game, refetchData], fetchProjects, {
    refetchOnWindowFocus: false,
    enabled: series,
    getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
  });

  const {
    data: resultsData,
    fetchNextPage: resultsFetchNextPage,
    hasNextPage: resultsHasNextPage,
    isFetchingNextPage: isFetchingNextResultsPage,
    status: resultsStatus,
    refetch: resultsRefetch,
  } = useInfiniteQuery(["results", game], fetchResults, {
    refetchOnWindowFocus: false,
    enabled: !series,
    getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
  });

  const { data: liveData, status: liveStatus } = useQuery(
    ["liveSeries", game, refetchData],
    fetchLive,
    {
      refetchOnWindowFocus: true,
      refetchInterval: 30000,
    }
  );

  const handleSelectedTab = (index, series) => {
    setSelectedTab(index);
    setSeries(series);
  };

  const tabs = [
    { id: 0, name: "Upcoming Matches" },
    { id: 1, name: "Results" },
  ];

  const seriesResults = (
    <div
      className={`flex h-8 w-full mt-5 ${
        liveData !== undefined && liveData.length > 0 && ""
      } mb-2`}
    >
      <div className={`${mobile && "w-1/2"}`}>
        <button
          className={`${
            mobile ? "text-xs px-2 w-full" : "w-48 px-3"
          }  h-8   pt-1 pb-1 rounded-l-md shadow-md uppercase   ${
            series === true
              ? "bg-serieRight2 text-white text-opacity-90 font-semibold"
              : "bg-elementBackground hover:text-red-600 text-gray-500 "
          } `}
          onClick={() => handleSelectedTab(0, true)}
        >
          {tabs[0].name}
        </button>
      </div>
      <div className={`${mobile && "w-1/2"}`}>
        <button
          className={`${mobile ? "text-xs px-2 w-full" : "w-48 px-3"}
            h-8 shadow-md  pl-2 pb-1 pt-1  bg-elementBackground uppercase rounded-r-md  ${
              series === false
                ? "bg-serieRight2  text-white text-opacity-90 font-semibold"
                : "bg-elementBackground hover:text-red-600 text-gray-500"
            }`}
          onClick={() => handleSelectedTab(1, false)}
        >
          {tabs[1].name}
        </button>
      </div>
    </div>
  );

  const gameHeader = () => {
    if (game == undefined) {
      return "";
    } else {
      return game;
    }
  };

  //console.log("INITIAL DATA:", liveData);
  // console.log(liveData);
  return (
    <>
      {game === undefined && (
        <Helmet>
          <title>
            OddsArena | Esports matches, live scores, live streams, match
            schedules, results and odds
          </title>
          <meta
            name="description"
            content="Esports matches, live scores, live streams, esports results combined with the best odds around for your favourite game | OddsArena"
          ></meta>
        </Helmet>
      )}
      {game !== undefined && (
        <Helmet>
          <title>{`OddsArena | ${game.toUpperCase()} matches`}</title>
          <meta
            name="description"
            content={`${game.toUpperCase()} Esports matches, live scores, live streams, esports results combined with the best odds around for your favourite game | OddsArena ${game.toUpperCase()} matches, live streams and results combined with the best odds around`}
          ></meta>
        </Helmet>
      )}

      <div className="">
        <div
          className={`flex flex-col w-full px-3 py-2 bg-elementBackground rounded-md  shadow-lg  max-w-none border border-white border-opacity-60 ${
            !mobile && "w-1/2"
          }`}
        >
          <h2 className="uppercase text-white font-bold text-2xl">{`${gameHeader()} Matches and Results`}</h2>
          <p className="  text-white text-base text-opacity-80">
            Below you can see live esports scores / scheduled{" "}
            {gameHeader() === "" ? "esports" : gameHeader()} matches and
            results.
          </p>
          <p className="text-white text-xs text-opacity-40 italic">
            Live matches, with a blinking live button means that OddsArena has
            identified a stream for the game. Otherwise the match is just
            visualized with an ongoing button.
          </p>
        </div>

        {liveStatus === "loading" && (
          <Lottie animationData={loadingAnimation} style={{ height: 200 }} />
        )}

        <>
          {liveStatus !== "loading" &&
            liveData !== undefined &&
            liveData !== 204 &&
            liveData.data !== undefined &&
            liveData.data.length > 0 && (
              <div className="mt-5">
                <LiveSerieList
                  game={game}
                  data={liveData.data}
                  games={games}
                  mobile={mobile}
                  live={true}
                />
              </div>
            )}

          {/*           {liveStatus !== "loading" && liveData.success === false && (
              <div className="bg-elementBackground rounded-md px-3 py-3 text-white  text-sm w-full mt-5 text-center">
                <span>
                  No live games to show for the selected game, try selecting or
                  filtering on another game
                </span>
              </div>
            )} */}
          {series && status === "loading" && (
            <Lottie animationData={loadingAnimation} style={{ height: 200 }} />
          )}

          {/*           {liveStatus !== "loading" &&
            status !== "loading" &&
            resultsStatus !== "loading" && (
              <div className="flex w-full ">
                <PageAd
                  mobile={mobile}
                  game={
                    game === undefined || game === "csgo"
                      ? "cyberbet"
                      : "thunder"
                  }
                />
              </div>
            )} */}
          {liveStatus !== "loading" &&
            status !== "loading" &&
            resultsStatus !== "loading" && <div>{seriesResults}</div>}
          {series &&
            status !== "loading" &&
            serieData.pages[0].success === true && (
              <div className="">
                <SerieList
                  game={game}
                  data={serieData}
                  games={games}
                  bookies={bookies}
                  mobile={mobile}
                  series={series}
                />
              </div>
            )}
          {series &&
            status !== "loading" &&
            serieData.pages[0].success === false && (
              <div className="bg-elementBackground rounded-md px-3 py-3 text-white w-full text-sm mt-5 text-center">
                <span className="">
                  No upcoming games to show for the selected game, try selecting
                  or filtering on another game
                </span>
              </div>
            )}
          {!series && resultsStatus === "loading" && (
            <Lottie animationData={loadingAnimation} style={{ height: 200 }} />
          )}
          {!series &&
            resultsStatus !== "loading" &&
            resultsData.pages[0].success === true && (
              <div className="">
                <SerieList
                  game={game}
                  data={resultsData}
                  games={games}
                  mobile={mobile}
                  completedSeries={true}
                />
              </div>
            )}
          {!series &&
            resultsStatus !== "loading" &&
            resultsData.pages[0].success === false && (
              <div className="bg-elementBackground rounded-md px-3 py-3 text-white w-full text-sm mt-5 text-center">
                <span className="">
                  No results to show for the selected game, try selecting or
                  filtering on another game
                </span>
              </div>
            )}
        </>

        <>
          <div className="mt-2">
            {selectedTab === 0 && isFetchingNextPage && !mainLoads() ? (
              <DesktopSpinner mobile={mobile} />
            ) : (
              hasNextPage && <div ref={ref}></div>
            )}
            {selectedTab === 1 && isFetchingNextResultsPage && !mainLoads() ? (
              <DesktopSpinner mobile={mobile} />
            ) : (
              resultsHasNextPage && <div ref={ref}></div>
            )}
          </div>
        </>
      </div>
      {status !== "loading" &&
        liveStatus !== "loading" &&
        resultsStatus !== "loading" && <SEOFooter game={game} />}
    </>
  );
}
