import React, { useEffect, useState } from "react";
import tournamentLink from "../../components/utils/tournamentLink";
import teamLink from "../../components/utils/teamLink";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export default function SingleMatchTournamentInfo({
  tournamentData,
  matchdata,
  mobile,
}) {
  const upcomingText = () => {
    return (
      <>
        <div className="text-white text-opacity-80">
          <p>
            <span>
              <span>On </span>
              <span className="font-bold">
                {new Date(matchdata.start).toLocaleDateString([], {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}{" "}
              </span>
              <span>at </span>
              <span className="font-bold">
                {new Date(matchdata.start).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })}
              </span>
              <span>, </span>
              <Link
                to={`/team/${teamLink(
                  matchdata.participants.filter((part) => part.seed === 1)[0]
                    .teamData,
                  matchdata.game[0]
                )}`}
              >
                <span className="hover:underline   hover:cursor-pointer font-bold text-red-500">
                  {
                    matchdata.participants.filter((part) => part.seed === 1)[0]
                      .teamData.name
                  }
                </span>
              </Link>
              <span> vs </span>
              <Link
                to={`/team/${teamLink(
                  matchdata.participants.filter((part) => part.seed === 2)[0]
                    .teamData,
                  matchdata.game[0]
                )}`}
              >
                <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                  {
                    matchdata.participants.filter((part) => part.seed === 2)[0]
                      .teamData.name
                  }
                </span>
              </Link>
              <span>
                {matchdata.lifecycle === "live"
                  ? " started playing a"
                  : " will be playing a"}{" "}
              </span>
              <span>
                <Link
                  to={`/matches/${matchdata.game[0].abbreviation
                    .toLowerCase()
                    .replace(/[:\s\-]/g, "")}`}
                >
                  <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                    {matchdata.game[0].title}
                  </span>{" "}
                </Link>
                esports match.{" "}
              </span>
              <p>
                <span>
                  The match is played in a best of {matchdata.best_of} format
                  and is{" "}
                </span>
                <span>part of the </span>
                <Link
                  to={`/tournament/${tournamentLink(
                    tournamentData,
                    matchdata.game[0],
                    true
                  )}`}
                >
                  {" "}
                  <span className="font-bold hover:underline hover:cursor-pointer text-red-500">
                    <span>{matchdata.title}</span>
                    <span> for </span>
                    <span>{tournamentData.title}</span>{" "}
                  </span>
                </Link>
              </p>
              <p></p>
            </span>
          </p>
          <br />
          <p>
            <h3 className="text-white text-opacity-60">How to Watch</h3>
            <p>
              <span>
                Don't miss a second of the action! Catch the game live on{" "}
              </span>
              <Link to={"/"}>
                <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                  oddsarena.gg
                </span>
              </Link>
              <span>. Find more scheduled matches at the </span>
              <Link
                to={`/matches/${matchdata.game[0].abbreviation
                  .toLowerCase()
                  .replace(/[:\s\-]/g, "")}`}
              >
                <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                  {matchdata.game[0].title}
                </span>
              </Link>
              <span> match page, check up on the latest results at </span>
              <Link
                to={`/matches/${matchdata.game[0].abbreviation
                  .toLowerCase()
                  .replace(/[:\s\-]/g, "")}`}
              >
                <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                  {`${matchdata.game[0].title} results`}
                </span>
              </Link>
              <span>
                {mobile ? "." : " or watch multiple live games at once at "}
              </span>
              {!mobile && (
                <Link to={`/arena`}>
                  <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                    The Arena.
                  </span>
                </Link>
              )}
            </p>
          </p>
        </div>
      </>
    );
  };

  //console.log("SMTI: ", matchdata);

  const endCondition = () => {
    if (matchdata.lifecycle !== "over-draw") {
      return (
        <>
          <span> in favour of </span>
          <span>
            {
              matchdata.participants.filter((part) => part.winner === true)[0]
                .teamData.name
            }
          </span>
        </>
      );
    } else {
      return <div />;
    }
  };
  const pastText = () => {
    return (
      <>
        <div className="text-white text-opacity-80">
          <p>
            <span>
              <span>On </span>
              <span className="font-bold">
                {new Date(matchdata.start).toLocaleDateString([], {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}{" "}
              </span>
              <span>at </span>
              <span className="font-bold">
                {new Date(matchdata.start).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })}
              </span>
              <span>, </span>
              <Link
                to={`/team/${teamLink(
                  matchdata.participants.filter((part) => part.seed === 1)[0]
                    .teamData,
                  matchdata.game[0]
                )}`}
              >
                <span className="hover:underline   hover:cursor-pointer font-bold text-red-500">
                  {
                    matchdata.participants.filter((part) => part.seed === 1)[0]
                      .teamData.name
                  }
                </span>
              </Link>
              <span> vs </span>
              <Link
                to={`/team/${teamLink(
                  matchdata.participants.filter((part) => part.seed === 2)[0]
                    .teamData,
                  matchdata.game[0]
                )}`}
              >
                <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                  {
                    matchdata.participants.filter((part) => part.seed === 2)[0]
                      .teamData.name
                  }
                </span>
              </Link>
              <span> played a </span>
              <span>
                <Link
                  to={`/matches/${matchdata.game[0].abbreviation
                    .toLowerCase()
                    .replace(/[:\s\-]/g, "")}`}
                >
                  <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                    {matchdata.game[0].title}
                  </span>{" "}
                </Link>
                esports match.{" "}
              </span>
              <br></br>
              <p className="mt-4">
                <span>The match ended </span>
                <span>
                  {
                    matchdata.participants.filter((part) => part.seed === 1)[0]
                      .score
                  }
                </span>
                <span>{" - "}</span>
                <span>
                  {
                    matchdata.participants.filter((part) => part.seed === 2)[0]
                      .score
                  }
                </span>
                <span>
                  {matchdata.lifecycle !== "over-draw" ? ", " : "."}
                  {endCondition()}
                </span>

                <p>
                  <br></br>
                  <span>The game was part of the </span>
                  <Link
                    to={`/tournament/${tournamentLink(
                      tournamentData,
                      matchdata.game[0],
                      true
                    )}`}
                  >
                    {" "}
                    <span className="font-bold hover:underline hover:cursor-pointer text-red-500">
                      <span>{matchdata.title}</span>
                      <span> for </span>
                      <span>{tournamentData.title}</span>
                    </span>
                    <span>.</span>
                  </Link>
                </p>
              </p>
              <p></p>
            </span>
          </p>
          <br />
          <p>
            <p>
              <span>Find more matches and games at </span>
              <Link to={"/"}>
                <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                  oddsarena.gg
                </span>
              </Link>
              <span>. For more scheduled </span>
              <Link
                to={`/matches/${matchdata.game[0].abbreviation
                  .toLowerCase()
                  .replace(/[:\s\-]/g, "")}`}
              >
                <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                  {matchdata.game[0].title}
                </span>
              </Link>
              <span> matches, check up on the latest results at </span>
              <Link
                to={`/matches/${matchdata.game[0].abbreviation
                  .toLowerCase()
                  .replace(/[:\s\-]/g, "")}`}
              >
                <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                  {`${matchdata.game[0].title} results`}
                </span>
              </Link>
              <span>
                {mobile ? "." : ", or watch multiple live games at once at "}
              </span>
              {!mobile && (
                <Link to={`/arena`}>
                  <span className="hover:underline text-red-500  hover:cursor-pointer font-bold">
                    The Arena.
                  </span>
                </Link>
              )}
            </p>
          </p>
        </div>
      </>
    );
  };

  const upcoming = () => {
    if (matchdata.lifecycle === "upcoming") {
      return true;
    } else if (matchdata.lifecycle === "live") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (matchdata !== undefined) {
      //console.log("TOURNAMENTSERIES");
      let browserLocale = navigator.language;
      //console.log(browserLocale);
      let format = new Intl.DateTimeFormat(`${navigator.language}`, {
        month: "long",
      });
      let start = new Date(matchdata.start);
      let test = format.format(start);
      //console.log("MÅNAD: ", test);
    }
  }, []);

  const logo = () => {
    if (tournamentData !== undefined && tournamentData.images.length > 0) {
      let image = tournamentData.images.filter(
        (image) => image.type === "large"
      );
      if (image.length === 0) {
        image = tournamentData.images.filter((image) => image.type === "small");
      }
      //console.log(image);
      return (
        <img
          className={`${mobile ? "rounded-t-md" : "rounded-tr-md "} `}
          src={image[0].url}
        ></img>
      );
    } else {
      return null;
    }
  };

  //console.log(matchdata);

  const mobileDisplay = (
    <div className="flex-col mt-5 bg-serieRight2 rounded-md shadow-md rounded-t-md text-xs text-white  text-opacity-100">
      <div>{logo()}</div>
      <div className={`flex-col ${mobile ? "p-2" : "p-2"} text-white`}>
        <div>
          <p className="text-white text-opacity-50">Tournament</p>
        </div>
        <Link
          to={`/tournament/${tournamentLink(
            tournamentData,
            matchdata.game[0],
            true
          )}`}
        >
          <div>
            <p className=" text-opacity-80 truncate hover:underline hover:cursor-pointer hover:text-red-500">
              {tournamentData.title}
            </p>
          </div>
        </Link>
        <div className="mt-1">
          <p className="text-white text-opacity-50">Match stage</p>
        </div>
        <div>
          <p className=" text-opacity-80 truncate">{matchdata.title}</p>
        </div>
        <div className="flex  mt-1">
          <div className="flex-col w-1/2">
            <div>
              <span className="text-white text-opacity-50">Date</span>
            </div>
            <div>
              <span>
                {matchdata.start !== undefined &&
                  new Date(matchdata.start).toLocaleDateString([], {
                    month: "short",
                    day: "numeric",
                  })}
              </span>
            </div>
          </div>
          <div className="flex-col w-1/2">
            <div>
              <span className="text-white text-opacity-50">Time</span>
            </div>
            <div>
              <span>
                {matchdata.start !== undefined &&
                  new Date(matchdata.start).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hourCycle: "h24",
                  })}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Link
        to={`/tournament/${tournamentLink(
          tournamentData,
          matchdata.game[0],
          true
        )}`}
      >
        <div className=" rounded-b-md text-red-500  bg-serieLeft2 px-2 py-2 flex justify-between hover:bg-hoverBackground hover:cursor-pointer">
          <span className="text-xs font-bold uppercase">Visit tournament</span>
          <div className="">
            <ChevronDoubleRightIcon className="h-5" />
          </div>
        </div>
      </Link>
    </div>
  );

  //console.log(tournamentData);

  const desktopDisplay = (
    <>
      <div className="flex mt-5  ">
        <div className="bg-elementBackground pr-2 border-t border-r border-l border-white border-opacity-20 rounded-tr-md">
          <h2 className="text-lg first-letter:ml-1 font-bold uppercase text-white text-opacity-80  ">
            Tournament information
          </h2>
        </div>
      </div>
      <div className="flex-col  bg-serieRight2 rounded-r-md rounder-bl-md shadow-md text-xs text-white  text-opacity-100 border border-white border-opacity-20">
        <div className="flex">
          <div
            className={`flex-col ${mobile ? "p-2" : "p-2"} text-white w-1/2`}
          >
            <div>
              <p className="text-white text-opacity-50">Tournament</p>
            </div>
            <Link
              to={`/tournament/${tournamentLink(
                tournamentData,
                matchdata.game[0],
                true
              )}`}
            >
              <div>
                <p className=" text-opacity-80 truncate hover:underline hover:cursor-pointer hover:text-red-500">
                  {tournamentData.title}
                </p>
              </div>
            </Link>

            <div className="flex  mt-2">
              <div className="flex-col w-1/2">
                <div>
                  <span className="text-white text-opacity-50">
                    Tournament Date(s)
                  </span>
                </div>
                <div>
                  <span>
                    {` ${
                      matchdata.start !== undefined &&
                      new Date(tournamentData.start).toLocaleDateString([], {
                        month: "short",
                        day: "numeric",
                      })
                    } ${
                      tournamentData.start !== undefined &&
                      tournamentData.end !== undefined &&
                      " - "
                    } ${
                      matchdata.start !== undefined &&
                      new Date(tournamentData.end).toLocaleDateString([], {
                        month: "short",
                        day: "numeric",
                      })
                    }`}
                  </span>
                </div>
              </div>
              <div className="flex-col w-1/2">
                {/*               <div>
                <span className="text-white text-opacity-50">
                  Tournament Start time
                </span>
              </div>
              <div>
                <span>
                  {matchdata.start !== undefined &&
                    new Date(matchdata.start).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hourCycle: "h24",
                    })}
                </span>
              </div> */}
              </div>
            </div>
            <div className="mt-2">
              <p className="text-white text-opacity-50">Prize pool</p>
              <p>
                {tournamentData.string_prize_pool !== undefined &&
                  tournamentData.string_prize_pool.total !== undefined &&
                  tournamentData.string_prize_pool.total}
              </p>
            </div>
            <div>
              <p className=" text-opacity-80 truncate">
                {tournamentData.prize_pool}
              </p>
            </div>
          </div>
          <div className="flex-col w-3/4 h-full">
            {logo()}
            <div className="">
              <Link
                to={`/tournament/${tournamentLink(
                  tournamentData,
                  matchdata.game[0],
                  true
                )}`}
              >
                <div className="  text-red-500 rounded-br-md  bg-serieRight2 border-l border-white border-opacity-20 px-2 py-2 flex justify-between hover:bg-hoverBackground hover:cursor-pointer">
                  <span className="text-xs font-bold uppercase">
                    Visit tournament
                  </span>
                  <div className="">
                    <ChevronDoubleRightIcon className="h-5" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="">{mobile ? mobileDisplay : desktopDisplay}</div>
      {matchdata !== undefined && matchdata.participants.length > 1 && (
        <div className="flex-col mt-5">
          <div className="flex">
            <div className="bg-elementBackground pr-2 border-t border-r border-l border-white border-opacity-20 rounded-tr-md">
              <h2 className="text-lg ml-1 font-bold uppercase  text-white text-opacity-80  ">
                {upcoming() ? "Match Preview" : "Match Summary"}
              </h2>
            </div>
          </div>
          <div className="flex-col  bg-serieRight2  text-xs text-white  text-opacity-100 border border-white border-opacity-20">
            <div className={`flex-col ${mobile ? "p-2" : "p-2"} text-white`}>
              {upcoming() ? upcomingText() : pastText()}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
