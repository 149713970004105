import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import SuggestionForm from "./SuggestionForm";
import { Helmet } from "react-helmet-async";

export default function SuggestionModal({
  openSuggestionModal,
  closeSuggestionModal,
}) {
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Helmet>
        <title>OddsArena | Suggestions</title>
      </Helmet>
      <Transition.Root show={openSuggestionModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 "
          initialFocus={cancelButtonRef}
          onClose={closeSuggestionModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-mainBackground bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-blue rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle ">
                <div className="sm:flex sm:items-start">
                  <SuggestionForm closeSuggestionModal={closeSuggestionModal} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
