import React from "react";
import { Link } from "react-router-dom";

export default function SideMenu({
  menuData,
  setFilterGame,
  filteredGame,
  page,
}) {
  //console.log(page);
  return (
    <div className="flex flex-col w-full p-3  bg-elementBackground rounded-md shadow-lg border border-white border-opacity-60">
      <Link to={`/${page === "matches" ? "" : page}`}>
        <div className="">
          <button className="text-white font-bold text-base uppercase hover:text-red-600 w-full text-left">
            <span className="">all games</span>
          </button>
        </div>
      </Link>

      <div className="border-b border-white border-opacity-30 mt-half" />
      {menuData.map((game) => {
        return (
          <div className={`flex flex-col mt-2 `} key={game.id}>
            <Link to={`/${page}/${game.gameMenu}`} key={game.id}>
              <button
                className={`hover:text-red-600 text-white text-bold text-sm pb-1`}
                key={game.id}
              >
                <div className="flex items-center  align-middle">
                  <div>
                    <img
                      className="h-6 w-6 rounded-sm"
                      src={game.defaultThumbnail}
                      alt={game.title}
                    />
                  </div>
                  <div className=" ml-2">
                    <p>
                      {game.title.length < 15 ? game.title : game.abbreviation}
                    </p>
                  </div>
                </div>
              </button>
            </Link>

            <div className="border-b border-white border-opacity-30 mt-half"></div>
            {game.tournaments !== undefined && game.tournaments.length > 0
              ? game.tournaments.map((tournament) => {
                  return (
                    <Link
                      to={`/tournament/${tournament.id}`}
                      key={tournament.id}
                    >
                      <div key={tournament.id}>
                        <button
                          className="hover:text-red-600 text-white text-xs text-left pb-1"
                          key={tournament.id}
                        >
                          {tournament.title}
                        </button>
                      </div>
                    </Link>
                  );
                })
              : null}
          </div>
        );
      })}
    </div>
  );
}
