import React from "react";

export default function PlayerStats({
  detailed_match,
  serieData,
  mobile,
  game,
  players,
  draft,
}) {
  //console.log(players);

  const team = detailed_match;

  const playerImage = (player) => {
    let playerImageReturn = <div className="w-8 h-8"></div>;
    //console.log(player);

    if (
      player !== undefined &&
      player.images !== undefined &&
      player.images.length > 0
    ) {
      playerImageReturn = (
        <div className="w-8 h-8">
          <img
            src={player.images[0].thumbnail}
            className="rounded-md"
            alt={`${player.nick_name}`}
          />
        </div>
      );
    }
    return playerImageReturn;
  };

  const heroImage = (hero) => {
    let playerImageReturn = <div className="w-8 "></div>;
    //console.log(player);
    //dota
    if (
      hero !== undefined &&
      hero.images !== undefined &&
      hero.images.length > 0 &&
      game[0].id === 1
    ) {
      playerImageReturn = (
        <img
          src={hero.images.filter((i) => i.type === "large")[0].url}
          className="rounded-md"
          alt={hero.name}
          title={hero.name}
        />
      );
    }
    if (
      hero !== undefined &&
      hero.images !== undefined &&
      hero.images.length > 0 &&
      game[0].id === 2
    ) {
      playerImageReturn = (
        <img
          src={hero.images[0].url}
          className="rounded-md"
          title={hero.name}
          alt={hero.name}
        />
      );
    }
    return playerImageReturn;
  };

  const playerKills = (player) => {
    if (game !== undefined) {
      if (game[0].id === 1) {
        return player.kills.total;
      } else if (game[0].id === 5) {
        return player.match_stats.kills.enemy.total;
      } else if (game[0].id === 2) {
        return player.kills.total;
      }
    }
  };

  const playerDeaths = (player) => {
    if (game !== undefined) {
      if (game[0].id === 1) {
        return player.deaths.total;
      } else if (game[0].id === 5) {
        return (
          player.match_stats.deaths.enemy +
          player.match_stats.deaths.bomb +
          player.match_stats.deaths.friendly_fire
        );
      } else if (game[0].id === 2) {
        return player.deaths.total;
      }
    }
  };

  const playerAssists = (player) => {
    if (game !== undefined) {
      if (game[0].id === 1) {
        return player.assists.total;
      } else if (game[0].id === 5) {
        return player.match_stats.assists.enemy.total;
      } else if (game[0].id === 2) {
        return player.assists.total;
      }
    }
  };

  const playerName = (player) => {
    let playerName = "";
    if (players !== undefined) {
      let singlePlayer = players.filter((p) => p.id === player.id);
      if (singlePlayer.length > 0) {
        if (singlePlayer[0].nick_name !== undefined) {
          //console.log(singlePlayer[0].nick_name.length);
          if (singlePlayer[0].nick_name.length > 15) {
            playerName = singlePlayer[0].nick_name.slice(0, 15);
          } else {
            playerName = singlePlayer[0].nick_name;
          }
        }
      }
    }
    //console.log(playerName);
    return playerName;
  };

  const playerHeader = (
    <div className="flex   text-white text-opacity-40 w-full text-xs  font-semibold pr-1">
      <div className="ml-2 w-36 flex items-center">
        <p>Player</p>
      </div>
      <div className="flex ">
        <div className={`flex   items-center  text-xs font-normal`}>
          <div className=" w-8 h-8 flex justify-center   items-center  text-center ">
            <p>Kills</p>
          </div>
          <div className=" w-8 h-8 flex justify-center   items-center  text-center ml-4">
            <p>Deaths</p>
          </div>
          <div className=" w-8 h-8 flex justify-center   items-center  text-center ml-4">
            <p>Assists</p>
          </div>
          <div className=" w-8 h-8 flex justify-center   items-center  text-center ml-4">
            <p>K / D</p>
          </div>
        </div>
      </div>
    </div>
  );

  const playerRow = (player) => {
    //console.log(players.filter((p) => p.id === player.id)[0]);
    //console.log("PLAYER: ", player);
    //console.log("DRAFT: ", draft);
    return (
      <div className="flex  ml-2 my-1 w-full mr-2 text-white text-sm pr-1">
        <div className={`flex  w-36 items-center `}>
          <div
            className={`align-middle ${
              game !== undefined && game[0].id === 1 && "py-1 h-8 w-10"
            } ${game !== undefined && game[0].id === 2 && "h-8 w-8"} flex`}
          >
            {game !== undefined &&
              game[0].id === 5 &&
              playerImage(players.filter((p) => p.id === player.id)[0])}

            {game !== undefined &&
              game[0].id === 1 &&
              heroImage(draft.filter((h) => h.id === player.hero.id)[0])}
            {game !== undefined &&
              game[0].id === 2 &&
              draft !== undefined &&
              player !== undefined &&
              heroImage(draft.filter((h) => h.id === player.champion.id)[0])}
          </div>
          <div className="flex text-center align-middle items-center ml-2">
            <p className="text-white text-xs font-bold truncate">
              {playerName(player)}{" "}
            </p>
          </div>
        </div>
        <div className="flex  items-center justify-end ">
          <div
            className={` w-8 ${
              mobile ? "h-6" : "h-8"
            } flex justify-center  bg-serieLeft2 rounded-md  items-center  text-center mr-4`}
          >
            <p>{playerKills(player)}</p>
          </div>
          <div
            className={` w-8 ${
              mobile ? "h-6" : "h-8"
            } flex justify-center  bg-serieLeft2 rounded-md  items-center  text-center mr-4`}
          >
            <p>{playerDeaths(player)}</p>
          </div>
          <div
            className={` w-8 ${
              mobile ? "h-6" : "h-8"
            } flex justify-center  bg-serieLeft2 rounded-md  items-center  text-center mr-4`}
          >
            <p>{playerAssists(player)}</p>
          </div>
          <div
            className={` w-8  ${
              mobile ? "h-6" : "h-8"
            } flex justify-center  bg-serieLeft2 rounded-md  items-center  text-center mr-2 `}
          >
            <p
              className={`${
                playerKills(player) - playerDeaths(player) > -1
                  ? "text-green-500 font-bold"
                  : "text-red-500 font-bold"
              }`}
            >
              {playerKills(player) - playerDeaths(player)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  //console.log("TEST: ", detailed_match.last_state.teams.home.players);

  return (
    <div
      className={`${
        mobile ? "flex-col" : "flex  justify-around"
      } overflow-x-auto `}
    >
      <div>
        <div>{playerHeader}</div>
        <div>
          {detailed_match.last_state !== undefined &&
            detailed_match.last_state.teams !== undefined &&
            detailed_match.last_state.teams.home !== undefined &&
            detailed_match.last_state.teams.home.players !== undefined &&
            players !== undefined &&
            detailed_match.last_state.teams.home.players.map((player) => (
              <div key={player.id} className="flex w-full">
                {playerRow(player)}
              </div>
            ))}
        </div>
      </div>
      <div>
        <div>{playerHeader}</div>
        <div>
          {detailed_match.last_state !== undefined &&
            players !== undefined &&
            detailed_match.last_state.teams.away.players.map((player) => (
              <div key={player.id} className="flex w-full">
                {playerRow(player)}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
