import { MenuIcon, XIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import OALogo from "../../assets/logo_white50h.png";
import { useCookies } from "react-cookie";
import { Switch } from "@headlessui/react/";
import axios from "axios";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import SearchBar from "../../components/SearchBar/SearchBar";
import SearchResults from "../../components/SearchBar/SearchResults";

const menuItems = [
  {
    link: "/arena",
    name: "The Arena",
    mobile: false,
    page: "arena",
  },
  {
    link: "/",
    name: "Matches",
    mobile: true,
    page: "matches",
  },
  {
    link: "/tournaments",
    name: "Tournaments",
    mobile: true,
    page: "tournaments",
  },

  /*   {
    link: "/bookmakers",
    name: "Bookmakers",
    mobile: true,
    page: "bookmakers",
  }, */
];

export default function Navigation({
  mobile,
  launchSuggestionModal,
  handleSelectedTab,
  page,
  handleSetPage,
  subMenu,
  SetRefetchData,
  refetchData,
  locationFilter,
  SetLocationFilter,
  cryptoOnly,
  SetCryptoOnly,
  handleSearchTermChange,
  searchResults,
  isLoading,
  searchTerm,
  resetSearchTerm,
}) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [locationShow, setLocationShow] = useState(false);
  const [cryptoShow, setCryptoShow] = useState(false);
  const toogleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMobileOpen(open);
  };

  const handleLocationMouseOver = () => {
    setLocationShow(true);
  };

  const handleLocationMouseOut = () => {
    setLocationShow(false);
  };

  const handleCryptoMouseOver = () => {
    setCryptoShow(true);
  };

  const handleCryptoMouseOut = () => {
    setCryptoShow(false);
  };

  const handleLocationChange = (props) => {
    updateCookie("locationFilter", props);
    SetLocationFilter(props);
    SetRefetchData(!refetchData);
  };

  const handleCryptoChange = (props) => {
    updateCookie("cryptoOnly", props);
    SetCryptoOnly(props);
    SetRefetchData(!refetchData);
  };

  async function updateCookie(field, value) {
    await axios(
      `${process.env.REACT_APP_BE_URL}/api/cookies/update?field=${field}&value=${value}`,
      {
        withCredentials: true,
      }
    );
    //console.log(response);
  }

  const mobileMenu = (
    <div className="fixed top-15 left-0 z-40 w-full h-screen bg-headerBackground mob-menu overflow-hidden">
      <div className="py-3 border-t-2 border-b-2 border-white">
        {menuItems
          .filter((item, index) => item.mobile !== false)
          .map((element) => (
            <div
              onClick={toogleDrawer(false)}
              onKeyDown={toogleDrawer(false)}
              key={element.link}
            >
              <Link to={element.link} key={element.name}>
                <div className="block ">
                  <button className="py-2 px-5 text-white font-medium hover:text-red-600">
                    {element.name}
                  </button>
                </div>
              </Link>
            </div>
          ))}
        <button
          className="py-2 px-5 text-white font-medium"
          onClick={launchSuggestionModal}
        >
          Suggest Improvements
        </button>
      </div>
      <div>
        <div>
          <p className="text-sm text-white opacity-50 px-5 pt-2 pb-1">
            Settings
          </p>
        </div>
        <div className="flex justify-between">
          <div className="flex">
            <p className="text-white text-sm font-medium  pt-2  pl-5">
              Crypto bookmakers only
            </p>
            <span className="text-sm mt-2 text-white text-opacity-50">:</span>
          </div>
          <div className="mr-2 mt-2">
            <Switch
              checked={cryptoOnly}
              onChange={handleCryptoChange}
              className={`${
                cryptoOnly ? " bg-mainBackground" : " bg-elementBackground"
              }
          relative inline-flex h-[22px] w-[51px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${cryptoOnly ? "translate-x-7" : "translate-x-0"}
            pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full ${
              cryptoOnly ? "bg-green-500" : "bg-white"
            }  shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
        </div>
        <div className="pb-1 px-5">
          <span className="text-xs text-white opacity-50 1">
            Crypto filter shows only crypto friendly bookmakers
          </span>
        </div>
        <div className="flex justify-between">
          <div className="flex">
            <p className="text-white text-sm font-medium pt-2 pl-5">
              Location filtered odds
            </p>

            <span className="text-sm mt-2 text-white text-opacity-50">:</span>
          </div>
          <div className="mr-2 mt-2">
            <Switch
              checked={locationFilter}
              onChange={handleLocationChange}
              className={`${
                locationFilter ? " bg-mainBackground" : " bg-elementBackground"
              }
          relative inline-flex h-[22px] w-[51px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${
                  locationFilter ? "translate-x-7" : "translate-x-0"
                }
            pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full ${
              locationFilter ? "bg-green-500" : "bg-white"
            }  shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
        </div>
        <div className="pb-1 px-5">
          <span className="text-xs text-white opacity-50 1">
            Location filter removes bookmakers not allowed to operate in your
            juristiction
          </span>
        </div>
        <div className="mt-5 pb-1 px-5">
          <span className="text-sm text-white opacity-50 1">
            Trying to access bookmakers outside of your location, might require
            you to use a
          </span>
          <span className="pl-2 text-white font-bold">VPN</span>
        </div>
        <div className="mt-5 pb-1 px-5">
          <span className="text-sm text-white opacity-50 1">
            Want to advertise with us? {" -> "}
          </span>
          <Link to="/advertise">
            <span
              className="pl-2 text-red-500 font-bold"
              onClick={() => setMobileOpen(false)}
            >
              Advertise
            </span>
          </Link>
        </div>
      </div>
    </div>
  );

  //console.log(searchResults);

  return (
    <div>
      <div
        className={`${"flex"} justify-between items-center py-3 px-3 lg:py-4 lg:px-7 bg-headerBackground `}
      >
        <Link to="/">
          <div className="font-bold text-3xl lg:text-4xl flex">
            <img
              src={OALogo}
              alt="OddsArena logo"
              className="h-9 mr-2 lg:h-10 lg:mr-3"
            />
            <span className="text-white font-brand ">ODDS</span>
            <span className="text-red-600 font-brand ">ARENA</span>
          </div>
        </Link>
        <div className="hidden lg:flex items-center align-middle font-medium gap-10 text-white ">
          <div className={`h-8 w-80`}>
            <SearchBar
              handleSearchTermChange={handleSearchTermChange}
              isLoading={isLoading}
              searchTerm={searchTerm}
              resetSearchTerm={resetSearchTerm}
              mobile={mobile}
            />
          </div>
          {searchResults !== undefined && searchResults.success === true && (
            <div className="absolute h-full">
              <div className=" z-40 relative   w-[400px]  top-16 ">
                <SearchResults
                  data={searchResults.data}
                  mobile={mobile}
                  resetSearchTerm={resetSearchTerm}
                />
              </div>
            </div>
          )}
          {menuItems.map((element) => {
            return (
              <Link
                key={element.name}
                to={element.link}
                onClick={() => handleSetPage(element.page)}
              >
                <div className=" px-4 py-2 hover:text-red-600">
                  {element.name}
                </div>
              </Link>
            );
          })}
        </div>
        <div className="lg:hidden">
          {mobileOpen ? (
            <XIcon
              className="w-7 h-7 text-white cursor-pointer hover:text-red-600"
              onClick={toogleDrawer(false)}
            />
          ) : (
            <MenuIcon
              className="w-7 h-7 text-white cursor-pointer hover:text-red-600"
              onClick={toogleDrawer(true)}
            />
          )}
        </div>
      </div>

      {mobileOpen && mobileMenu}
      {mobile && !mobileOpen && (
        <div className="   w-full absolute">
          <SearchBar
            handleSearchTermChange={handleSearchTermChange}
            isLoading={isLoading}
            searchTerm={searchTerm}
            resetSearchTerm={resetSearchTerm}
            mobile={mobile}
          />
          {searchResults !== undefined && searchResults.success === true && (
            <div className={` z-40 relative  w-full  ${!mobile && "top-20"} `}>
              <SearchResults
                data={searchResults.data}
                mobile={mobile}
                resetSearchTerm={resetSearchTerm}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
