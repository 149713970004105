import React from "react";
import VsHeader from "./vsHeader";
import { Link } from "react-router-dom";

export default function SingleMatchHeader({ matchData, mobile }) {
  const link = (teamData) => {
    //console.log(data, data.teamData, games);
    if (matchData !== undefined && teamData !== undefined) {
      let gameLink = matchData.game[0].abbreviation
        .toLowerCase()
        .replace(/[:\s\-]/g, "");

      //console.log(game);

      let link = teamData.name;
      link = link.toLowerCase();
      link = link.replace(/\s/g, "-");
      link = link.replace(/\//g, "-");
      link += "-" + teamData.id;

      let returnLink = gameLink + "/" + link;

      return returnLink;
    }
    return;
  };

  const logo = (participant) => {
    let logoReturn;
    //console.log(participant);
    participant !== undefined &&
    participant.teamData !== undefined &&
    participant.teamData.images !== undefined &&
    participant.teamData.images !== null &&
    participant.teamData.images.length > 0
      ? (logoReturn = (
          <Link to={`/team/${link(participant.teamData)}`}>
            <div className="rounded-full  h-20 w-20 ">
              <img
                className="h-20 w-20 z-10"
                src={participant.teamData.images[0].url}
                alt={participant.teamData.name}
                title={participant.teamData.name}
              />
            </div>
          </Link>
        ))
      : (logoReturn = <div className="h-20 w-20  " />);

    return logoReturn;
  };

  const teamName = (participant) => {
    let returnObject;
    participant !== undefined &&
    participant.teamData !== undefined &&
    participant.teamData.name !== undefined
      ? (returnObject = (
          <Link to={`/team/${link(participant.teamData)}`}>
            <span className="">{participant.teamData.name}</span>
          </Link>
        ))
      : (returnObject = <span className="">TBD</span>);

    return returnObject;
  };

  //console.log(matchData);
  return (
    <>
      {matchData !== undefined && (
        <div className="">
          <div className="flex flex-col">
            <div className="bg-serieRight2 rounded-md shadow-md py-1 border border-white border-opacity-20">
              <div className="flex justify-between  text-sm md:text-md text-white font-bold">
                <div className="ml-3 mt-1 w-28   h-full mb-1 ">
                  <div className="flex-col border border-white hover:bg-hoverBackground hover:border-opacity-50 border-opacity-0 hover:rounded-md ">
                    <div className="flex justify-center ">
                      {logo(
                        matchData.participants.filter(
                          (part) => part.seed === 1
                        )[0]
                      )}
                    </div>
                    <div className="text-center mt-2">
                      {teamName(
                        matchData.participants.filter(
                          (part) => part.seed === 1
                        )[0]
                      )}
                    </div>
                    {matchData.lifecycle === "over-forfeited" &&
                      matchData.participants[0].winner && (
                        <div className="mt-2 text-center">
                          <span className="text-white font-normal">Winner</span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="h-8 w-32 mt-8 flex justify-center ">
                  <VsHeader matchData={matchData} />
                </div>
                <div className=" mr-3   w-28 mt-1  ">
                  <div className="flex-col md:flex-row border border-white hover:bg-hoverBackground hover:border-opacity-50 border-opacity-0 hover:rounded-md ">
                    <div className="flex justify-center ">
                      {logo(
                        matchData.participants.filter(
                          (part) => part.seed === 2
                        )[0]
                      )}
                    </div>
                    <div className=" text-center mt-2">
                      {teamName(
                        matchData.participants.filter(
                          (part) => part.seed === 2
                        )[0]
                      )}
                    </div>
                    {matchData.lifecycle === "over-forfeited" &&
                      matchData.participants[1].winner && (
                        <div className="mt-2 text-center">
                          <span className="text-white font-normal">Winner</span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
