import React, { useState, useEffect } from "react";
import GroupRow from "./GroupRow";
import SerieList from "../../Series/SerieList";
import Serie from "../../Series/SerieItem";

export default function GroupList({
  teams,
  standings,
  title,
  game,
  series,
  mobile,
  bookies = false,
}) {
  const [upcomingSeries, setUpcomingSeries] = useState([]);
  const [completedSeries, setCompletedSeries] = useState([]);
  const [tab, setTab] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const handleSelectedTab = (tab) => {
    setTab(tab);
  };
  const tabs = [{ name: "Upcoming Matches" }, { name: "Results" }];

  useEffect(() => {
    if (series !== undefined && series.length > 0) {
      let completedFilter = ["over", "over-draw", "over-forfeited"];
      let upcomingLiveFilter = ["upcoming"];
      let completed = series.filter((serie) =>
        completedFilter.includes(serie.lifecycle)
      );

      let upcoming = series.filter((serie) =>
        upcomingLiveFilter.includes(serie.lifecycle)
      );

      if (completed.length > 0) {
        setCompletedSeries(completed);
        setShowFilter(true);
      }
      //console.log("UPCOMING", upcoming.length);
      if (upcoming.length > 0) {
        setUpcomingSeries(upcoming);

        setShowFilter(true);
      }
    }
  }, [series]);

  useEffect(() => {
    if (showFilter) {
      if (tab === "Upcoming Matches") {
        setData(upcomingSeries);
      } else if (tab === "Results") {
        setData(completedSeries);
      }
    }

    if (tab === false) {
      if (upcomingSeries.length > 0) {
        setTab("Upcoming Matches");
      } else if (upcomingSeries.length === 0 && completedSeries.length > 0) {
        setTab("Results");
      }
    }
  }, [upcomingSeries, completedSeries, showFilter, tab, data]);

  useEffect(() => {
    if (showFilter) {
      if (tab === "Upcoming Matches") {
        setData(upcomingSeries);
      } else if (tab === "Results") {
        setData(completedSeries);
      }
    }
  }, []);

  //console.log(showFilter, data.length, data);

  const seriesResults = (
    <div className="flex h-10 w-full mt-4 mb-2">
      {upcomingSeries.length > 0 && (
        <div className={`${mobile && "w-1/2"}`}>
          <button
            className={`${
              mobile ? "text-xs px-2 w-full" : "w-48 px-3"
            }  h-10   pt-1 pb-1 rounded-l-md shadow-md uppercase ${
              completedSeries.length < 1 && "rounded-r-md"
            }  ${
              tab === "Upcoming Matches"
                ? "bg-serieRight2 text-white text-opacity-90 font-semibold"
                : "bg-elementBackground hover:text-red-600 text-gray-500 "
            } `}
            onClick={() => handleSelectedTab("Upcoming Matches")}
          >
            {tabs[0].name}
          </button>
        </div>
      )}
      {completedSeries.length > 0 && (
        <div className={`${mobile && "w-1/2"}`}>
          <button
            className={`${mobile ? "text-xs px-2 w-full" : "w-48 px-3"}
            h-10 shadow-md  pl-2 pb-1 pt-1  bg-elementBackground uppercase rounded-r-md ${
              upcomingSeries.length < 1 && "rounded-l-md"
            }  ${
              tab === "Results"
                ? "bg-serieRight2  text-white text-opacity-90 font-semibold"
                : "bg-elementBackground hover:text-red-600 text-gray-500"
            }`}
            onClick={() => handleSelectedTab("Results")}
          >
            {tabs[1].name}
          </button>
        </div>
      )}
    </div>
  );

  const sortedSeries = (
    <div className="flex flex-col  bg-mainBackground shadow-lg mt-2">
      {data !== undefined &&
        data.length > 0 &&
        data.map((serie, index) => (
          <Serie
            page={index}
            index={index}
            key={serie.id}
            data={serie}
            bookies={bookies}
            mobile={mobile}
            length={data.length}
            games={[game]}
            completedSeries={tab === "Results" ? true : false}
          />
        ))}
    </div>
  );
  //console.log("STANDINGS", standings);
  return (
    <div className="flex-col w-auto shadow-md mt-2">
      <div>
        {standings !== undefined && standings.length > 0 && (
          <div className="flex w-full text-sm text-white text-opacity-30  ">
            <div className="w-2/5 rounded-br-md ">
              <h2 className="pl-1 underline text-white text-opacity-70 text-base font-bold"></h2>
            </div>
            <div className="flex w-3/5 relative items-center underline bg-serieRight rounded-tl-sm rounded-tr-md">
              <div className="w-1/5 text-center">
                <span>Played</span>
              </div>
              <div className="w-1/5 text-center">
                <span>Wins</span>
              </div>
              <div className="w-1/5 text-center">
                <span>Draw</span>
              </div>
              <div className="w-1/5 text-center">
                <span>Losses</span>
              </div>
              <div className="w-1/5  text-right mr-1">
                <span>Points</span>
              </div>
            </div>
          </div>
        )}
        {standings !== undefined &&
          standings.length > 0 &&
          standings.map((sr, index) => (
            <GroupRow
              game={game}
              data={sr}
              index={index}
              team={teams.filter((team) => team.id === sr.roster.id)[0]}
              teams={teams}
              length={standings.length}
            />
          ))}
      </div>
      <div>{showFilter && seriesResults}</div>
      <div>{showFilter && sortedSeries}</div>
    </div>
  );
}
