import React from "react";
import LeftTeam from "./LeftTeam";
import Results from "./Results";
import RightTeam from "./RightTeam";
import Versus from "./Versus";

export default function RightSerieSection({
  lifecycle,
  data,
  bookies,
  index,
  length,
  completedSeries,
  open = false,
  team,
  games,
  page,
}) {
  const oddsPresent = () => {
    if (data.odds !== undefined && data.odds.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //console.log(data);

  return (
    <div className={`flex relative w-full items-center bg-serieLeft2 `}>
      {oddsPresent() === true ? (
        <LeftTeam
          data={data.participants[0]}
          fullData={data}
          odds={data.odds}
          bookies={bookies}
          completedSeries={completedSeries}
          lifecycle={data.lifecycle}
          team={team}
          games={games}
        />
      ) : (
        <LeftTeam
          fullData={data}
          data={data.participants[0]}
          completedSeries={completedSeries}
          lifecycle={data.lifecycle}
          team={team}
          games={games}
        />
      )}
      {completedSeries === false && <Versus />}
      {completedSeries !== false && (
        <Results
          results={data.participants}
          lifecycle={data.lifecycle}
          id={data.id}
          open={true}
        />
      )}

      {oddsPresent() === true ? (
        <RightTeam
          data={data.participants[1]}
          odds={data.odds}
          bookies={bookies}
          index={index}
          length={length}
          completedSeries={completedSeries}
          lifecycle={data.lifecycle}
          games={games}
        />
      ) : (
        <RightTeam
          data={data.participants[1]}
          index={index}
          length={length}
          completedSeries={completedSeries}
          lifecycle={data.lifecycle}
          games={games}
        />
      )}
    </div>
  );
}
