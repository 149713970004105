import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="flex flex-col-reverse sm:flex-row justify-between py-3 px-5 lg:py-4 lg:px-7 bg-headerBackground ">
      <div className="text-white text-sm text-opacity-50">
        Copyright © 2023 OddsArena. All Rights Reserved
      </div>
      <div className="flex sm:gap-6 flex-col sm:flex-row lg:mr-12 justify-evenly text-sm text-white ">
        <div className="hover:text-red-600">
          <Link to="/our-service">
            <button>Our Service</button>
          </Link>
        </div>
        <div className="hover:text-red-600">
          <Link to="/integrity">
            <button>Integrity</button>
          </Link>
        </div>
        <div className="hover:text-red-600">
          <Link to="/responsible-gambling">
            <button>Responsible Gambling</button>
          </Link>
        </div>
        <div className="hover:text-red-600">
          <Link to="/advertise">
            <button>Advertise</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
