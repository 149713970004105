import React from "react";
import teamLink from "../utils/teamLink";
import { Link } from "react-router-dom";

export default function TournamentParticipants({ tournamentData }) {
  // console.log(tournamentData);
  return (
    <div>
      {tournamentData.participants !== undefined &&
      tournamentData.participants.length > 0 ? (
        <>
          <div>
            <h2 className="text-white text-sm uppercase font-bold text-opacity-80  ml-1 underline mt-2">
              Tournament Participants
            </h2>
          </div>
          <div className="bg-serieRight shadow-md  rounded-md py-2 px-3 w-full  text-white text-opacity-80 max-w-none border border-white border-opacity-60">
            <div className="flex h-auto flex-wrap gap-2  justify-evenly">
              {tournamentData.participants.map((team, index) => (
                <div key={`${team.id}+${index}`}>
                  <Link to={`/team/${teamLink(team, tournamentData.game[0])}`}>
                    <div className="hover:bg-hoverBackground hover:border-white hover:border hover:rounded-md  flex h-18 w-18   items-center justify-center ">
                      <div className={``}>
                        {team.images !== null && team.images.length > 0 ? (
                          <img
                            className="h-16 w-16 z-10"
                            src={team.images[0].url || team.images[0].thumbnail}
                            alt={team.name}
                            title={team.name}
                          />
                        ) : (
                          <div
                            className="h-18 w-18 z-10 flex text-white rounded-md hover:text-red-500  justify-center font-semibold items-center border border-white  border-opacity-60"
                            alt={team.name}
                            title={team.name}
                          >
                            <p className="text-xs text-opacity-80">
                              {team.abbreviation}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
