import React from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { Link, Navigate } from "react-router-dom";

export default function SingleBracket({
  series,
  game,
  cols,
  lowerCols,
  title,
}) {
  //console.log(cols, lowerCols);
  //console.log("GAME:", game);
  const bracketRound = (serieRounds) => {
    return <>{serieRounds.map((serie) => bracketMatch(serie))}</>;
  };

  const link = (serie) => {
    let urlString = "";

    if (
      serie.game !== undefined &&
      serie.game[0] !== undefined &&
      serie.game[0].abbreviation !== undefined
    ) {
      let game = serie.game[0].abbreviation;
      game = game.replace(/[:\s\-]/g, "").toLowerCase();

      urlString += "/match/" + game + "/";
    }

    if (serie.participants[0] !== undefined) {
      urlString += serie.participants[0].teamData.name
        .replace(/ /g, "-")
        .toLowerCase();
    }
    if (serie.participants[1] !== undefined) {
      urlString +=
        "-vs-" +
        serie.participants[1].teamData.name.replace(/ /g, "-").toLowerCase();
    }

    if (serie.title !== undefined) {
      let tempString = serie.title;
      tempString = tempString.replace(/ - /g, "-");
      urlString += "-" + tempString.replace(/ /g, "-").toLowerCase();
    }

    if (
      serie.tournament !== undefined &&
      serie.tournament.length > 0 &&
      serie.tournament[0].title !== undefined
    ) {
      urlString +=
        "-" +
        serie.tournament[0].title
          .replace(/ /g, "-")
          .replace(/#/g, "")
          .toLowerCase();
    }

    if (serie.id !== undefined) {
      urlString += "-" + serie.id;
    }

    urlString = urlString.replace("#", "");

    return urlString;
  };

  const logo = (data) => {
    let logoReturn;

    data !== undefined &&
    data.teamData !== undefined &&
    data.teamData.images !== null &&
    data.teamData.images.length > 0 &&
    data.teamData.images[0].url !== ""
      ? (logoReturn = (
          <div className="rounded-full flex h-5 w-5   items-center justify-center">
            <img
              className="h-5 w-5"
              src={data.teamData.images[0].thumbnail}
              alt={data.teamData.name}
              title={data.teamData.name}
            />
          </div>
        ))
      : (logoReturn = <div className="h-5 w-6  rounded-r-lg" />);

    return logoReturn;
  };

  const matchTeam = (team = false) => {
    if (team === false) {
      return (
        <div className="flex justify-between">
          <div>{"TBD"}</div>
          <div></div>
        </div>
      );
    } else {
      return (
        <div className="flex w-full">
          <div>{logo(team)}</div>
          <div className="flex ml-1 justify-between w-full">
            <div className="ml-1">{team.teamData.name}</div>
            <div className="mr-1">{team.score}</div>
          </div>
        </div>
      );
    }
  };

  const bracketMatch = (match) => {
    //console.log(match);

    let homeTeam = match.participants.filter((part) => part.seed === 1);
    if (homeTeam.length > 0) {
      homeTeam = matchTeam(homeTeam[0]);
    } else {
      homeTeam = matchTeam();
    }
    let awayTeam = match.participants.filter((part) => part.seed === 2);
    if (awayTeam.length > 0) {
      awayTeam = matchTeam(awayTeam[0]);
    } else {
      awayTeam = matchTeam();
    }

    return (
      <Link to={link(match)}>
        <div
          className={`${
            match.lifecycle === "upcoming"
              ? "h-20"
              : match.lifecycle === "live"
              ? "h-20"
              : "h-16"
          } my-4  rounded-md text-xs flex-col hover:text-red-500 text-white border-2 border-white border-opacity-60 text-opacity-80 hover:cursor-pointer hover:border-2 hover:border-red-500`}
        >
          {match.lifecycle === "upcoming" && (
            <div className="flex justify-center">
              {`${new Date(match.start).toLocaleDateString([], {
                month: "short",
                day: "2-digit",
              })} - ${new Date(match.start).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })}`}
            </div>
          )}
          {match.lifecycle === "live" && (
            <div className="flex justify-center text-center">
              <p className=" text-red-500 text-xs font-bold animate-pulse">
                LIVE
              </p>
            </div>
          )}
          <div className="flex">
            <div className=" w-44 flex-col">
              <div
                className={`px-1 py-1 text-white ${
                  match.lifecycle !== "upcoming" &&
                  match.lifecycle !== "live" &&
                  "rounded-t-md"
                }  border-white border-opacity-60 border-b bg-serieLeft2 h-8`}
              >
                {homeTeam}
              </div>
              <div className=" px-1 py-1 text-white  rounded-b-md bg-serieRight2 h-7">
                {awayTeam}
              </div>
            </div>
            <div
              className={`w-7 h-auto border-l border-white border-opacity-60 flex justify-center align-middle bg-hoverBackground ${
                match.lifecycle !== "upcoming" &&
                match.lifecycle !== "live" &&
                "rounded-tr-md"
              }`}
            >
              <InformationCircleIcon className="w-5 " />
            </div>
          </div>
        </div>
      </Link>
    );
  };

  //nneed to figure out how many columns we will have

  return (
    <div className="flex-col mt-2">
      <div>
        <h3 className="text-white font-semibold ml-1 text-sm">{title}</h3>
      </div>
      <div className="flex">
        {cols.map((r, index) => {
          return (
            <div className="flex flex-col  w-48  mr-4 justify-around ">
              {bracketRound(
                series.filter((serie) => serie.bracket_position.col === r)
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
