import React from "react";
import { useForm } from "react-hook-form";

export default function AddFormMessage({ handleAddStream }) {
  const { register, handleSubmit, reset, formState } = useForm();
  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ stream: "" });
    }
  }, [formState, reset]);
  return (
    <div>
      <form onSubmit={handleSubmit(handleAddStream)}>
        <div className="rounded-md border-white border-opacity-40 border bg-serieRight2   mt-6    ">
          <div className="flex align-middle">
            <div>
              <input
                className="mx-1 mt-[6px] rounded-sm text-sm w-64 indent-px "
                {...register("stream")}
                placeholder="https://www.twitch.tv/example"
              />
            </div>
            <div className="w-full flex justify-end p-1">
              <button
                className="ml-5 bg-blue-500 hover:bg-serieLeft2 hover:text-red-500 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline text-xs"
                type="submit"
              >
                Add Stream
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
