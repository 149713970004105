import React, { useEffect } from "react";

export default function Advertise() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col w-full px-3 py-2 mt-4 bg-elementBackground rounded-md shadow-lg prose max-w-none">
      <div className=" text-white prose-headings:text-white prose-h3:text-opacity-50 prose-h4:text-opacity-50 text-opacity-60 prose-h2:text-opacity-60 px-2 md:px-5 py-3">
        <h1>Advertise with us</h1>
        <p>
          <span>
            If you are interested in advertising with us, don't hesitate to
            contact us on
          </span>{" "}
          <a
            href="mailto:advertise@oddsarena.gg?subject=Advertisement on Oddsarena.gg"
            className="text-white"
          >
            advertise@oddsarena.gg
          </a>
        </p>
        <p>
          We offer all traditional advertisement solutions for all devices.{" "}
        </p>
        <p>
          In general, we love challenges and we are passionate around technology
          and esports.
        </p>
        <p>
          Therefore, OddsArena will gladly work with you to develop our site to
          support your advertisement needs.
        </p>
      </div>
    </div>
  );
}
