import React from "react";
import DotaView from "./liveScores/dotaView";
import CsView from "./liveScores/csView";

export default function LiveScore({ data, coverage, game }) {
  //console.log(data.teams["home"].score, " - ", data.teams["away"].score);
  //console.log(data);
  //console.log(data);
  // console.log("GAME:", game.id);

  return (
    <>
      {game.id === 5 && data !== undefined && <CsView data={data} />}
      {game.id === 1 && data !== undefined && <DotaView data={data} />}
      {game.id === 2 && data !== undefined && <DotaView data={data} />}
    </>
  );
}
