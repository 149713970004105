import React from "react";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export default function MatchButton({ link }) {
  return (
    <div className="ml-2 w-14  bg-serieRight2 hover:bg-hoverBackground rounded-md mr-2 md:mr-1  ">
      <Link to={link}>
        <div className="flex justify-center align-middle items-center h-6 ">
          <ChevronDoubleRightIcon className="text-white h-5 w-5 hover:text-red-500" />
        </div>
      </Link>
    </div>
  );
}
