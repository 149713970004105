import React, { useEffect, useState } from "react";
import SingleBracket from "./SingleBracket";

export default function Brackets({ game, title, series }) {
  const [upperSeries, setUpperSeries] = useState(null);
  const [lowerSeries, setLowerSeries] = useState(null);
  const [finalSeries, setFinalSeries] = useState(null);
  const [upperCols, setUpperCols] = useState(null);
  const [lowerCols, setLowerCols] = useState(null);
  const [finalCols, setFinalCols] = useState(null);
  useEffect(() => {
    //// console.log(detailed_match.past_rounds.length);
    //lets figure out the logic here...
    if (series !== undefined && series.length > 0) {
      /*       let uniqueBrackets = series.map((dp) => dp.bracket_position.part);
      let uniqueTest = [...new Set(uniqueBrackets)];
      console.log("uniqueTest:", uniqueTest); */
      series = series.filter((serie) => serie.lifecycle !== "deleted");
      let data = series.filter((serie) => serie.bracket_position.part === "UB");

      if (data.length > 0) {
        //lets see how many columns we have in the data..
        let allColValues = data.map((dp) => dp.bracket_position.col);
        // console.log(allColValues);
        let unique = [...new Set(allColValues)];
        unique.sort(function (a, b) {
          return b - a;
        });
        setUpperCols(unique);
        //console.log("COLS: ", unique);
      }

      setUpperSeries(data);
      data = series.filter((serie) => serie.bracket_position.part === "LB");
      if (data.length > 0) {
        //lets see how many columns we have in the data..
        let allColValues = data.map((dp) => dp.bracket_position.col);
        // console.log(allColValues);
        let unique = [...new Set(allColValues)];
        unique.sort(function (a, b) {
          return b - a;
        });
        setLowerCols(unique);
        //console.log("LowerCOLS: ", unique);
        setLowerSeries(data);
      }

      data = series.filter((serie) => serie.bracket_position.part === "GF");
      if (data.length > 0) {
        //lets see how many columns we have in the data..
        let allColValues = data.map((dp) => dp.bracket_position.col);
        // console.log(allColValues);
        let unique = [...new Set(allColValues)];
        unique.sort(function (a, b) {
          return b - a;
        });
        setFinalCols(unique);
        //console.log("LowerCOLS: ", unique);
        setFinalSeries(data);
      }
    }
  }, [series]);

  return (
    <div className=" ml-1 w-full overflow-x-auto">
      {finalSeries !== null &&
        finalSeries.length > 0 &&
        finalCols !== null &&
        finalCols.length > 0 && (
          <SingleBracket
            game={game}
            series={finalSeries}
            cols={finalCols}
            lowerCols={lowerCols}
            title={"Final"}
          />
        )}
      {upperSeries !== null &&
        upperSeries.length > 0 &&
        upperCols !== null &&
        upperCols.length > 0 && (
          <SingleBracket
            game={game}
            series={upperSeries}
            cols={upperCols}
            lowerCols={lowerCols}
            title={"Upper Bracket"}
          />
        )}
      {lowerSeries !== null &&
        lowerSeries.length > 0 &&
        lowerCols !== null &&
        lowerCols.length > 0 && (
          <SingleBracket
            game={game}
            series={lowerSeries}
            cols={lowerCols}
            title={"Lower Bracket"}
          />
        )}
    </div>
  );
}
