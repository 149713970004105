import React, { useState, useEffect } from "react";
import TournamentSubStage from "./TournamentSubStage";
import SubstageDropdown from "./SubstageDropdown";
import {
  ChevronDoubleUpIcon,
  ChevronDoubleDownIcon,
} from "@heroicons/react/solid";
export default function TournamentStage({
  data,
  mobile,
  game,
  teams,
  series,
  bookies,
  index,
}) {
  //console.log("ST", data, series.length);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectItem, setSelectedItem] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [stageStart, setStageStart] = useState(-1);
  const [stageEnd, setStageEnd] = useState(-1);
  const [amountOfDays, setAmountOfDays] = useState(-1);
  //console.log(selectItem);
  useEffect(() => {
    if (
      data !== undefined &&
      data.substages !== undefined &&
      data.substages.length > 0
    ) {
      let stages = data.substages.map((st) => {
        let returnObject = {
          id: st.id,
          label: st.title,
        };

        return returnObject;
      });
      setDropdownItems([...stages]);
      setShowDropdown(true);
      if (data.substages.length === 1) {
        handleSetSelectedItem({
          id: data.substages[0].id,
          label: data.substages[0].label,
        });
      }
    }
  }, [data.substages]);

  // console.log("SERIES LENGTH: ", series.length);
  useEffect(() => {
    //IF START DATE IS IN THE FUTURE, COLLAPSE AND display start time of stage
    let today = new Date();
    let amountOfDays;

    let dates = data.substages.map((st) => new Date(st.start));
    let mnDate = dates.reduce(function (a, b) {
      return a < b ? a : b;
    });

    //console.log("DATES:", dates);

    let serieEnds = [];
    let serieStarts = [];
    data.substages.forEach((st) => {
      //console.log(st.id);
      let localSeries;
      //console.log(series.length);
      localSeries = series.filter((serie) => serie.substage.id === st.id);
      //console.log(localSeries);
      localSeries.forEach((ls) => {
        if (ls.end !== undefined) {
          serieEnds.push(new Date(ls.end));
        }
        if (ls.start !== undefined) {
          serieStarts.push(new Date(ls.start));
        }
      });
    });
    let maxDate = 0;
    if (serieStarts.length > 1) {
      maxDate = serieStarts.reduce(function (a, b) {
        return a > b ? a : b;
      });
    }

    //console.log("MD ", maxDate);
    //console.log("Stage ID:", data.id, " SeriesDates: ", serieStarts);

    if (mnDate > today) {
      let diff = mnDate - today;
      amountOfDays = Math.floor(diff / (1000 * 3600 * 24));
      //console.log(amountOfDays);
      setAmountOfDays(amountOfDays);
      setExpanded(false);
      // console.log("ARE WE SEEING THIS?");
    }
    if (mnDate < today && maxDate > today) {
      // ongoing
      setExpanded(true);
      setStageStart(1);
      let diff = maxDate - today;
      amountOfDays = Math.floor(diff / (1000 * 3600 * 24));
      setStageEnd(amountOfDays);
    }
    if (mnDate < today && maxDate < today) {
      setStageEnd(1);
    }
  }, [data, series]);

  function handleSetSelectedItem(data) {
    //console.log(e.target.value);
    setSelectedItem(data.id);
  }

  function displayStageStatus() {
    if (amountOfDays !== -1) return `(in ${amountOfDays} days)`;
    else if (stageStart === 1) return `(Ongoing)`;
    else if (stageEnd === 1) return `(Completed)`;
  }

  //console.log("INDEX:", index);
  return (
    <>
      <div
        className={`bg-serieRight h-10 ${
          index !== 0 && "mt-1"
        } py-1 text-base flex justify-between w-full hover:text-red-500 hover:bg-hoverBackground  hover:cursor-pointer ${
          index === 0 ? "rounded-md" : "rounded-md"
        }`}
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex w-10 h-8 items-center"></div>
        <div className={``}>
          <p className={`text-white text-opacity-80 font-bold  leading-none `}>
            {data.title}
          </p>

          <p className=" text-gray-400 text-xs text-center">
            {displayStageStatus()}
          </p>
        </div>
        <div className="w-10 h-8 flex justify-center items-center">
          {expanded ? (
            <ChevronDoubleUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDoubleDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      {showDropdown && expanded && data.substages.length > 1 && (
        <SubstageDropdown
          data={dropdownItems}
          handleSetSelectedItem={handleSetSelectedItem}
          selectItem={selectItem}
        />
      )}
      <div className="flex-row">
        {data !== undefined &&
          data.substages.length > 0 &&
          selectItem !== 0 &&
          expanded &&
          data.substages
            .filter((st) => st.id == selectItem)
            .map((substage) => (
              <div key={substage.id}>
                <TournamentSubStage
                  bookies={bookies}
                  data={substage}
                  mobile={mobile}
                  teams={teams}
                  game={game}
                  series={series.filter(
                    (serie) => serie.substage.id === selectItem
                  )}
                />
              </div>
            ))}
      </div>
    </>
  );
}
