import teamLink from "./teamLink";
import { Link } from "react-router-dom";

export default function logo(participant, size, game, noReturn = false) {
  let logoReturn;
  //console.log(participant);
  participant !== undefined &&
  participant.teamData !== undefined &&
  participant.teamData.images !== undefined &&
  participant.teamData.images !== null &&
  participant.teamData.images.length > 0
    ? (logoReturn = (
        <Link to={`/team/${teamLink(participant.teamData, game[0])}`}>
          <div className={`h-${size} w-${size} ml-1`}>
            <img
              className={`h-${size} w-${size} z-10`}
              src={participant.teamData.images[0].url}
              alt={participant.teamData.name}
              title={participant.teamData.name}
            />
          </div>
        </Link>
      ))
    : (logoReturn = (
        <div
          className={`flex h-${size} w-${size} ml-1  items-center align-middle   bg-serieRight2 hover:bg-hoverBackground`}
        >
          <span>{!noReturn ? participant.teamData.name : ""}</span>
        </div>
      ));

  return logoReturn;
}
