import React from "react";
import Odds from "./Odds";

export default function MobileOdds({ odds, bookies, style = false }) {
  return (
    <div className="flex justify-between align-middle text-white items-center w-full px-1  bg-elementBackground rounded-tr-md">
      <Odds
        odds={odds}
        bookies={bookies}
        home={true}
        mobile={true}
        style={style}
      />
      <Odds odds={odds} bookies={bookies} mobile={true} style={style} />
    </div>
  );
}
