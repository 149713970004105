import React from "react";

export default function dotaView({ data }) {
  /*   console.log("DOTAVIEW");
  console.log(data); */
  return (
    <div className="flex  justify-between h-10 text-2xl">
      <div className="mr-3 flex align-middle items-center">
        <span
          className={`mb-1 text-gray-400 text-opacity-70 font-normal
          }`}
        >
          {data !== undefined &&
            data.detailed_match !== undefined &&
            data.detailed_match.last_state !== undefined &&
            data.detailed_match.last_state.teams !== undefined &&
            data.detailed_match.last_state.teams["home"] !== undefined &&
            data.detailed_match.last_state.teams["home"].score !== undefined &&
            data.detailed_match.last_state.teams["home"].score}
        </span>
      </div>
      <div className="ml-3 flex align-middle items-center">
        <span
          className={`mb-1 text-gray-400 text-opacity-70 font-normal
          } `}
        >
          {data !== undefined &&
            data.detailed_match !== undefined &&
            data.detailed_match.last_state !== undefined &&
            data.detailed_match.last_state.teams !== undefined &&
            data.detailed_match.last_state.teams["away"] !== undefined &&
            data.detailed_match.last_state.teams["away"].score !== undefined &&
            data.detailed_match.last_state.teams["away"].score}
        </span>
      </div>
    </div>
  );
}
