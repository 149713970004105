import React from "react";

export default function SEOFooter({ game }) {
  const lol = (
    <div>
      <h4>League of Legends</h4>
      <p>
        League of Legends, also known as LoL, is one of the most popular esport
        games in the world. This multiplayer online battle arena (MOBA) game has
        captured the hearts of millions of fans globally. If you're one of those
        fans, then you know how important it is to stay up-to-date with the
        latest live streams, game schedules, results, and scores of your
        favorite teams and tournaments.
      </p>
      <h4>OddsArena</h4>
      <p>
        That's where OddsArena.gg comes in! At OddsArena.gg, we provide an
        easy-to-use function to find live scores and streams for League of
        Legends matches, so you can catch all the action as it happens. We also
        offer the latest results and for all of your League of Legends matches,
        so you can stay on top of your game. Our game schedule section allows
        you to see the upcoming matches and plan your viewing experience
        accordingly.
      </p>
      <h4>Odds comparison</h4>
      <p>
        But that's not all! If you're interested in betting on League of Legends
        matches, we provide an odds comparison tool for each match. This tool
        allows you to compare odds from different bookmakers and find the best
        value for your bets. We also keep you updated on the latest League of
        Legends betting trends, so you can make informed decisions when placing
        your bets.
      </p>
      <h4 className="font-bold">Conclusion</h4>
      <p>
        At OddsArena.gg, we provide everything you need to stay up-to-date with
        your favorite esport game, League of Legends. Our website is the perfect
        destination for any League of Legends fan who wants to keep themselves
        updated on the latest tournaments, teams, live scores, live streams,
        upcoming game schedules, and results. In conclusion, if you're a League
        of Legends fan looking for a one-stop-shop for live streams, game
        schedules, results, and betting odds, OddsArena.gg is the perfect
        destination for you. Visit our website today to keep yourself updated on
        your favorite League of Legends tournament, team, live matches, upcoming
        game schedules, or results!
      </p>
    </div>
  );
  const dota = (
    <div>
      <h4>Dota 2</h4>
      <p>
        Dota 2 is a popular esport game that has taken the gaming world by
        storm. This multiplayer online battle arena (MOBA) game has millions of
        fans from all over the world. If you're one of those fans, then you know
        how important it is to stay up-to-date with the latest live sccores,
        streams, game schedules and results of your favorite teams and
        tournaments. That's where OddsArena.gg comes in!
      </p>
      <h4>OddsArena</h4>
      <p>
        At OddsArena.gg, we provide an easy-to-use function to find live streams
        for Dota 2 matches, so you can catch all the action as it happens. We
        also offer the latest results and scores for all of your Dota 2 matches,
        so you can stay on top of your game. Our game schedule section allows
        you to see the upcoming matches and plan your viewing experience
        accordingly.
      </p>
      <h4>Odds comparison</h4>
      <p>
        But that's not all! If you're interested in betting on Dota 2 matches,
        we provide an odds comparison tool for each match. This tool allows you
        to compare odds from different bookmakers and find the best value for
        your bets. We also keep you updated on the latest Dota 2 betting trends,
        so you can make informed decisions when placing your bets.
      </p>
      <h4 className="font-bold">Conclusion</h4>
      <p>
        At OddsArena.gg, we provide everything you need to stay up-to-date with
        your favorite esport game, Dota 2. Our website is the perfect
        destination for any Dota 2 fan who wants to keep themselves updated on
        the latest tournaments, teams, live matches, upcoming game schedules,
        and results. In conclusion, if you're a Dota 2 fan looking for a
        one-stop-shop for live streams, game schedules, results, and betting
        odds, OddsArena.gg is the perfect destination for you. Visit our website
        today to keep yourself updated on your favorite Dota 2 tournament, team,
        live matches, upcoming game schedules, or results!
      </p>
    </div>
  );
  const csgo = (
    <div>
      <h4>Counter-Strike: Global Offensive (CS:GO)</h4>
      <p>
        Counter-Strike: Global Offensive (CS:GO) is a first-person shooter game
        that has been dominating the world of esports since its release in 2012.
        CS:GO has become one of the most popular games in the world, with
        millions of fans and players all over the globe. If you're a CS:GO fan,
        then you know how important it is to stay up-to-date with the latest
        live streams, game schedules, results, and scores of your favorite teams
        and tournaments. That's where OddsArena.gg comes in!
      </p>
      <h4>OddsArena</h4>
      <p>
        At OddsArena.gg, we provide an easy-to-use function to find live streams
        for CS:GO matches, so you can catch all the action as it happens. We
        also offer the latest results and scores for all of your CS:GO matches,
        so you can stay on top of your game. Our game schedule section allows
        you to see the upcoming matches and plan your viewing experience
        accordingly.
      </p>
      <h4>Odds comparison</h4>
      <p>
        But that's not all! If you're interested in betting on CS:GO matches, we
        provide an odds comparison tool for each match. This tool allows you to
        compare odds from different bookmakers and find the best value for your
        bets. We also keep you updated on the latest CS:GO betting trends, so
        you can make informed decisions when placing your bets.
      </p>
      <h4 className="font-bold">Conclusion</h4>
      <p>
        At OddsArena.gg, we provide everything you need to stay up-to-date with
        your favorite esport game, CS:GO. Our website is the perfect destination
        for any CS:GO fan who wants to keep themselves updated on the latest
        tournaments, teams, live matches, upcoming game schedules, and results.
        In conclusion, if you're a CS:GO fan looking for a one-stop-shop for
        live streams, game schedules, results, and betting odds, OddsArena.gg is
        the perfect destination for you. Visit our website today to keep
        yourself updated on your favorite CS:GO tournament, team, live matches,
        upcoming game schedules, or results!
      </p>
    </div>
  );
  const generic = (
    <div>
      <h4>OddsArena</h4>
      <p>
        Welcome to OddsArena.gg, your ultimate platform for all things esports!
        Whether you're a seasoned esports fan or a casual viewer, our platform
        has everything you need to stay up-to-date on the latest esports matches
        and events.
      </p>
      <p>
        Our platform provides comprehensive coverage of live streams for Dota2,
        League of Legends, and CS-GO matches. With just a few clicks, you can
        find and watch the live streams of your favorite esports matches, all in
        one place.
      </p>
      <p>
        In addition to live streams, OddsArena.gg offers a comprehensive
        schedule of upcoming games for Dota2, League of Legends, and CS-GO.
        Never miss out on a match again with our easy-to-use calendar feature.
      </p>
      <p>
        But that's not all - we also provide real-time results and scores for
        all of your favorite games. Whether you're looking to follow a specific
        team or just want to stay up-to-date on the latest standings,
        OddsArena.gg has got you covered.
      </p>
      <p>
        And for those looking to place bets on esports matches, our platform
        automatically finds the best odds from a variety of bookmakers. This
        means that you can easily compare odds and place bets with confidence,
        all from within our platform.
      </p>
      <p>
        At OddsArena.gg, we're dedicated to providing the best possible esports
        experience for our users. Whether you're looking to watch live streams,
        stay up-to-date on the latest events, or place bets on your favorite
        matches, our platform has everything you need. Welcome to OddsArena.gg!
      </p>
    </div>
  );

  return (
    <div className="mt-20 text-white text-xs   prose-headings:text-white  prose  prose-h3:text-opacity-50 prose-h4:text-opacity-50 text-opacity-60  prose-h2:text-opacity-60 bg-elementBackground rounded-md shadow-md px-2 md:px-5  max-w-none">
      {game === undefined && generic}
      {game === "lol" && lol}
      {game === "dota2" && dota}
      {game === "csgo" && csgo}
    </div>
  );
}
