import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import tournamentLink from "../utils/tournamentLink";

export default function TournamentItem({
  tournament,
  length,
  index,
  mobile = false,
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (tournament.start !== undefined) {
      let date = new Date(tournament.start);
      let startString = date.getDate() + " " + months[date.getMonth()];

      setStartDate(startString);
    }

    if (tournament.end !== undefined) {
      if (tournament.end === null) {
        setEndDate("");
      } else {
        let date = new Date(tournament.end);
        let endString = date.getDate() + " " + months[date.getMonth()];

        setEndDate(endString);
      }
    }
  }, [tournament]);

  const tournamentTitle = () => {
    if (tournament.title.length > 40) {
      return tournament.short_title;
    } else {
      return tournament.title;
    }
  };

  const tournamentLocation = () => {
    if (tournament.location.host.country !== null) {
      let stringLength = tournament.location.host.country.name.length;
      //console.log(stringLength);
      if (stringLength > 13) {
        return tournament.location.host.country.abbreviation;
      } else {
        return tournament.location.host.country.name;
      }
    } else {
      return "Online";
    }
  };

  const tournamentPrize = () => {
    if (
      tournament.string_prize_pool.total === "TBD" ||
      tournament.string_prize_pool.total === "null" ||
      tournament.string_prize_pool.total === "TBA"
    ) {
      return null;
    } else {
      return tournament.string_prize_pool.total;
    }
  };

  return (
    <Link
      to={`/tournament/${tournamentLink(tournament, tournament.game[0], true)}`}
    >
      <div
        className={`mt-[6px] h-12 border border-white border-opacity-60 rounded-md hover:border-red-500 hover:border-2 bg-serieLeft2`}
      >
        <div className="flex align-middle h-full items-center px-3 w-full justify-between text-white text-opacity-80 text-xs sm:text-sm text-center">
          <div className="flex h-full">
            <div className="h-full flex align-middle items-center border-r border-white border-opacity-20 pr-3">
              <img
                alt={tournament.game[0].title}
                src={tournament.game[0].defaultThumbnail}
                className="w-7 h-7 rounded-sm"
              />
            </div>
            <div className="h-full flex align-middle items-center pl-3">
              <span className=" w-full">
                {tournament.images !== null &&
                tournament.images[0].thumbnail !== undefined ? (
                  <img
                    src={tournament.images[0].thumbnail}
                    className="w-12 h-7 rounded-sm"
                    alt={`${tournament.title} logo`}
                  />
                ) : null}
              </span>
            </div>
            <div className="h-full flex align-middle items-center pl-3">
              <span className="hover:text-red-600 w-full text-white font-semibold text-opacity-50">
                {tournamentTitle()}
              </span>
            </div>
          </div>
          <div className="flex text-xs text-white h-12">
            <div className="hidden md:flex w-32 h-full">
              <div className="flex h-full  ">
                <div
                  className={`flex justify-end h-full md:pl-2 md:pr-3 w-28  align-middle  items-center text-xs  text-white text-opacity-80 `}
                >
                  <span className=" ">{tournamentLocation()}</span>
                </div>
              </div>
            </div>
            <div className="hidden md:flex h-full w-32 ">
              <div className="flex h-full border-l border-white border-opacity-20   ">
                <div
                  className={`flex justify-end h-full md:pl-2  w-20  align-middle  items-center text-xs text-white text-opacity-80 `}
                >
                  <span className=" ">{tournamentPrize()}</span>
                </div>
              </div>
            </div>
            <div className="hidden md:flex w-32 h-full">
              <div className="flex h-full border-l border-white border-opacity-20 ">
                <div
                  className={`flex  justify-center h-full px-2  w-32  align-middle  items-center text-xs  text-white text-opacity-80 `}
                >
                  <span className=" ">{`${startDate} - ${endDate}`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
