import React from "react";
import MobileSideMenu from "./MobileSideMenu";
import SideMenu from "./SideMenu";

export default function WhichSideBar({
  windowSize,
  menuData,
  handleSelectedTab,
  page,
  setFilterGame,
  filteredGame,
}) {
  return (
    <>
      {windowSize.width < 1280 ? (
        <div className="flex mt-8">
          {menuData !== undefined && menuData.length > 0 && (
            <MobileSideMenu
              menuData={menuData}
              handleSelectedTab={handleSelectedTab}
              page={page}
              setFilterGame={setFilterGame}
              filteredGame={filteredGame}
            />
          )}
        </div>
      ) : (
        <div className="flex flex-col  w-72">
          {menuData !== undefined && menuData.length > 0 && (
            <SideMenu
              menuData={menuData}
              handleSelectedTab={handleSelectedTab}
              page={page}
              setFilterGame={setFilterGame}
              filteredGame={filteredGame}
            />
          )}
        </div>
      )}
    </>
  );
}
