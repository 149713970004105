import React from "react";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export default function MatchButton({ link, index, page = false }) {
  //console.log(page);
  return (
    <div
      className={`w-14 bg-elementBackground rounded-r-md hover:bg-hoverBackground ${
        index === 0 && "rounded-tr-md"
      }`}
    >
      <Link to={link}>
        <div className="flex justify-center align-middle items-center h-16 hover:text-red-500 ">
          <ChevronDoubleRightIcon className="text-white h-7 w-7 hover:text-red-500" />
        </div>
      </Link>
    </div>
  );
}
