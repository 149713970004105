import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import TwitchPlayer from "../TwitchPlayer";
import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet-async";
import AddFormMessage from "./AddFormMessage";
import { useNavigate } from "react-router-dom";
import delay from "../../utils/Delay";

export default function Arena({ games, mobile }) {
  //console.log(mobile);
  const [numStreams, setNumStreams] = useState(0);
  let navigate = useNavigate();

  const [streams, setStreams] = useState([]);

  console.log(streams);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function handleStreams(serie, action) {
    if (serie.id !== undefined && action !== undefined) {
      if (action === true) {
        //DELETE HERE
        setStreams((current) => current.filter((s) => s.id !== serie.id));
        setNumStreams((prev) => prev - 1);
        //need to figure out the index and splice it
      } else if (action === false) {
        //ADD HERE
        //console.log("HERE2?");
        setStreams([...streams, serie]);
        setNumStreams((prev) => prev + 1);
      }
    }
  }

  useEffect(() => {
    if (mobile) {
      redirectMobile();
    }
  }, [mobile]);

  async function redirectMobile() {
    await delay(10000);
    navigate("/");
  }

  const displayRedirect = (
    <div className="flex w-full justify-center  ">
      <div className="flex w-3/4 h-auto mt-16 bg-elementBackground rounded-md shadow-md">
        <div className="p-3 w-full flex-col border-white border-opacity-70 border rounded-md">
          <div className="">
            <p className="text-white text-opacity-80 font-bold">
              The Arena not supported on mobile
            </p>
            <p className="text-white text-opacity-80 text-sm mt-2 ">
              To utilize the arena, browse to this page using your desktop
              browser. This page will automatically redirect you back to the
              homepage within 10 seconds.
            </p>
          </div>
        </div>
      </div>
      ;
    </div>
  );

  function handleAddStream(data) {
    //lets start with twitch here
    let username = data.stream.split("twitch.tv/")[1];
    //console.log(username);
    let id = uuidv4();
    //calculate display / username

    let primaryCaster = [
      {
        additional_info: {
          display_name: username,
          username: username,
          url: data.stream,
          platform: { id: 1 },
        },
      },
    ];

    let streamObject = {
      id: id,
      user_added: true,
      primaryCaster: primaryCaster,
    };

    handleStreams(streamObject, false);

    //let let casterObject
  }

  //console.log(streams);
  const closeTwitchPlayer = (id) => {
    handleStreams({ id: id }, true);
  };

  const displayStreams = () => {
    //console.log("TRYING TO DISPLAY:", streams, streams.length);

    //calculate rows

    let rows = 1;
    if (streams.length >= 3) {
      rows = 2;
    }
    let firstRowStreams = [];
    let secondRowStreams = [];

    if (rows === 1) {
      firstRowStreams = streams;
    } else {
      if (streams.length === 3) {
        firstRowStreams = streams.slice(0, 2);
        secondRowStreams = streams.slice(2);
      } else if (streams.length === 4) {
        firstRowStreams = streams.slice(0, 2);

        secondRowStreams = streams.slice(2);
      } else if (streams.length >= 5) {
        firstRowStreams = streams.slice(0, 3);

        secondRowStreams = streams.slice(3);
      }
    }

    console.log(secondRowStreams);

    return (
      <div className={`w-full ${rows === 1 ? "flex" : "flex-col"}`}>
        {streams.length > 0 && displayRow(firstRowStreams)}
        {streams.length > 2 && displayRow(secondRowStreams)}
      </div>
    );
  };

  function returnWidth(data) {
    if (streams.length === 1 && data.length === 1) {
      return "w-[85%]";
    } else if (streams.length === 2 && data.length === 2) {
      return "w-1/2";
    } else if (streams.length === 3 && data.length === 1) {
      return "w-1/2";
    } else if (streams.length === 3 && data.length === 2) {
      return "w-1/2";
    } else if (streams.length === 4 && data.length === 2) {
      return "w-1/2";
    } else if (streams.length === 5 && data.length === 3) {
      return "w-1/3";
    } else if (streams.length === 5 && data.length === 2) {
      return "w-1/3";
    } else if (streams.length === 6 && data.length === 3) {
      return "w-1/3";
    }
  }

  function displayRow(data) {
    console.log(streams.length, data.length);
    return (
      <div className="w-full flex justify-center">
        {data.length > 0 &&
          data.map((stream, index) => (
            <div className={`${returnWidth(data)}`}>
              <TwitchPlayer
                serie={stream}
                closeTwitchPlayer={closeTwitchPlayer}
                style={"bg-serieRight2"}
                mobile={false}
                arena={true}
                handleStreams={handleStreams}
              />
            </div>
          ))}
      </div>
    );
  }

  function displayMessage() {
    return (
      <div className="flex w-full justify-center  ">
        <div className="flex w-1/4 h-auto mt-16 bg-serieLeft2 rounded-md shadow-md">
          <div className="p-3 w-full flex-col border-white border-opacity-70 border rounded-md">
            <div className="">
              <p className="text-white text-opacity-80 font-bold">
                No stream is showing
              </p>
              <p className="text-white text-opacity-80 text-sm mt-2 ">
                Get started by pressing a game in the menu or adding a stream
                below in the textbox. Currently watching up to six streams
                simultaneously is supported.
              </p>
            </div>
            <div>
              <AddFormMessage handleAddStream={handleAddStream} />
            </div>
          </div>
        </div>
        ;
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>OddsArena | The Arena</title>
        <meta
          name="description"
          content="OddsArena | The Arena, where you can watch as many matches simultaneously as your desktop can handle"
        ></meta>
      </Helmet>
      {!mobile ? (
        <>
          <div className=" bg-mainImage bg-cover bg-no-repeat h-screen bg-blend-darken bg-mainBackground  ">
            <div className="flex z-10">
              {streams.length > 0 && displayStreams()}
              {streams.length === 0 && displayMessage()}
            </div>
          </div>
          <div className="top-20 bottom-0 z-50 fixed">
            <Menu
              games={games}
              streams={streams}
              handleStreams={handleStreams}
              handleAddStream={handleAddStream}
            />
          </div>
        </>
      ) : (
        <div>{displayRedirect}</div>
      )}
    </>
  );
}
