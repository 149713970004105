import React, { useState, useEffect } from "react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/outline";
import { useQuery } from "react-query";
import MenuFilter from "./MenuFilter";
import LiveSerie from "./LiveSerie";
import AddForm from "./AddForm";
import UserAddedStream from "./UserAddedStream";
export default function Menu({
  games,
  streams,
  handleStreams,
  handleAddStream,
}) {
  const [show, setShow] = useState(true);
  const [selectedGame, setSelectedGame] = useState(0);
  const [filteredData, setFilteredData] = useState([]);

  const toggleShow = () => {
    setShow(!show);
  };

  function handleGameFilter(id) {
    //console.log("ID: ", id);
    setSelectedGame(id);
  }

  const fetchLive = async () => {
    let data;
    let game;
    //console.log(filter);

    if (game !== undefined) {
      if (games !== undefined) {
        let singleGame = games.filter((sg) => sg.gameMenu === game)[0];

        data = await fetch(
          `${process.env.REACT_APP_BE_URL}/api/series/live?&game=${singleGame.id}`,
          { credentials: "same-origin" }
        );
      }
    } else {
      data = await fetch(`${process.env.REACT_APP_BE_URL}/api/series/live`, {
        credentials: "same-origin",
      });
    }

    if (data.status !== 204) {
      data = await data.json();
      //console.log(data);
    }
    return data;
  };

  const { data: liveData, status: liveStatus } = useQuery(
    ["liveSeries"],
    fetchLive,
    {
      refetchOnWindowFocus: true,
      refetchInterval: 30000,
    }
  );

  useEffect(() => {
    if (liveData !== undefined) {
      let tempData = liveData.data.filter(
        (ld) => ld.primaryCaster !== undefined
      );
      if (tempData.length > 0) {
        //lets duplicate some data to errro test
        /*         let item = tempData[0];
        let testArray = [];
        for (let i = 0; i < 20; i++) {
          testArray.push(item);
        } */
        setFilteredData(tempData);
      }

      //check if any streams has finished and if it's still running display it on the menu as user_added
    }
  }, [liveData]);

  console.log(streams);

  return (
    <div
      className=" flex 
   border-t border-b border-r border-white border-opacity-60 rounded-r-md  "
    >
      {show ? (
        <div
          className=" w-60 flex-col flex-1  bg-serieRight2 rounded-r-md "
          onMouseLeave={toggleShow}
        >
          {/*           <div>
            {}             {games !== undefined && games.length > 0 && (
              <MenuFilter
                menuData={games}
                handleGameFilter={handleGameFilter}
                selectedGame={selectedGame}
              />
            )} 
          </div> */}
          <div className="flex-col h-auto">
            <div className="p-1">
              <p className="pl-1 text-sm text-white font-bold">
                Currently Live Series
              </p>
              <p className="pl-1 text-white text-xs text-opacity-40 italic">
                This list is only displaying games, where oddsarena.gg has
                identified streams. If a game is missing, and you have a stream
                for it, you can always add it manually in the end of the menu.
              </p>
            </div>
            <div className="">
              {liveData !== undefined &&
                liveData.success === true &&
                filteredData.length > 0 &&
                filteredData.map((serie) => (
                  <LiveSerie
                    serie={serie}
                    streams={streams}
                    handleStreams={handleStreams}
                  />
                ))}
            </div>
            {streams.filter((stream) => stream.user_added).length > 0 && (
              <div>
                {streams
                  .filter((stream) => stream.user_added)
                  .map((serie) => (
                    <UserAddedStream
                      serie={serie}
                      streams={streams}
                      handleStreams={handleStreams}
                    />
                  ))}
              </div>
            )}
            <div className="my-2 px-1">
              <AddForm handleAddStream={handleAddStream} />
            </div>
          </div>
        </div>
      ) : null}
      {!show && (
        <div
          className="flex h-36  w-12  bg-slate-500 items-center justify-center hover:bg-gray-500 text-white hover:text-red-500 rounded-r-md hover:cursor-pointer"
          onMouseEnter={toggleShow}
        >
          <div className=" h-8 w-8">
            {show ? <ChevronDoubleLeftIcon /> : <ChevronDoubleRightIcon />}
          </div>
        </div>
      )}
    </div>
  );
}
