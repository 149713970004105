import React from "react";

export default function BookmakerItem({ bookie }) {
  //console.log(bookie);
  return (
    <div className="flex flex-col">
      <div className="flex w-full">
        <div className="w-36 flex flex-col">
          <div>
            <img
              src={bookie.affiliateSmallLogoUrl}
              className="h-6"
              alt={bookie.name}
            />
          </div>
          <div className="w-36 flex justify-between text-sm text-white text-opacity-90 mt-1">
            <div className=" ">
              <p className="">Founded:</p>
            </div>
            <div className="mr-5 ">
              <p>{bookie.founded}</p>
            </div>
          </div>
          <div className="flex justify-between text-sm text-white text-opacity-90 mt-1">
            <div className=" ">
              <p className="">License:</p>
            </div>
            <div className="mr-5 ">
              <p>{bookie.from}</p>
            </div>
          </div>
        </div>
        <div className="w-full flex-col">
          <div className="flex justify-between text-sm text-white text-opacity-90 pt-7">
            <div className=" ">
              <p className="">Crypto-friendly:</p>
            </div>
            <div className="mr-1">
              <p>{bookie.crypto ? "Yes" : "No"}</p>
            </div>
          </div>
          <div className="flex justify-between  text-white text-opacity-90 mt-1">
            <div className="text-sm ">
              <p className="">New Player Bonus:</p>
            </div>
            <div className="mr-1 ml-1 text-sm ">
              <p className="">{bookie.newPlayer}</p>
            </div>
          </div>
        </div>
      </div>
      {bookie.affcode !== undefined && (
        <div className="w-full flex justify-between text-sm text-white text-opacity-90 mt-4">
          <div className=" ">
            <p className>Registration Code:</p>
          </div>
          <div className="mr-1">
            <p className="font-bold text-sm text-white">{bookie.affcode}</p>
          </div>
        </div>
      )}
      <a href={bookie.affiliateLink} target="_blank" rel="noopener, noreferrer">
        <div className="mt-2 w-full flex justify-center ">
          <button className="py-half  px-2 rounded text-white bg-serieLeft2 font-semibold w-full hover:bg-serieRight2 hover:text-red-600">
            <div className="flex ml-1 justify-center">
              <span>Register at</span>
              <span className="ml-1 uppercase">{bookie.name}</span>
            </div>
          </button>
        </div>
      </a>
    </div>
  );
}
